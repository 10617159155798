import { isNotEmptyObject } from "@/utils";

export const getHasActiveFilters = (state: EventFilterStore) => {
  const config = state.filterConfigs[state.activeConfigKey];
  const isInCameraHistory = state.activeConfigKey === "fromCamera";
  const hasCameraFilter = isInCameraHistory
    ? false
    : isNotEmptyObject(config.cameras);
  return (
    hasCameraFilter ||
    isNotEmptyObject(config.types) ||
    config.date.start ||
    config.date.end
  );
};

export const getActiveConfig = (state: EventFilterStore) => {
  return state.filterConfigs[state.activeConfigKey];
};
