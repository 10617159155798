import { motion, TargetAndTransition } from "framer-motion";

type Props = { direction: "left" | "right"; children: any; onTransitionComplete: Cb };

const movements = {
  left: "-25%",
  right: "25%"
};

export default function SceneAnimation({ direction, children, onTransitionComplete }: Props) {
  const movement = movements[direction];

  const handleTransition = (animationProps: TargetAndTransition) => {
    if (animationProps.name === "animate") onTransitionComplete();
  };

  return (
    <motion.div
      initial={{ x: movement, opacity: 0, name: "initial" }}
      animate={{ x: 0, opacity: 1, name: "animate" }}
      exit={{ x: movement, opacity: 0, name: "exit" }}
      transition={{ duration: 0.2 }}
      style={{ height: "calc(100% - 76px)", display: "flex", flexDirection: "column" }}
      onAnimationComplete={handleTransition}
    >
      {children}
    </motion.div>
  );
}
