import { toRelative } from "@/utils";
import EventEmitter from "@/lib/EventEmitter";
import { NoiseSens } from "@/enums/noise";
import { viewerStore } from "viewer/store/viewer";

export default class NoiseDetector {
  private static ctx: AudioContext;
  private static microphoneNode: MediaStreamAudioSourceNode | null = null;
  private static volumeMeterNode: AudioWorkletNode | null = null;
  private static emitter = new EventEmitter();

  static on = this.emitter.on;
  static off = this.emitter.off;

  static init = async () => {
    try {
      this.ctx = new AudioContext();
      await this.ctx.audioWorklet.addModule("volume-meter-processor.js");
      log.app("successfully loaded ctx");
    } catch (err) {
      log.err("Failed 'init' in NoiseDetector", err);
    }
  };
  static connectStream = async () => {
    const stream = viewerStore().localStream;

    if (!stream) {
      log.warn("Trying to call 'connectStream' without any stream");
      return;
    }
    if (this.microphoneNode?.mediaStream) {
      log.info("Disconnecting audio nodes, about to connect new stream");
      this.destroyConnectedStream();
    }
    this.microphoneNode = this.ctx.createMediaStreamSource(stream);
    this.volumeMeterNode = new AudioWorkletNode(this.ctx, "volume-meter");
    this.volumeMeterNode.port.onmessage = ({ data }: { data: number }) => {
      const noise = toRelative({
        value: data,
        sensitivity: NoiseSens.MEDIUM.value,
        correction: 0
      });
      this.emitter.emit("viewer-noise", noise);
    };
    this.microphoneNode.connect(this.volumeMeterNode).connect(this.ctx.destination);
    await this.ctx.resume();
  };
  private static destroyConnectedStream = () => {
    this.microphoneNode?.disconnect();
    this.volumeMeterNode?.disconnect();
  };
  static destroy = async () => {
    await this.ctx.close();
    this.destroyConnectedStream();
  };
}
