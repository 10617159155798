import { useCallback, useMemo } from "react";
import { Flex } from "@chakra-ui/react";
import Calendar from "assets/icons/calendar.svg?react";
import { CameraColors, CameraIcons } from "@/enums/camera";
import { EventColors, EventIcons, EventStringKeys } from "@/enums/events";
import Time from "@/lib/Time";
import { t } from "@/utils";
import { useDevicesByObjectId } from "@/hooks";
import { useEventFilters } from "viewer/store/eventFilters";
import FilterItem from "./FilterItem";

export default function ActiveFilters() {
  const { getByObjectId } = useDevicesByObjectId();
  const { activeConfigKey, filterConfigs } = useEventFilters(({ activeConfigKey, filterConfigs }) => ({
    activeConfigKey,
    filterConfigs
  }));
  const activeConfig = filterConfigs[activeConfigKey];
  const { start: startDate, end: endDate } = activeConfig.date;

  const getCameraFilterItems = useCallback(() => {
    const cameraObjectIds = Object.values(activeConfig.cameras);
    return cameraObjectIds.map((objectId) => {
      const device = getByObjectId(objectId) as StoreCameraDevice;
      return {
        label: device!.name,
        icon: CameraIcons[device.icon].icon,
        color: CameraColors[device.color].value
      };
    });
  }, [activeConfig.cameras, getByObjectId]);

  const getEventTypeFilterItems = useCallback(() => {
    const events = Object.values(activeConfig.types);
    return events.map((event) => {
      return {
        label: t(EventStringKeys[event]),
        icon: EventIcons[event],
        color: EventColors[event]?.primary
      };
    });
  }, [activeConfig]);

  const getDateString = useCallback(() => {
    const startDateMs = new Date(startDate!).getTime();
    const endDateMs = new Date(endDate!).getTime();

    if (startDate && endDate && Time.isSameDay(startDate, endDate)) {
      return Time.getRelevantDate(startDateMs);
    }
    if (startDate && !endDate) {
      return t("fromDate", { 1: Time.getRelevantDate(startDateMs) });
    }
    if (endDate && !startDate) return t("untilDate", { 1: Time.getRelevantDate(endDateMs) });

    if (startDate && endDate) {
      return `${Time.getRelevantDate(startDateMs)} - ${Time.getRelevantDate(endDateMs)}`;
    }
    return "";
  }, [endDate, startDate]);

  const date = useMemo(getDateString, [getDateString]);
  const activeFilters = [
    ...(activeConfig.key !== "fromCamera" ? getCameraFilterItems() : []),
    ...getEventTypeFilterItems()
  ];
  return (
    <Flex
      gap="1rem"
      flexGrow="1"
      flexDir="row-reverse"
      justifyContent="flex-start"
      overflow="auto"
      mr="1rem"
      alignItems="center"
    >
      {activeFilters.map((activeFilter, i) => {
        const { label, icon, color } = activeFilter;
        return (
          <FilterItem
            label={label}
            isActive={false}
            key={i}
            props={{
              bg: "secondary-container",
              pl: "0.75rem",
              pr: "1rem",
              maxH: "32px",
              borderColor: "transparent",
              as: "div",
              pointerEvents: "none"
            }}
            icon={icon}
            iconFill={color}
            iconSize="18px"
            textProps={{
              maxW: 150,
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
          />
        );
      })}
      {(startDate || endDate) && (
        <FilterItem
          label={date}
          icon={Calendar}
          iconSize="18px"
          props={{
            bg: "secondary-container",
            px: "8px",
            maxH: "32px",
            borderColor: "transparent",
            pointerEvents: "none"
          }}
        />
      )}
    </Flex>
  );
}
