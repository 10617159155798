import { useCallback, useMemo, useRef } from "react";
import { msToSeconds, pxToMs, secondsToMs } from "./extensions";
import { RefList } from "./useTimelineUpdater";

type Props = {
  refs: RefList;
};

export const useTimelineHelpers = ({ refs }: Props) => {
  const { timelineStartDate, originalTimelineStartDate, timeline, video } = refs;
  const prevTimelineStartTime = useRef(0);

  const getStartTime = useCallback(
    () => (timelineStartDate.current ? new Date(timelineStartDate.current).getTime() : null),
    [timelineStartDate]
  );

  const getOriginalStartTime = useCallback(
    () => (originalTimelineStartDate.current ? new Date(originalTimelineStartDate.current).getTime() : null),
    [originalTimelineStartDate]
  );

  const getDurationTime = useCallback(
    () => (timeline.current ? pxToMs(timeline.current.getBoundingClientRect().width) : null),
    [timeline]
  );

  const getTotalDurationInSec = useCallback(() => {
    const start = getOriginalStartTime()!;
    const now = new Date().getTime() - 10000;
    return Math.floor(msToSeconds(now - start));
  }, [getOriginalStartTime]);

  const getCurrentPlayerDateTime = useCallback(() => {
    const startTime = getOriginalStartTime();
    if (!video.current || !startTime) return 0;
    return startTime + secondsToMs(video.current.currentTime);
  }, [getOriginalStartTime, video]);

  const getVisibleEndTime = useCallback(() => {
    const startTime = getStartTime();
    const duration = getDurationTime();
    if (!startTime || !duration) return 0;
    return startTime + duration;
  }, [getDurationTime, getStartTime]);

  const getNegativeTimeUpdate = useCallback(() => {
    let update = 0;
    const startTime = getStartTime();
    if (!startTime) return 0;
    if (prevTimelineStartTime.current === 0) prevTimelineStartTime.current = startTime;
    update = prevTimelineStartTime.current - startTime;
    prevTimelineStartTime.current = startTime;
    return update;
  }, [getStartTime]);

  return useMemo(
    () => ({
      getStartTime,
      getOriginalStartTime,
      getDurationTime,
      getCurrentPlayerDateTime,
      getVisibleEndTime,
      getNegativeTimeUpdate,
      getTotalDurationInSec
    }),
    [
      getCurrentPlayerDateTime,
      getDurationTime,
      getNegativeTimeUpdate,
      getOriginalStartTime,
      getStartTime,
      getVisibleEndTime,
      getTotalDurationInSec
    ]
  );
};

export type TimelineHelpers = ReturnType<typeof useTimelineHelpers>;
