import { create } from "zustand";

type InitialStep = "settings" | "account";
type ExtendedControls = Omit<Controls, "open"> & {
  initialStep: InitialStep;
  open: (initialStep?: InitialStep) => void;
};

export const useViewerSettings = create<ExtendedControls>()((set) => ({
  isOpen: false,
  initialStep: "settings",
  close() {
    set({ isOpen: false });
  },
  open(initialStep) {
    set({ isOpen: true, initialStep: initialStep || "settings" });
  }
}));
