import WrongIcon from "assets/icons/wrong.svg?react";
import ErrorIcon from "assets/icons/error.svg?react";

export const contentEnum = {
  face: {
    no_device: {
      title: "noCamera.title",
      subtitle: "noCamera.subtitle",
      Icon: WrongIcon
    },
    no_permission: {
      title: "cameraPermission.title",
      subtitle: "permission.subtitle",
      Icon: WrongIcon
    },
    already_running: {
      title: "faceAlreadyRunning.title",
      subtitle: "faceAlreadyRunning.subtitle",
      Icon: ErrorIcon
    },
    stopped: {
      title: "seeMeStopped.title",
      subtitle: "seeMeStopped.subtitle",
      Icon: ErrorIcon
    }
  },
  microphone: {
    no_device: {
      title: "babyStation.noAudioDialog.heading",
      subtitle: "babyStation.noAudioDialog.text",
      Icon: WrongIcon
    },
    no_permission: {
      title: "microphonePermission.title",
      subtitle: "permission.subtitle",
      Icon: WrongIcon
    }
  }
} as const;

export type ContentEnum = typeof contentEnum;
