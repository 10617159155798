import Dialog from "@/components/Dialog";
import { t } from "@/utils";
import ErrorIcon from "assets/icons/error.svg?react";
import { contentEnum } from "./contentEnum";
import { useMemo } from "react";
import { SpecificFeature, FeatureStringKey, useMonitoringDialog } from "./useMonitoringDialog";

type ContentGetParams = {
  specificFeature: SpecificFeature | null | undefined;
  stringKey: FeatureStringKey | null | undefined;
};

const getContent = ({ specificFeature, stringKey }: ContentGetParams) => {
  if (specificFeature === "face" && stringKey)
    return {
      title: contentEnum[specificFeature][stringKey as keyof (typeof contentEnum)["face"]].title,
      body: contentEnum[specificFeature][stringKey as keyof (typeof contentEnum)["face"]].subtitle,
      icon: contentEnum[specificFeature][stringKey as keyof (typeof contentEnum)["face"]].Icon
    } as const;
  if (specificFeature === "microphone" && stringKey)
    return {
      title: contentEnum[specificFeature][stringKey as keyof (typeof contentEnum)["microphone"]].title,
      body: contentEnum[specificFeature][stringKey as keyof (typeof contentEnum)["microphone"]].subtitle,
      icon: contentEnum[specificFeature][stringKey as keyof (typeof contentEnum)["microphone"]].Icon
    } as const;
  else
    return {
      title: "somethingWentWrong",
      body: "tryAgainLater",
      icon: ErrorIcon
    } as const;
};

export default function MonitoringDialog() {
  const { isOpen, close, specificFeature, stringKey } = useMonitoringDialog();
  const { title, body, icon } = useMemo(() => getContent({ specificFeature, stringKey }), [specificFeature, stringKey]);
  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      title={t(title)}
      body={t(body)}
      buttons={{ confirm: { label: t("buttons.ok") } }}
      iconProps={{ fill: "error", size: "2rem" }}
      icon={icon}
    />
  );
}
