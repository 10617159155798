import { AspectRatio, Flex, Heading, Button, Box } from "@chakra-ui/react";
import AdsComponent from "@/components/Ads";
import AppImage from "@/components/AppImage";
import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import { openCameritoWeb, t } from "@/utils";

export default function DashboardAd() {
  const isPremium = useAuth(getHasActiveSubscription);
  if (isPremium) return null;

  return (
    <Box
      maxW={["100%", "calc(50% - 12px)"]}
      flexBasis={["100%", "50%"]}
      bg="surface-variant"
      borderRadius="1rem"
      pos="relative"
    >
      <AspectRatio ratio={16 / 9} h={["400px", "100%"]} zIndex={2}>
        <AdsComponent
          type="list"
          placeholderComponent={
            <Flex justifyContent="center" flexDir="column" alignItems="center" w="100%" h="100%" pos="absolute" top={0}>
              <AppImage name="lock.svg" />
              <Heading as="h5" my="1rem">
                {t("adMessage")}
              </Heading>
              <Button onClick={() => openCameritoWeb("pricing")}>{t("adUnlock")}</Button>
            </Flex>
          }
        />
      </AspectRatio>
    </Box>
  );
}
