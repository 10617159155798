import { useCallback, useEffect, useRef } from "react";
import CameraList from "@/modules/app/components/CameraList";
import { useControls } from "@/hooks";
import { useViewer } from "viewer/store/viewer";
import { getCurrentCameraConfig } from "viewer/store/viewer/selectors";
import { useViewerActions } from "../../MonitoringManager";

type Props = {
  actionController: ActionController;
};
export default function CameraSwitch({ actionController }: Props) {
  const list = useControls(false);
  const isProcessing = useRef(false);
  const { changeCamera: _changeCamera } = useViewerActions();
  const { cameraConfig, setCameraConfig } = useViewer((state) => {
    return { cameraConfig: getCurrentCameraConfig(state), setCameraConfig: state.setCameraConfig };
  });

  const { cameraState, configuration } = cameraConfig || {};

  const changeCamera = useCallback(
    async (cameraId: string) => {
      if (!cameraConfig || isProcessing.current) return false;
      isProcessing.current = true;

      const isOk = await _changeCamera(cameraId);
      if (isOk) setCameraConfig(cameraConfig.jid, { cameraState: { cameraId } });
      isProcessing.current = false;
      return isOk;
    },
    [_changeCamera, cameraConfig, setCameraConfig]
  );

  useEffect(() => {
    if (configuration && configuration.availableCameras?.length < 2) return;
    actionController.addAction(list.open, "switch_camera");
  }, [actionController, configuration, list.open]);

  if (!configuration || !cameraState) return null;

  const cameraList = configuration.availableCameras.map((c) => ({
    deviceId: c.cameraId,
    label: c.name
  }));

  return (
    <CameraList
      isOpen={list.isOpen}
      cameraId={cameraState.cameraId}
      cameraList={cameraList}
      changeCamera={changeCamera}
      close={list.close}
    />
  );
}
