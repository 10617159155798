import { Flex, Text, Button, Spinner } from "@chakra-ui/react";
import Icon from "@/components/Icon";
import { t } from "@/utils";
import { useActivityLog } from "viewer/store/activityLog";
import Refresh from "assets/icons/refresh.svg?react";
import ActivityLogController from "../ActivityLogController";

export default function EventLoading() {
  const [error, loading] = useActivityLog((state) => [state.error, state.loading]);

  if (!error && !loading) return null;
  return (
    <Flex justifyContent="center" mb="1.5rem" mt="0.75rem" h="40px">
      {error && (
        <Flex alignItems="center">
          <Text variant="title" color="on-surface" mr="0.25rem">
            {t("loadMoreEvents.error")}
          </Text>
          <Button
            variant="text"
            color="primary"
            py="0.5rem"
            px="0.75rem"
            onClick={() => ActivityLogController.downloadNextEvents()}
          >
            <Icon icon={Refresh} fill="primary" size="24" style={{ mr: "0.25rem" }} />
            {t("buttons.retry")}
          </Button>
        </Flex>
      )}
      {loading && !error && <Spinner h="32" w="32" my="0.25rem" />}
    </Flex>
  );
}
