import { useCallback, useEffect } from "react";
import { monitoringActionKeys as eventKeys } from "@/enums/monitoringActionKeys";
import { usePremiumScreen } from "@/modules/app/PremiumScreen";
import { useViewer } from "viewer/store/viewer";
import { getShouldCheckCameraAvailability } from "viewer/store/viewer/selectors";
import checkLocalDeviceAvailability from "../utils/checkLocalDeviceAvailability";
import { useMonitoringDialog } from "../CameraView/MonitoringDialog";
import { useViewerActions } from "../MonitoringManager";

type R = Cb<boolean>;
type T = { openCameraHistory: Cb; actionController: ActionController };

export default function useActionEvents({ openCameraHistory, actionController }: T) {
  const actions = useViewerActions();
  const shouldCheckCameraAvailability = useViewer(getShouldCheckCameraAvailability);
  const openMonitoringDialog = useMonitoringDialog((state) => state.open);
  const { displayBannerForFreeUser } = usePremiumScreen();
  const { toggleFlashlight, toggleMute, changeLowLightMode, toggleVideoMode, toggleFace } = actions;

  const onOpenCameraHistory = useCallback(
    (response: R) => {
      openCameraHistory();
      response(true);
    },
    [openCameraHistory]
  );

  const onToggleFlashlight = useCallback(
    async (response: R) => {
      await toggleFlashlight();
      response(true);
    },
    [toggleFlashlight]
  );

  const onMute = useCallback(
    (response: R) => {
      toggleMute();
      response(true);
    },
    [toggleMute]
  );

  const onScaleVideo = useCallback(
    (response: R) => {
      toggleVideoMode();
      response(true);
    },
    [toggleVideoMode]
  );

  const onChangeLowLightMode = useCallback(
    async (response: R) => {
      const result = await changeLowLightMode();
      response(result);
    },
    [changeLowLightMode]
  );

  const onToggleFace = useCallback(
    async (response: R) => {
      if (actionController.map.face.isActive) {
        await toggleFace();
        response(true);
        return;
      }
      if (shouldCheckCameraAvailability) {
        const cameraAvailability = await checkLocalDeviceAvailability("camera");
        if ("error" in cameraAvailability) {
          openMonitoringDialog("face", cameraAvailability.error);
          response(true);
          return;
        }
      }
      await displayBannerForFreeUser("face").elseRun(async () => {
        const res = await toggleFace();
        if (res === "already_running") openMonitoringDialog("face", "already_running");
      });
      response(true);
    },
    [
      actionController.map.face.isActive,
      displayBannerForFreeUser,
      openMonitoringDialog,
      shouldCheckCameraAvailability,
      toggleFace
    ]
  );

  useEffect(() => {
    actionController.on(eventKeys.history, onOpenCameraHistory);
    actionController.on(eventKeys.flashlight, onToggleFlashlight);
    actionController.on(eventKeys.mute, onMute);
    actionController.on(eventKeys.scale_video, onScaleVideo);
    actionController.on(eventKeys.low_light_mode, onChangeLowLightMode);
    actionController.on(eventKeys.face, onToggleFace);

    return () => {
      actionController.off(eventKeys.history, onOpenCameraHistory);
      actionController.off(eventKeys.flashlight, onToggleFlashlight);
      actionController.off(eventKeys.mute, onMute);
      actionController.off(eventKeys.scale_video, onScaleVideo);
      actionController.off(eventKeys.low_light_mode, onChangeLowLightMode);
      actionController.off(eventKeys.face, onToggleFace);
    };
  }, [
    actionController,
    onChangeLowLightMode,
    onMute,
    onOpenCameraHistory,
    onScaleVideo,
    onToggleFace,
    onToggleFlashlight
  ]);
}
