import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Fade, chakra } from "@chakra-ui/react";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useViewer } from "viewer/store/viewer";

const EnhancedVideo = chakra(motion.video);

export default function LocalVideo({ activeFlashlight }: { activeFlashlight: boolean }) {
  const [isActive, setIsActive] = useState(false);
  const faceVideoRef = useRef<HTMLVideoElement | null>(null);
  const localStream = useViewer((state) => state.localStream);

  useEffect(() => {
    const unsub = [
      dataSyncEmitter.on("local-video-added", () => setIsActive(true)),
      dataSyncEmitter.on("local-video-removed", () => setIsActive(false))
    ];
    return () => unsub.forEach((cb) => cb());
  }, []);

  useEffect(() => {
    if (faceVideoRef.current && localStream) {
      faceVideoRef.current.srcObject = localStream;
    }
  }, [isActive, localStream]);

  return (
    <Fade in={Boolean(isActive)} unmountOnExit transition={{ exit: { duration: 0.2 }, enter: { duration: 0.2 } }}>
      <motion.div
        style={{
          position: "absolute",
          bottom: "68px",
          right: "2rem",
          maxWidth: "220px"
        }}
        animate={{ transform: activeFlashlight ? "translateX(-2rem)" : "0" }}
        transition={{ duration: 0.2 }}
      >
        <EnhancedVideo transform="scaleX(-1)" borderRadius="1rem" ref={faceVideoRef} autoPlay muted />
      </motion.div>
    </Fade>
  );
}
