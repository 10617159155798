import { useState } from "react";
import { Flex, Box, Heading } from "@chakra-ui/react";
import Time from "@/lib/Time";
import { t } from "@/utils";
import { useAuth } from "@/store/auth";
import { useDevices } from "@/store/devices";
import { getCameraDevices, getViewerDevices } from "@/store/devices/selectors";
import { useMount } from "@/hooks/useLifeCycles";
import NavigationBar from "@/components/NavigationBar";
import { getDevicesLatestActivity } from "viewer/modules/cloud/station";
import { ViewerSettingsStepProps } from "../../SettingsFlow";
import DeviceCard from "./DeviceCard";

const getDeviceStatus = (deviceActivity?: DeviceActivity) => {
  return deviceActivity
    ? deviceActivity.status === "ONLINE"
      ? t("online")
      : t("offlineFor", { 1: Time.timeSince(deviceActivity.timestamp) })
    : null;
};

export default function DeviceList({ previous }: ViewerSettingsStepProps) {
  const [deviceActivities, setDeviceActivites] = useState<{
    [key: string]: DeviceActivity;
  }>({});
  const viewerDevices = useDevices(getViewerDevices);
  const cameraDevices = useDevices(getCameraDevices);
  const _thisDevice = useAuth((state) => state.device);

  const getActivity = async () => {
    const activities = await getDevicesLatestActivity();
    if (!activities) return;
    const obj = {} as { [key: string]: DeviceActivity };
    activities.forEach((device) => (obj[device.jid] = device));
    setDeviceActivites(obj);
  };
  useMount(getActivity);

  const thisDevice = { name: _thisDevice.name, jid: _thisDevice.xmppCredentials?.xmppLogin } as StoreViewerDevice;
  return (
    <Box>
      <NavigationBar title={t("devices")} onBack={previous} />
      <Flex flexDir="column" rowGap="1rem">
        <Heading as="h5" mt="0.5rem">
          {t("viewers.title")}
        </Heading>
        <Flex justifyContent="space-between" columnGap="1.5rem" rowGap="1rem" flexWrap="wrap">
          {[thisDevice, ...viewerDevices].map((device) => {
            const status = getDeviceStatus(deviceActivities[device.jid]);
            return (
              <DeviceCard
                key={device.jid}
                device={device}
                isViewerDevice
                isThisDevice={thisDevice.jid === device.jid}
                status={status}
              />
            );
          })}
        </Flex>
        {cameraDevices.length > 0 && (
          <>
            <Heading as="h5" mt="0.5rem">
              {t("cameras.title")}
            </Heading>
            <Flex justifyContent="space-between" columnGap="1.5rem" rowGap="1rem" flexWrap="wrap">
              {cameraDevices.map((device) => {
                const status = getDeviceStatus(deviceActivities[device.jid]);
                return <DeviceCard key={device.jid} device={device} status={status} />;
              })}
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  );
}
