import { messages } from "@/modules/communication";
import Messenger from "@/modules/Messenger";
import { viewerStore } from "viewer/store/viewer";

const { setCameraConfig } = viewerStore();

export default class FlashlightController {
  private get jid() {
    return viewerStore().selectedCamera.jid as string;
  }
  private get isEnabled() {
    return viewerStore().cameraConfigs[this.jid]?.cameraState?.flashlightEnabled ?? false;
  }
  private get isAvailable() {
    return viewerStore().cameraConfigs[this.jid]?.cameraState?.flashlightAvailable ?? false;
  }
  private get previousIntensity() {
    return viewerStore().cameraConfigs[this.jid]?.cameraState?.flashlightIntensity ?? -1;
  }
  private messenger = Messenger.getInstance();
  private isProcessing = false;

  private send = async ({ enabled, intensity }: { enabled: boolean; intensity: number }) => {
    const message = messages.SetVideoFlashlightMessage.create({
      enabled,
      intensity
    });
    log.monitoring("Sending 'SetVideoFlashlightMessage'", this.jid, message);
    await this.messenger.sendAsEnvelope({ to: this.jid, payload: { message } });
  };

  private on = async () => {
    try {
      const intensity = this.previousIntensity;
      await this.send({ enabled: true, intensity });
      setCameraConfig(this.jid, {
        cameraState: { flashlightIntensity: intensity, flashlightEnabled: true }
      });
    } catch (err) {
      log.err("Failed setting flashlight", err);
    }
  };

  private off = async () => {
    try {
      const intensity = this.previousIntensity;
      setCameraConfig(this.jid, {
        cameraState: { flashlightIntensity: intensity, flashlightEnabled: false }
      });
      await this.send({ enabled: false, intensity });
    } catch (err) {
      log.err("Failed setting flashlight", err);
    }
  };

  toggle = async () => {
    if (!this.isAvailable) {
      log.warn("Flashlight not available");
      return;
    }
    if (this.isProcessing) return;
    this.isProcessing = true;

    if (this.isEnabled) await this.off();
    else await this.on();

    this.isProcessing = false;
  };

  setIntensity = async (intensity: number) => {
    try {
      await this.send({ enabled: true, intensity });
      setCameraConfig(this.jid, {
        cameraState: { flashlightIntensity: intensity }
      });
    } catch (err) {
      log.err("Failed setting flashlight", err);
    }
  };
}
