import { useCallback, useState } from "react";
import { Box } from "@chakra-ui/react";
import { t } from "@/utils";
import IconButton from "@/components/IconButton";
import Maximize from "assets/icons/maximize.svg?react";
import Minimize from "assets/icons/minimize.svg?react";
import Settings from "assets/icons/settings_outlined.svg?react";
import Close from "assets/icons/close_large.svg?react";
import { useMonitoringIconButtonStyle } from "../utils/styles";
import MonitoringAd from "../MonitoringAd";

export default function CameraViewControls({ disconnect, openSettings }: { disconnect: Cb; openSettings: Cb }) {
  const buttonStyle = useMonitoringIconButtonStyle();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const fullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  }, []);

  return (
    <>
      <IconButton
        {...buttonStyle}
        icon={Close}
        tooltip={{ label: t("buttons.close") }}
        props={{
          pos: "absolute",
          left: "32",
          top: "32"
        }}
        onClick={disconnect}
      />
      <MonitoringAd />
      <IconButton
        {...buttonStyle}
        icon={Settings}
        props={{ pos: "absolute", top: "32", right: "88" }}
        tooltip={{ label: t("settings") }}
        onClick={openSettings}
      />
      <Box pos="absolute" top="32" right="32">
        <IconButton
          {...buttonStyle}
          icon={isFullscreen ? Minimize : Maximize}
          onClick={fullscreen}
          tooltip={{ label: t(isFullscreen ? "fullscreen.exit" : "fullscreen.enter") }}
        />
      </Box>
    </>
  );
}
