import { useCallback, useMemo } from "react";
import { msToPx } from "./extensions";
import { TimelineHelpers } from "./useTimelineHelpers";
import { RefList } from "./useTimelineUpdater";

type Props = {
  TH: TimelineHelpers;
  refs: RefList;
};

export const useElementHelpers = ({ refs, TH }: Props) => {
  const { timeline } = refs;

  const getPositionFromCurrentStartInPx = useCallback(
    (startTime: number) => {
      const startInPx = msToPx(new Date(startTime).getTime() - TH.getStartTime()!);
      return startInPx + "px";
    },
    [TH]
  );

  const renderElementOnTimeline = useCallback((el: Element) => timeline.current?.append(el), [timeline]);

  const getRangeDurationInMs = (startTime: number, endTime: number) => endTime - startTime;

  const removeElementById = (id: string) => document.querySelector(`#${id}`)?.remove();
  const getElementById = (id: string) => document.querySelector(`#${id}`) as HTMLDivElement | null;

  const moveElementOnTimeline = (el: HTMLDivElement, updateInPx: number) => {
    const newPosition = Number(el.style.left.slice(0, -2)) + updateInPx;
    el.style.left = newPosition + "px";
  };

  return useMemo(
    () => ({
      getPositionFromCurrentStartInPx,
      renderElementOnTimeline,
      getRangeDurationInMs,
      removeElementById,
      getElementById,
      moveElementOnTimeline
    }),
    [getPositionFromCurrentStartInPx, renderElementOnTimeline]
  );
};

export type ElementHelpers = ReturnType<typeof useElementHelpers>;
