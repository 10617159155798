import { useCallback, useRef } from "react";
import Time from "@/lib/Time";
import { ElementHelpers } from "./useElementHelpers";
import { TimelineHelpers } from "./useTimelineHelpers";
import { RefList } from "./useTimelineUpdater";
import { TIMESTAMP_INTERVAL_IN_MS } from "./const";

type Props = {
  refs: RefList;
  elementHelpers: ElementHelpers;
  TH: TimelineHelpers;
};

export const useTimestamps = ({ refs, elementHelpers, TH }: Props) => {
  const { originalTimelineStartDate } = refs;
  const { getVisibleEndTime } = TH;
  const alreadyRendered = useRef(false);
  const latestTimestamp = useRef(0);

  const getFirstTimestamp = useCallback(() => {
    if (!originalTimelineStartDate.current) return;
    const date = new Date(originalTimelineStartDate.current);
    const minutes = date.getMinutes();

    const minutesToAdd = 5 - (minutes % 5);

    date.setMinutes(minutes + minutesToAdd);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date.getTime();
  }, [originalTimelineStartDate]);

  const calculateTimestamps = useCallback(() => {
    const firstTimestamp = getFirstTimestamp();
    if (!firstTimestamp) return;

    const visibleTimelineEndTime = getVisibleEndTime();
    if (!visibleTimelineEndTime) return;

    const timestamps: number[] = [];
    let time = firstTimestamp;

    while (time <= visibleTimelineEndTime) {
      timestamps.push(time);
      time += TIMESTAMP_INTERVAL_IN_MS;
    }
    return timestamps;
  }, [getFirstTimestamp, getVisibleEndTime]);

  const renderTimestamp = (timestamp: number) => {
    const timestampEl = document.createElement("div");
    const id = String(timestamp);
    timestampEl.id = id;
    timestampEl.classList.add("timestamp");
    timestampEl.innerHTML = Time.formatDate(timestamp, "HH:mm");
    timestampEl.style.fontSize = "9px";
    timestampEl.style.position = "absolute";
    timestampEl.style.letterSpacing = "0.2px";
    timestampEl.style.lineHeight = "12px";
    timestampEl.style.fontWeight = "500";
    timestampEl.style.color = "#FFFFFF";
    timestampEl.style.transform = "translateY(100%)";
    timestampEl.style.bottom = "-4px";
    timestampEl.style.opacity = "0.7";
    timestampEl.style.left = elementHelpers.getPositionFromCurrentStartInPx(timestamp);
    elementHelpers.renderElementOnTimeline(timestampEl);
  };

  const renderForFirstTime = () => {
    if (alreadyRendered.current) return;
    const timestamps = calculateTimestamps();
    timestamps?.forEach((timestamp) => {
      renderTimestamp(timestamp);
      latestTimestamp.current = timestamp;
    });
    if (timestamps && timestamps.length > 0) alreadyRendered.current = true;
  };

  const addNewTimestamps = () => {
    if (!latestTimestamp.current) return;

    let time = latestTimestamp.current + TIMESTAMP_INTERVAL_IN_MS;
    const timestampToAdd: number[] = [];
    while (time <= getVisibleEndTime()) {
      timestampToAdd.push(time);
      time += TIMESTAMP_INTERVAL_IN_MS;
      latestTimestamp.current = time;
    }
    timestampToAdd.forEach((timestamp) => renderTimestamp(timestamp));
  };

  const updateTimestamps = (updateInPx: number) => {
    const timestampElements = Array.from(document.getElementsByClassName("timestamp")) as HTMLDivElement[];

    const timelineStartTime = TH.getStartTime();
    if (!timelineStartTime) return;

    timestampElements.forEach((timestampEl) => {
      if (Number(timestampEl.id) < timelineStartTime) timestampEl.remove();
      else {
        timestampEl.style.left = `${parseFloat(timestampEl.style.left) + updateInPx}px`;
      }
    });
    addNewTimestamps();
  };

  const renderTimestamps = () => {
    if (alreadyRendered.current) addNewTimestamps();
    else renderForFirstTime();
  };

  return {
    renderTimestamps,
    updateTimestamps
  };
};
