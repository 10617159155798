import { Box, Flex, Heading } from "@chakra-ui/react";
import ListButton from "@/components/ListButton";
import NavigationBar from "@/components/NavigationBar";
import { t, openEmailClient } from "@/utils";

import Account from "assets/icons/account.svg?react";
import Devices from "assets/icons/devices.svg?react";
import Email from "assets/icons/email.svg?react";
import Info from "assets/icons/info.svg?react";
import { ViewerSettingsStepProps } from "../SettingsFlow";

export default function Settings({ push, onClose }: ViewerSettingsStepProps) {
  const generalItems = [
    {
      label: t("account"),
      icon: Account,
      action: () => push("account")
    },
    {
      label: t("devices"),
      icon: Devices,
      action: () => push("device-list")
    }
  ];
  const aboutItems = [
    {
      label: t("purchase.help.contactUs.heading"),
      icon: Email,
      action: () => openEmailClient()
    },
    {
      label: t("settings.appVersion"),
      additionalText: __APP_VERSION__,
      icon: Info,
      hideArrow: true,
      disabled: true
    }
  ];

  return (
    <Box>
      <NavigationBar title={t("settings")} onClose={onClose} />
      <Heading as="h5" mb="1rem" mt="0.5rem">
        {t("parentStation.settings.general.heading")}
      </Heading>
      <Flex columnGap="24px" wrap="wrap" rowGap="16px">
        {generalItems.map(({ label, action, icon }) => {
          return (
            <ListButton
              icon={icon}
              iconFill="primary"
              onClick={action}
              key={label}
              label={label}
              props={{
                width: ["100%", "calc(50% - 12px)"]
              }}
            />
          );
        })}
      </Flex>
      <Heading as="h5" mb="1rem" mt="1.5rem">
        {t("about.title")}
      </Heading>
      <Flex columnGap="24px" wrap="wrap" rowGap="16px">
        {aboutItems.map(({ label, additionalText, hideArrow, disabled, icon, action }) => {
          return (
            <ListButton
              key={label}
              label={label}
              icon={icon}
              iconFill="primary"
              onClick={action}
              additionalText={additionalText}
              hideArrow={hideArrow}
              props={{
                width: ["100%", "calc(50% - 12px)"],
                pointerEvents: disabled ? "none" : "auto"
              }}
            />
          );
        })}
      </Flex>
    </Box>
  );
}
