import { Flex, Spinner } from "@chakra-ui/react";
import { useMount } from "@/hooks/useLifeCycles";
import { absoluteCenter } from "@/theme/utils/style";
import { useActivityLog } from "viewer/store/activityLog";
import ActivityLogController from "../ActivityLogController";
import useSceneCondition from "../useSceneConditions";
import NoFilteredEvents from "./NoFilteredEvents";
import EventDetail from "./EventDetail";
import NoEvents from "./NoEvents";
import EventList from "./EventList";
import EventsError from "./EventsError";
import SelectedEventProvider from "../SelectedEventProvider";

export default function ActivityLog() {
  const { hasNoEvents, hasNoFilteredResults } = useSceneCondition();
  const [loading, selectedEventId, error] = useActivityLog((state) => [
    state.loading,
    state.selectedEventId,
    state.error
  ]);

  useMount(ActivityLogController.downloadEvents);
  const firstLoading = loading && !selectedEventId && !error;

  if (firstLoading) return <Spinner {...absoluteCenter} />;
  if (hasNoEvents && !error) return <NoEvents />;
  if (hasNoEvents && error) return <EventsError />;
  if (hasNoFilteredResults) return <NoFilteredEvents />;

  return (
    <SelectedEventProvider>
      <Flex width="100%" columnGap={["1rem", "1rem", "1.5rem"]} height="100%" pos="relative">
        <EventList />
        <EventDetail />
      </Flex>
    </SelectedEventProvider>
  );
}
