import Icon from "@/components/Icon";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import { Button, ButtonProps, Text, TextProps } from "@chakra-ui/react";
import LivingRoom from "assets/icons/living_room.svg?react";

export default function FilterItem({
  label,
  icon,
  isActive,
  onClick,
  props,
  iconFill,
  iconSize,
  textProps
}: {
  label: string | JSX.Element;
  icon?: any;
  isActive?: boolean;
  onClick?: Cb;
  props?: ButtonProps;
  iconFill?: string;
  iconSize?: string;
  textProps?: TextProps;
}) {
  const { active, hover } = useButtonStateColors("on-surface-variant", "secondary-container");
  return (
    <Button
      _hover={{
        bg: hover
      }}
      _active={{
        bg: active
      }}
      variant="unstyled"
      display="flex"
      alignItems="center"
      onClick={onClick}
      border="1px solid"
      bg={isActive ? "secondary-container" : ""}
      borderColor={isActive ? "primary" : "outline"}
      borderRadius="1.5rem"
      py="0.375rem"
      pr="1rem"
      pl="0.75rem"
      {...props}
    >
      <Icon icon={icon || LivingRoom} fill={iconFill || "primary"} size={iconSize} />

      <Text variant="label" color="on-surface-variant" ml="8px" {...textProps}>
        {label}
      </Text>
    </Button>
  );
}
