import { create } from "zustand";

type ActivityLogState = {
  loading: boolean;
  events: ParseCameraEvent[];
  selectedEventId?: string | null;
  playerEvent?: ParseCameraEvent | null;
  reachedEnd: boolean;
  error: boolean;
};

type ActivityLogStore = ActivityLogState & {
  setLoading(loading: boolean): void;
  setEvents(events: ActivityLogState["events"]): void;
  addEvents(newEvents: ActivityLogState["events"]): void;
  removeEvent(uniqueId: string): void;
  setSelectedEventId(selectedEventId: string | null): void;
  setPlayerEvent(playerEvent: ParseCameraEvent): void;
  setReachedEnd(state: boolean): void;
  setError(state: boolean): void;
};

const useActivityLog = create<ActivityLogStore>()((set, get) => ({
  loading: true,
  events: [],
  playerEvent: null,
  selectedEventId: null,
  reachedEnd: false,
  error: false,
  addEvents(newEvents) {
    set(({ events }) => ({ events: [...events, ...newEvents] }));
  },
  setEvents(events) {
    set({ events });
  },
  removeEvent(uniqueId) {
    let selectedEventId = get().selectedEventId;
    const filteredEvents = get().events.filter((e) => e.uniqueId !== uniqueId);
    if (uniqueId === selectedEventId) selectedEventId = filteredEvents[0]?.uniqueId;
    set({ events: filteredEvents, selectedEventId });
  },
  setLoading(loading) {
    set({ loading });
  },
  setSelectedEventId(selectedEventId) {
    set({ selectedEventId });
  },
  setPlayerEvent(playerEvent) {
    set({ playerEvent });
  },
  setReachedEnd(state: boolean) {
    set({ reachedEnd: state });
  },
  setError(state: boolean) {
    set({ error: state });
  }
}));

const activityLogStore = useActivityLog.getState;

export { useActivityLog, activityLogStore };
