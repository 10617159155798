import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import { useTransparentize } from "@/theme/utils/transparentize";

export const useMonitoringPanelButtonStyle = () => {
  const containerBg = useTransparentize("neutral-variant.30", 0.7);
  const activeBg = useTransparentize("primary.70", 0.4);

  const { active: activeStateBg, hover: hoverStateBg } = useButtonStateColors("neutral.99", containerBg);
  const { active: inEffectActiveStateBg, hover: inEffectHoverStateBg } = useButtonStateColors("neutral.99", activeBg);

  const getStyleObjAsValues = (isActive: boolean) => ({
    backdropFilter: "blur(8px)",
    bg: isActive ? activeBg : containerBg,
    hoverBg: isActive ? inEffectHoverStateBg : hoverStateBg,
    activeBg: isActive ? inEffectActiveStateBg : activeStateBg
  });

  return { getStyleObjAsValues };
};

export const useMonitoringIconButtonStyle = () => {
  const containerBg = useTransparentize("neutral-variant.30", 0.7);

  return {
    bg: containerBg,
    fill: "neutral.99",
    props: {
      backdropFilter: "blur(8px)"
    }
  } as const;
};
