import { useRef, useState } from "react";
import { Box, Input, Text, Spinner, Flex } from "@chakra-ui/react";
import { t } from "@/utils";
import { useDevices } from "@/store/devices";
import { useAuth } from "@/store/auth";
import Dialog from "@/components/Dialog";
import Announcer from "@/modules/Announcer";
import { setDeviceName } from "@/modules/cloud/devices";
import { updateCameraStationSettings } from "@/modules/cloud/station";
import EditIcon from "assets/icons/edit_outlined.svg?react";
import { useDeviceRename } from "./useDeviceRename";

const renameDevice = async (device: StoreDevice, name: string) => {
  if (device.role === "viewer") {
    const response = await setDeviceName({ xmppLogin: device.jid, deviceName: name });
    if (!response) return null;
    return name;
  } else {
    const response = await updateCameraStationSettings({ xmppLogin: device.jid, name });
    if (!response) return null;
    return name;
  }
};

export default function DeviceRename() {
  const [isProcessing, setIsProcessing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isOpen, device, close } = useDeviceRename();
  const { thisDeviceJid, updateDevice } = useAuth(({ device, updateDevice }) => ({
    thisDeviceJid: device.xmppCredentials?.xmppLogin,
    updateDevice
  }));
  const updateExistingDevice = useDevices((state) => state.updateExistingDevice);

  const handleRename = async () => {
    if (!inputRef.current || !device) return;
    setIsProcessing(true);

    const newName = await renameDevice(device, inputRef.current.value);
    if (!newName) {
      log.err("Error renaming device", device);
      setIsProcessing(false);
      return;
    }

    if (thisDeviceJid === device.jid) updateDevice({ name: newName });
    else updateExistingDevice({ jid: device.jid, name: newName });

    Announcer.getInstance().announceItemChange("DEVICES");
    setIsProcessing(false);
    close();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      onConfirm={handleRename}
      icon={EditIcon}
      iconProps={{ props: { w: "24px", h: "24px" }, fill: "primary" }}
      title={t("deviceList.renameDevice.heading")}
      body={
        <Box>
          {isProcessing ? (
            <Flex w="100%" h="100%" justifyContent="center" alignItems="center">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <>
              <Text>{t("deviceList.renameDevice.text", { 1: device?.name })}</Text>
              <Input ref={inputRef} defaultValue={device?.name || ""} mt="1rem" />
            </>
          )}
        </Box>
      }
      buttons={{
        cancel: { label: t("buttons.cancel") },
        confirm: { label: t("buttons.rename") }
      }}
    />
  );
}
