import { useMemo, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useControls, useIsMobileView } from "@/hooks";
import { dataSyncEmitter } from "@/modules/events/emitter";
import Modal from "@/components/Modal";
import { useActivityLog } from "viewer/store/activityLog";
import EventDetailContent from "./EventDetailContent";

export default function EventDetail() {
  const { events, selectedEventId } = useActivityLog((state) => ({
    events: state.events,
    selectedEventId: state.selectedEventId
  }));
  const modalControls = useControls();
  const isMobile = useIsMobileView();

  useEffect(() => {
    return dataSyncEmitter.on("open-event-modal", modalControls.open);
  }, [modalControls.open]);

  const event = useMemo(() => events.find((e) => e.uniqueId === selectedEventId), [events, selectedEventId]);
  if (!event) return null;
  if (isMobile) {
    return (
      <Modal
        onClose={modalControls.close}
        isOpen={modalControls.isOpen}
        modalBodyProps={{ overflow: "visible", py: 0, px: 0 }}
        modalContentProps={{ maxW: ["container.sm", "container.md", "container.lg"], bg: "surface", minH: "auto" }}
        closeButton
      >
        <EventDetailContent />
      </Modal>
    );
  }
  return (
    <Box w={["100%", "calc(50% - 0.75rem)"]}>
      <EventDetailContent />
    </Box>
  );
}
