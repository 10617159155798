import { useEffect, useState } from "react";
import { t } from "@/utils";
import Dialog from "@/components/Dialog";
import Trash from "assets/icons/trash.svg?react";
import Done from "assets/icons/done.svg?react";
import { deleteAccountSendEmail } from "viewer/modules/cloud/auth";

export default function DeleteAccountDialog({ close, isOpen }: { close: Cb; isOpen: boolean }) {
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const titleStringKey = userConfirmed ? "account.deleteRequest.title" : "account.delete.assurance.heading";
  const descriptionStringKey = userConfirmed ? "account.deleteRequest.description" : "account.delete.assurance.text";

  const deleteAccount = async () => {
    setIsProcessing(true);
    const res = await deleteAccountSendEmail();
    if (!res) log.err("failed 'deleteAccountSendEmail'");
    setIsProcessing(false);
    setUserConfirmed(true);
  };

  useEffect(() => {
    if (isOpen) setUserConfirmed(false);
  }, [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      buttons={
        userConfirmed
          ? {
              cancel: {
                label: t("buttons.ok"),
                color: "primary"
              }
            }
          : {
              confirm: {
                label: t("buttons.delete"),
                color: "error"
              },
              cancel: {
                label: t("buttons.cancel")
              }
            }
      }
      onClose={close}
      onConfirm={deleteAccount}
      icon={userConfirmed ? Done : Trash}
      iconProps={{ fill: userConfirmed ? "green" : "error" }}
      title={t(titleStringKey)}
      body={t(descriptionStringKey)}
      isProcessing={isProcessing}
    />
  );
}
