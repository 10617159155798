import { Box, Button, Text, Flex, Heading, Badge, BadgeProps } from "@chakra-ui/react";
import { t } from "@/utils";
import Time from "@/lib/Time";
import { getCameraDevices, getDeviceByObjectId } from "@/store/devices/selectors";
import { useDevices } from "@/store/devices";
import { EventColors, EventIcons, EventStringKeys } from "@/enums/events";
import { CameraIcons, CameraColors } from "@/enums/camera";
import Circle from "@/components/Circle";
import Icon from "@/components/Icon";
import { useEventFilters } from "viewer/store/eventFilters";
import { getActiveConfig } from "viewer/store/eventFilters/selectors";
import EventThumbnail from "./EventThumbnail";
import { usePresenceKeeper } from "@/modules/app/Presence";
import { useViewer } from "viewer/store/viewer";

type Props = {
  isSelected: boolean;
  onClick: Cb;
  event: ParseCameraEvent;
};
const commonBadgeStyles: BadgeProps = {
  px: "6",
  py: "2",
  fontSize: "9px",
  pos: "absolute",
  top: "6",
  right: "6",
  variant: "status",
  zIndex: 4
};

export default function EventCard({ isSelected, onClick, event }: Props) {
  const { type, end, start, cameraStation } = event;

  const { device, cameraDevices } = useDevices((s) => ({
    device: getDeviceByObjectId(cameraStation.objectId)(s) as StoreCameraDevice,
    cameraDevices: getCameraDevices(s)
  }));
  const presence = usePresenceKeeper()[device?.jid] || "unavailable";
  const cameraPreview = useViewer((s) => s.cameraPreviews)[device?.jid] || null;

  const filterConfig = useEventFilters(getActiveConfig);

  const displayCameraIcon = cameraDevices.length > 1 && filterConfig.key === "fromDashboard";
  const isOngoingAndLive = Boolean(!event.end) && presence !== "unavailable";
  const isOngoingAndOffline = Boolean(!event.end) && presence === "unavailable";

  const thumbnailUrl = isOngoingAndLive ? cameraPreview?.image?.url : event.thumbnailUrl;
  return (
    <Button
      onClick={onClick}
      variant="custom"
      borderRadius="1rem"
      bg="surface3"
      w="100%"
      h={["80px", null, "90px"]}
      alignItems="center"
      overflow="hidden"
      pos="relative"
    >
      {isOngoingAndLive && <Badge {...commonBadgeStyles}>{t("live").toUpperCase()}</Badge>}
      {isOngoingAndOffline && (
        <Badge bg="secondary" color="on-secondary" {...commonBadgeStyles}>
          {t("notAvailable").toUpperCase()}
        </Badge>
      )}
      {isSelected && <Box w="4px" pos="absolute" left="0px" bg="primary" height="100%" />}
      <Circle
        size="2rem"
        bg={EventColors[event.type].secondary}
        props={{ mr: "0.75rem", ml: ["0.75rem", null, "1rem"] }}
      >
        <Icon icon={EventIcons[event.type]} fill={EventColors[event.type].primary} size="16" />
      </Circle>
      <Box flexGrow="1" textAlign="left" mr="12px" overflow="hidden">
        <Flex alignItems="center" mb="0.25rem">
          <Heading as="h6" variant="title" color="on-surface" mr="8px">
            {Time.formatDate(start, "HH:mm")}
          </Heading>
          {displayCameraIcon && device && (
            <Icon icon={CameraIcons[device.icon].icon} fill={CameraColors[device.color].value} size="1rem" />
          )}
          {!device && "Unknown camera"}
        </Flex>

        <Text color="on-surface-variant" variant="body">
          {t(EventStringKeys[type])} ·{" "}
          {end ? Time.formatDuration(end - start) : isOngoingAndLive ? t("ongoingEvent") : t("unfinishedEvent")}
        </Text>
      </Box>
      <EventThumbnail url={thumbnailUrl} hideBg={Boolean(end)} />
    </Button>
  );
}
