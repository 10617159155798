import { cloudCode } from "@/modules/cloud";
import { useAuth } from "@/store/auth";

export function getPairingCodeInfo(code: string) {
  return cloudCode.run("getPairingCodeInfo", { code }, { rethrow: true });
}

export function requestDevicePairing(requestedJid: string) {
  log.pairing("requesting device pairing for jid: ", requestedJid);
  const xmppLogin = useAuth.getState().device.xmppCredentials?.xmppLogin;
  return cloudCode.run("requestDevicePairing", {
    myJID: xmppLogin,
    requestedJID: requestedJid,
  });
}
