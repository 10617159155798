import { create } from "zustand";
import { ContentEnum } from "./contentEnum";

export type FeatureStringKey = keyof ContentEnum["face"] | keyof ContentEnum["microphone"];
export type SpecificFeature = "face" | "microphone";

type Open = <
  T extends SpecificFeature,
  F extends FeatureStringKey = T extends "face" ? FeatureStringKey : keyof ContentEnum["microphone"]
>(
  specificFeature?: T,
  stringKey?: F
) => void;

type Store = {
  isOpen: boolean;
  specificFeature: SpecificFeature | null;
  stringKey: FeatureStringKey | null;
  open: Open;
  close: Cb;
};

export const useMonitoringDialog = create<Store>()((set) => ({
  isOpen: false,
  specificFeature: null,
  stringKey: null,
  open(specificFeature, stringKey) {
    set({ isOpen: true, specificFeature, stringKey });
  },
  close() {
    set({ isOpen: false, specificFeature: null, stringKey: null });
  }
}));
