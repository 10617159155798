import { useDevices } from "@/store/devices";
import { useMemo } from "react";

export function useDevicesByObjectId() {
  const devices = useDevices((s) => s.devices);

  const byId = useMemo(
    () =>
      Object.values(devices).reduce((all, one) => {
        all[one.objectId] = one;
        return all;
      }, {} as StoreDevices),
    [devices]
  );

  const getByObjectId = (objectId: string) => {
    return byId[objectId];
  };

  return { devices: byId, getByObjectId };
}
