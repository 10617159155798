import { useAuth } from "@/store/auth";
import { useDevices } from "@/store/devices";
import { useViewer } from "viewer/store/viewer";

export default function clearSession() {
  log.app("Clearing session");

  useAuth.persist.clearStorage();
  useDevices.persist.clearStorage();
  useViewer.persist.clearStorage();
  localStorage.removeItem("parseUser");
}
