import { ReactElement, useEffect, useRef, useState } from "react";
import { useMount } from "@/hooks/useLifeCycles";
import { SystemStyleObject, chakra } from "@chakra-ui/react";

const EnhancedIns = chakra("ins");

const AdsComponent = ({
  type = "basic",
  placeholderComponent,
  style
}: {
  type: "basic" | "list";
  style?: SystemStyleObject;
  placeholderComponent: ReactElement;
}) => {
  const adRef = useRef<HTMLModElement>(null);
  const [displayPlaceholder, setDisplayPlaceholder] = useState(false);

  useMount(() => {
    try {
      //@ts-ignore
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      log.err("Google ad error");
    }
  });

  useEffect(() => {
    const adElement = adRef.current;
    if (adElement) {
      const timer = setTimeout(() => {
        if (adElement.getAttribute("data-ad-status") !== "filled") {
          log.warn("Ad failed to load within the expected time.");
          setDisplayPlaceholder(true);
        }
      }, 1500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  if (displayPlaceholder) return placeholderComponent;
  //not used
  if (type === "basic") {
    return (
      <EnhancedIns
        ref={adRef}
        className="adsbygoogle"
        sx={{ ...style }}
        data-ad-client="ca-pub-7656172151000850"
        data-ad-slot="5218104130"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    );
  }
  return (
    <EnhancedIns
      ref={adRef}
      className="adsbygoogle"
      data-ad-format="fluid"
      data-ad-layout-key="-6t+ed+2i-1n-4w"
      data-ad-client="ca-pub-7656172151000850"
      data-ad-slot="6606944099"
      sx={{ ...style }}
    />
  );
};

export default AdsComponent;
