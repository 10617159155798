import { FunctionComponent } from "react";
import Peers from "@/modules/Peers";
import { useMonitoringDialog } from "../CameraView/MonitoringDialog";

export type StateValue = { isActive: boolean; isDisabled: boolean; isProcessing: boolean };
type Component = ({ actionController }: { actionController: ActionController }) => JSX.Element | null;
type ActionMiddleware = "processing" | "disabledWhenNoConnection";
type ActionCreator<Action, LabelKey> = {
  key: CameraControlKey;
  actionOpts: {
    middlewares?: ActionMiddleware[];
    action: Action;
  };
  labelKey: LabelKey;
  icon: FunctionComponent;
  Component?: Component;
};

let jid: string;
const Processing = () => {
  const _locks: { [key: string]: boolean } = {};
  const createLock = (key: string) => (_locks[key] = false);

  let toggleProcess: Cb<CameraControlKey>;

  const addProcessing = <T extends (...args: any) => any>(key: CameraControlKey, cb: T) => {
    createLock(key);

    return (async () => {
      if (_locks[key]) return;
      _locks[key] = true;

      toggleProcess(key);
      const result = await cb();
      toggleProcess(key);
      _locks[key] = false;

      return result;
    }) as T;
  };

  return { addProcessing, setProcessToggle: (cb: Cb<CameraControlKey>) => (toggleProcess = cb) };
};

const addDisableWhenNoConnection = <T extends (...args: any) => any>(cb: T) => {
  return (async () => {
    const peer = Peers.get(jid);
    if (peer?.peerConnection && peer.getIsConnectionInErrorState()) return false;
    return await cb();
  }) as ReturnType<T>;
};

const addDialogOnError = <T extends (...args: any) => any>(cb: T) => {
  return (async () => {
    const res = await cb();
    if (!res) useMonitoringDialog.getState().open();
    return res;
  }) as T;
};

const { addProcessing, setProcessToggle } = Processing();

const createAction = <Action extends (...args: any) => any, LabelKey>({
  key,
  actionOpts,
  Component,
  icon,
  labelKey
}: ActionCreator<Action, LabelKey>) => {
  const middlewares = actionOpts.middlewares?.reduce(
    (all, one) => ({ ...all, [one]: one }),
    {} as { [key in ActionMiddleware]?: ActionMiddleware }
  );

  let action = actionOpts.action;
  if (middlewares?.processing) action = addProcessing(key, action);
  if (middlewares?.disabledWhenNoConnection) action = addDisableWhenNoConnection(action);
  if (middlewares?.processing || middlewares?.disabledWhenNoConnection) action = addDialogOnError(action);

  return {
    key,
    isActive: false,
    isDisabled: false,
    isProcessing: false,
    action,
    labelKey,
    Component,
    icon
  };
};

const actionControllerUtils = {
  createAction,
  Processing: { setProcessToggle },
  setJid(_jid: string) {
    jid = _jid;
  }
};

export default actionControllerUtils;
