import { Flex, Heading, Box } from "@chakra-ui/react";
import Modal from "@/components/Modal";
import { useDevices } from "@/store/devices";
import { useModalManager } from "@/lib/ModalManager";
import IconButton from "@/components/IconButton";
import { t } from "@/utils";
import { getDeviceByObjectId } from "@/store/devices/selectors";
import ActivityLog from "viewer/modules/activityLog/components/ActivityLog";
import { useEventFilters } from "viewer/store/eventFilters";
import { getActiveConfig } from "viewer/store/eventFilters/selectors";
import ActiveFilters from "viewer/modules/activityLog/components/ActiveFilters";
import FilterSelect from "viewer/modules/activityLog/components/FilterSelect";
import RefreshButton from "viewer/modules/activityLog/components/RefreshButton";
import Close from "assets/icons/close_large.svg?react";

export default function CameraHistory({ isOpen, onClose }: { isOpen: boolean; onClose: Cb }) {
  const { isModalOpen } = useModalManager();
  const resetConfig = useEventFilters((state) => state.resetConfig);
  const filterConfig = useEventFilters(getActiveConfig);
  const cameraObjectId = Object.values(filterConfig.cameras)[0];
  const cameraName = useDevices(getDeviceByObjectId(cameraObjectId))?.name;

  const close = () => {
    resetConfig("fromCamera");
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      title={
        <Flex alignItems="center" w="100%">
          <Heading as="h4" maxW="40%" w="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {cameraName}
          </Heading>
          <Flex flexGrow="1" alignItems="center" ml="1rem" pos="relative" overflowX="auto" maxW="60%">
            <ActiveFilters />
          </Flex>
          <FilterSelect />
          <Box mx="0.5rem">
            <RefreshButton />
          </Box>
          <IconButton
            onClick={close}
            iconSize="24"
            icon={Close}
            fill="on-surface"
            tooltip={{ label: t("buttons.close") }}
          />
        </Flex>
      }
      props={{ closeOnEsc: !isModalOpen }}
      modalBodyProps={{
        overflow: "visible",
        height: "calc(100vh - 7.5rem - 52px)",
        pt: "12px"
      }}
      modalContentProps={{
        w: ["container.sm", "container.md", "container.lg"],
        maxW: "calc(100% - 2rem)",
        bg: "surface"
      }}
    >
      <ActivityLog />
    </Modal>
  );
}
