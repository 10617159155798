// {[key in CameraControlKey]: CameraControlKey; }
export const monitoringActionKeys = {
  history: "history",
  flashlight: "flashlight",
  mute: "mute",
  scale_video: "scale_video",
  switch_camera: "switch_camera",
  replay: "replay",
  low_light_mode: "low_light_mode",
  face: "face",
} as const;
