import { Box, Flex, Text } from "@chakra-ui/react";
import { useDevices } from "@/store/devices";
import AnimatedDot from "@/components/AnimatedDot";
import { useViewer } from "viewer/store/viewer";
import BatteryIndicator from "viewer/modules/station/components/BatteryIndicator";
import { getCurrentCameraConfig } from "viewer/store/viewer/selectors";
import { useIsPeerConnected } from "viewer/hooks/useIsPeerConnected";

export default function CameraInfo() {
  const devices = useDevices((state) => state.devices);
  const previews = useViewer((state) => state.cameraPreviews);
  const cameraConfig = useViewer(getCurrentCameraConfig);
  const connected = useIsPeerConnected(cameraConfig?.jid);

  if (!cameraConfig) return null;

  const deviceName = devices[cameraConfig.jid]?.name;
  const isCharging = previews[cameraConfig.jid]?.status?.batteryStatus?.state === "CHARGING";
  const avaiableBatteryStatus = cameraConfig.batteryStatus.state !== "UNKNOWN";
  return (
    <Box pos="absolute" bottom="32" right="32" zIndex={2}>
      <Box pos="relative">
        <Flex align="center" columnGap="1rem">
          <Flex align="center" columnGap="6px">
            <Text
              variant="label"
              color="neutral-variant.99"
              maxW="20vw"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {deviceName}
            </Text>
            {!isCharging && avaiableBatteryStatus && connected && (
              <BatteryIndicator batteryLevel={cameraConfig.batteryStatus.level} />
            )}
          </Flex>
          {connected && <AnimatedDot />}
        </Flex>
      </Box>
    </Box>
  );
}
