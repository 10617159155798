import { EventKeys } from "@/enums/events";
import { isEmptyArray } from "@/utils";
import Time from "@/lib/Time";
import { activityLogStore } from "viewer/store/activityLog";
import { viewerStore } from "viewer/store/viewer";
import { eventFilterStore } from "viewer/store/eventFilters";
import { getActiveConfig } from "viewer/store/eventFilters/selectors";
import { getCameraHistoryEvents, getNewerCameraHistoryEvents } from "../cloud/media";

const { setEvents, setLoading, setSelectedEventId, addEvents, setReachedEnd, setError } = activityLogStore();
const THREE_MINUTES = 60000 * 3;

export default class ActivityLogController {
  private static refreshInterval: NodeJS.Timeout | null = null;

  private static removeUnfinishedEventsIfIsInLiveView = (events: ParseCameraEvent[] | null) => {
    if (!events) return null;
    const jid = viewerStore().selectedCamera.jid;
    const isConnected = jid && viewerStore().cameraConfigs[jid]?.cameraState;
    return isConnected ? events.filter((e) => e.end) : events;
  };

  private static getParams = () => {
    const storedEvents = activityLogStore().events;
    const filters = getActiveConfig(eventFilterStore());

    const camerasArray = Object.keys(filters.cameras);
    const eventTypesArray = Object.keys(filters.types);
    const { start: startDate, end: endDate } = filters.date;

    const lastEventId = storedEvents[storedEvents.length - 1]?.objectId as string | undefined;
    const filteredCameras = isEmptyArray(camerasArray) ? undefined : camerasArray;
    const filteredEventTypes = isEmptyArray(eventTypesArray) ? [EventKeys.MOTION, EventKeys.NOISE] : eventTypesArray;

    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    return {
      lastEventId,
      filteredCameras,
      filteredEventTypes,
      start,
      end
    };
  };

  static downloadNextEvents = async (callback?: Cb) => {
    log.info("Downloading new History Events");
    const { lastEventId, filteredCameras, filteredEventTypes, start, end } = this.getParams();
    setLoading(true);
    setError(false);
    let events = await getCameraHistoryEvents({
      lastEventId,
      cameras: filteredCameras,
      events: filteredEventTypes,
      ...(start && { start: Time.getDayWithZeroMs(start) }),
      ...(end && { end: Time.getDayWithMaximumMs(end) })
    });
    events = this.removeUnfinishedEventsIfIsInLiveView(events);
    if (events === null) setError(true);
    if (events && events.length === 0) setReachedEnd(true);
    if (events?.length) addEvents(events);
    setLoading(false);
    callback?.();
  };

  static downloadEvents = async () => {
    const { filteredCameras, filteredEventTypes, start, end } = this.getParams();
    setLoading(true);
    setError(false);
    setReachedEnd(false);
    setSelectedEventId(null);

    let events = await getCameraHistoryEvents({
      cameras: filteredCameras,
      events: filteredEventTypes,
      ...(start && { start: Time.getDayWithZeroMs(start) }),
      ...(end && { end: Time.getDayWithMaximumMs(end) })
    });
    events = this.removeUnfinishedEventsIfIsInLiveView(events);
    if (events !== null) setEvents(events);
    if (events === null) setError(true);
    if (events && events[0]?.uniqueId) {
      setSelectedEventId(events[0].uniqueId);
    }
    setLoading(false);
  };

  // // not used
  // static startRefreshInterval = () => {
  //   this.refreshInterval = setInterval(this.downloadNewestEvents, THREE_MINUTES);
  // };
  // // not used
  // static stopRefreshInterval = () => {
  //   if (this.refreshInterval) {
  //     clearInterval(this.refreshInterval);
  //     this.refreshInterval = null;
  //   }
  // };

  // // not used
  // private static downloadNewestEvents = async () => {
  //   const oldEvents = activityLogStore().events;
  //   const initialEventId = oldEvents[0]?.objectId;
  //   const { filteredCameras, filteredEventTypes } = this.getParams();

  //   const events = await getNewerCameraHistoryEvents({
  //     initialEventId,
  //     cameras: filteredCameras,
  //     events: filteredEventTypes
  //   });
  //   log.info("Downloaded newest events:", events);
  //   this.mergeNewEvents(events);
  // };

  // // not used
  // private static mergeNewEvents = (newEvents: ParseCameraEvent[]) => {
  //   if (isEmptyArray(newEvents)) return;
  //   const oldEvents = activityLogStore().events;

  //   const uniqueEventsIds = {} as Keyable<boolean>;
  //   const mergedEvents = [...newEvents, ...oldEvents].filter((event) => {
  //     if (!uniqueEventsIds[event.uniqueId]) {
  //       uniqueEventsIds[event.uniqueId] = true;
  //       return true;
  //     }
  //     return false;
  //   });

  //   setEvents(mergedEvents);
  // };
}
