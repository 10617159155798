import { create } from "zustand";
import { persist } from "zustand/middleware";
import environment from "@/modules/environment";

type StationState = {
  hasShownWizard: boolean;
};
type StationStore = StationState & {
  setHasShownWizard(hasShownWizard: boolean): void;
};

const initialState: StationState = {
  hasShownWizard: false
};

const useStation = create<StationStore>()(
  persist(
    (set) => ({
      ...initialState,
      setHasShownWizard(hasShownWizard) {
        set({ hasShownWizard });
      }
    }),
    {
      name: environment.appMode + "-station"
    }
  )
);

const stationStore = useStation.getState;

export { useStation, stationStore };
