import { Flex, Text, Box, Heading, Button } from "@chakra-ui/react";
import { t, openEmailClient } from "@/utils";
import Circle from "@/components/Circle";
import NavigationBar from "@/components/NavigationBar";
import TextAsButton from "@/components/TextAsButton";
import { WizardProps } from "../Wizard";

export default function CantFindCode({ push }: WizardProps) {
  return (
    <Flex grow={1} direction="column">
      <NavigationBar onBack={() => push("enter-code", { stepDirection: "previous" })} props={{ my: "1rem" }} />
      <Flex px="2.5rem" direction="column" justify="center" align="center" flexGrow="1">
        <Heading mb="2rem" as="h4">
          {t("pairing.code.help.title")}
        </Heading>
        <Flex columnGap="12px" alignSelf="flex-start">
          <Circle size="28" bg="primary" props={{ color: "on-primary" }}>
            1
          </Circle>
          <Box>
            <Text display="inline-flex" mr="0.25rem">
              {t("pairing.code.help.step1")}
            </Text>
            <TextAsButton
              color="primary"
              onClick={() =>
                push("device-select", {
                  stepDirection: "next",
                  stateUpdate: {
                    previousStepId: "cant-find-code"
                  }
                })
              }
            >
              {t("pairing.help.install.title")}
            </TextAsButton>
          </Box>
        </Flex>
        <Flex my="1.5rem" alignItems="center" alignSelf="flex-start">
          <Circle size="28" bg="primary" props={{ color: "on-primary" }}>
            2
          </Circle>
          <Text ml="12px">{t("pairing.add.camera.step2", { b: <strong /> })}</Text>
        </Flex>
        <Flex alignItems="center" columnGap="12px" alignSelf="flex-start">
          <Circle size="28" bg="primary" props={{ color: "on-primary" }}>
            3
          </Circle>
          <Text>{t("pairing.code.help.step3")}</Text>
        </Flex>
      </Flex>
      <Button mt="3rem" variant="text" mx="auto" minW="18.5rem" onClick={() => openEmailClient()}>
        {t("purchase.help.contactUs.heading")}
      </Button>
    </Flex>
  );
}
