import { useCallback, useEffect } from "react";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { msToPx, msToSeconds } from "./extensions";
import { ElementHelpers } from "./useElementHelpers";
import { TimelineHelpers } from "./useTimelineHelpers";
import useTimelineMetaEvents from "./useTimelineMetaEvents";
import { RefList } from "./useTimelineUpdater";

type Props = {
  refs: RefList;
  elementHelpers: ElementHelpers;
  TH: TimelineHelpers;
};

export const useEvents = ({ refs, elementHelpers, TH }: Props) => {
  const { video, timeline } = refs;

  const {
    mapEvents,
    deleteMappedEvents,
    addEventToMap,
    updateEventInMap,
    getClosestEventTimeInDirection,
    updateSkipButtons,
    updateMetaEventOnSeek,
    updateMetaEventsOnTimeUpdate,
    deleteOldEvents
  } = useTimelineMetaEvents(TH, elementHelpers);

  const renderEvent = useCallback(
    (event: CameraEvent) => {
      const eventEl = document.createElement("div");
      const id = "id" + event.uniqueId;
      eventEl.id = id;
      eventEl.classList.add("timeline-event");
      eventEl.style.zIndex = event.type === "NOISE" ? "1" : "2";
      eventEl.style.height = "12px";
      eventEl.style.borderRadius = "24px";
      eventEl.style.backgroundColor = event.type === "NOISE" ? "var(--colors-error-50)" : "var(--colors-purple-50)";
      eventEl.style.position = "absolute";
      const startTime = new Date(event.start).getTime();
      const endTime = new Date(event.end).getTime();

      const duration = elementHelpers.getRangeDurationInMs(startTime, endTime);

      eventEl.style.left = elementHelpers.getPositionFromCurrentStartInPx(event.start);
      eventEl.style.width = event.finished ? msToPx(duration) + "px" : "-webkit-fill-available";
      elementHelpers.renderElementOnTimeline(eventEl);
    },
    [elementHelpers]
  );

  const renderEvents = useCallback((events: CameraEvent[]) => events.forEach(renderEvent), [renderEvent]);

  const setEventDurationOnTimeline = useCallback(
    (event: CameraEvent) => {
      const eventEl = elementHelpers.getElementById("id" + event.uniqueId);
      if (eventEl && event.finished) {
        eventEl.style.width = msToPx(elementHelpers.getRangeDurationInMs(event.start, event.end)) + "px";
      }
    },
    [elementHelpers]
  );

  const onEventsLoad = useCallback(
    (events: CameraEvent[]) => {
      log.timeline("'onEventsLoad' fired", events);
      mapEvents(events);
      deleteOldEvents();
      renderEvents(events);
    },
    [deleteOldEvents, mapEvents, renderEvents]
  );
  const onEventCreate = useCallback(
    (event: CameraEvent) => {
      log.info("'onEventCreate' fired");
      renderEvents([event]);
      addEventToMap(event);
    },
    [addEventToMap, renderEvents]
  );
  const onEventUpdate = useCallback(
    (event: CameraEvent) => {
      log.info("'onEventUpdate' fired");
      updateEventInMap(event);
      setEventDurationOnTimeline(event);
    },
    [setEventDurationOnTimeline, updateEventInMap]
  );

  const onEventsReload = useCallback(
    (events: CameraEvent[]) => {
      document.querySelectorAll(".timeline-event").forEach((el) => el.remove());
      deleteMappedEvents();
      onEventsLoad(events);
    },
    [deleteMappedEvents, onEventsLoad]
  );

  const skipToEvent = useCallback(
    (direction: "next" | "previous") => {
      const originalTimelineStartDate = TH.getOriginalStartTime();
      if (!originalTimelineStartDate || !video.current || !timeline.current) return;
      const eventStartDate = getClosestEventTimeInDirection(direction);
      if (!eventStartDate) return;

      const skipTo = msToSeconds(new Date(eventStartDate).getTime() - originalTimelineStartDate);

      video.current.currentTime = Math.ceil(skipTo);
      updateMetaEventOnSeek();
      updateSkipButtons();
    },
    [TH, getClosestEventTimeInDirection, timeline, updateMetaEventOnSeek, updateSkipButtons, video]
  );

  useEffect(() => {
    const previousEvent = () => skipToEvent("previous");
    const nextEvent = () => skipToEvent("next");

    dataSyncEmitter.on("timeline-skip-to-previous-event", previousEvent);
    dataSyncEmitter.on("timeline-skip-to-next-event", nextEvent);
    return () => {
      dataSyncEmitter.off("timeline-skip-to-previous-event", previousEvent);
      dataSyncEmitter.off("timeline-skip-to-next-event", nextEvent);
    };
  }, [skipToEvent]);

  useEffect(() => {
    dataSyncEmitter.on("timeline-events-load", onEventsLoad);
    dataSyncEmitter.on("timeline-events-reload", onEventsReload);

    dataSyncEmitter.on("timeline-event-create", onEventCreate);
    dataSyncEmitter.on("timeline-event-update", onEventUpdate);

    dataSyncEmitter.on("timeline-ready", updateMetaEventOnSeek);

    return () => {
      dataSyncEmitter.off("timeline-events-load", onEventsLoad);
      dataSyncEmitter.off("timeline-events-reload", onEventsReload);

      dataSyncEmitter.off("timeline-event-create", onEventCreate);
      dataSyncEmitter.off("timeline-event-update", onEventUpdate);

      dataSyncEmitter.off("timeline-ready", updateMetaEventOnSeek);
    };
  }, [onEventCreate, onEventUpdate, onEventsLoad, onEventsReload, updateMetaEventOnSeek]);

  const updateRenderedEvents = (ms: number) => {
    document
      .querySelectorAll<HTMLDivElement>(".timeline-event")
      .forEach((el) => elementHelpers.moveElementOnTimeline(el, msToPx(ms)));
  };

  return {
    updateRenderedEvents,
    deleteOldEvents,
    updateMetaEventOnSeek,
    updateMetaEventsOnTimeUpdate,
    updateSkipButtons
  };
};

export type EventHelpers = ReturnType<typeof useEvents>;
