import cameraStationPreview from "@/modules/communication/private/CameraStationPreview";
import { commEmitter } from "@/modules/events/emitter";
import { getCameraDevices } from "@/store/devices/selectors";
import { useDevices } from "@/store/devices";
import { viewerStore } from "viewer/store/viewer";
// import PresenceKeeper from "@/modules/app/Presence";

const { setCameraPreview, removeCameraPreview } = viewerStore();

export default class PreviewManager {
  static startListeningForUpdates = () => {
    commEmitter.on("presence-payload", this.handleCurrentStatePayload);
    // PresenceKeeper.on("presence-update", this.handlePresenceUpdate);
    useDevices.subscribe(this.handleStoreUpdate);
  };

  private static handleCurrentStatePayload = (payload: Partial<CameraCurrentStatePayload>, from: string) => {
    try {
      log.media("Received encoded preview message", from);
      const { currentState: encodedCurrentState } = payload;
      if (!encodedCurrentState) return;

      const currentState = cameraStationPreview.decode(encodedCurrentState);
      setCameraPreview(from, currentState);
    } catch (err) {
      log.err("Error decoding currentState payload");
      log.err(err);
    }
  };

  private static handleStoreUpdate = async (state: DevicesStore, prevState: DevicesStore) => {
    log.media("Comparing device list...");
    const cameras = getCameraDevices(state);
    const previousCameras = getCameraDevices(prevState);

    if (cameras.length < previousCameras.length) {
      const oldCamera = previousCameras.find((camera) => !cameras.find((c) => c.jid === camera.jid));
      if (oldCamera) {
        log.media("Old camera found, removing preview for", oldCamera.jid);
        removeCameraPreview(oldCamera.jid);
      }
    }
  };

  // private static handlePresenceUpdate = ({ jid, status }: PresenceUpdate) => {
  //   const devices = useDevices.getState().devices;
  //   if (devices[jid]?.role === "camera" && status === "unavailable") removeCameraPreview(jid);
  // };
}
