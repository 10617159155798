import { useRef, useCallback } from "react";

export default function useDragAndDrop() {
  const itemRefs = useRef<HTMLDivElement[]>([]);
  const dropAreaRefs = useRef<HTMLDivElement[]>([]);

  const swapItems = useCallback((dragIndex: number, dropIndex: number) => {
    const placeholder = document.createElement("div");
    const dragItem = itemRefs.current[dragIndex];
    const dropItem = itemRefs.current[dropIndex];

    dragItem.before(placeholder);

    dropItem.after(dragItem);
    placeholder.before(dropItem);
    placeholder.remove();
  }, []);

  const reoderItems = useCallback(
    (dragIndex: number, dropIndex: number, direction: "left" | "right") => {
      const dragItem = itemRefs.current[dragIndex];
      const dropArea = dropAreaRefs.current[dropIndex];

      if (dragItem === dropArea.parentElement) {
        return;
      }

      if (direction === "left") {
        dropArea.parentElement?.before(dragItem);
      }
      if (direction === "right") {
        dropArea.parentElement?.after(dragItem);
      }
    },
    []
  );

  const hideEmpty = useCallback(
    (dragIndex: number, emptyArea: HTMLDivElement) => {
      const dragItem = itemRefs.current[dragIndex];

      emptyArea.after(dragItem);
      emptyArea.classList.add("hidden");
      emptyArea.classList.remove("hovered");
    },
    []
  );

  const fillEmpty = useCallback(
    (dragIndex: number, emptyFillArea: HTMLDivElement) => {
      const dragItem = itemRefs.current[dragIndex];

      emptyFillArea.classList.remove("hovered");
      emptyFillArea.before(dragItem);
    },
    []
  );

  const init = useCallback(() => {
    const clears: Cb[] = [];
    let draggedIndex: number;

    itemRefs.current = Array.from(document.querySelectorAll("div[draggable]"));

    itemRefs.current.forEach((item, index) => {
      function dragstart(e: DragEvent) {
        draggedIndex = index;
        e.dataTransfer!.setData("index", String(index));
        e.dataTransfer!.effectAllowed = "move";
        const ghost = item.cloneNode(true) as HTMLDivElement;
        ghost.id = "drag-ghost";
        ghost.style.position = "fixed";
        ghost.style.left = "0px";
        ghost.style.top = "0px";
        ghost.style.zIndex = "-99";
        document.body.appendChild(ghost);

        const { x, y } = item.getBoundingClientRect();

        e.dataTransfer?.setDragImage(ghost, e.clientX - x, e.clientY - y);
        item.classList.add("dragged");
      }
      function dragover(e: DragEvent) {
        e.preventDefault();
        e.dataTransfer!.dropEffect = "move";
      }
      function drop(e: DragEvent) {
        e.preventDefault();
        const dragIndex = e.dataTransfer?.getData("index")!;
        swapItems(Number(dragIndex), index);
      }
      function dragend(e: DragEvent) {
        const ghost = document.querySelector("#drag-ghost");
        ghost?.remove();
        dropAreaRefs.current.forEach((item) => {
          item.classList.remove("hovered");
        });
        itemRefs.current.forEach((item) => {
          item.classList.remove("hovered");
        });
        item.classList.remove("dragged");
      }
      function dragenter(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (index === draggedIndex) return;
        if (e.target === e.currentTarget) {
          (e.currentTarget as HTMLDivElement).classList.add("hovered");
        }
      }
      function dragleave(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target !== e.currentTarget) return;
        (e.currentTarget as HTMLDivElement).classList.remove("hovered");
      }

      item.addEventListener("dragenter", dragenter);
      item.addEventListener("dragleave", dragleave);
      item.addEventListener("dragstart", dragstart);
      item.addEventListener("dragover", dragover);
      item.addEventListener("drop", drop);
      item.addEventListener("dragend", dragend);
      clears.push(() => {
        item.removeEventListener("dragenter", dragenter);
        item.removeEventListener("dragleave", dragleave);
        item.removeEventListener("dragstart", dragstart);
        item.removeEventListener("dragover", dragover);
        item.removeEventListener("drop", drop);
        item.removeEventListener("dragend", dragend);
      });
    });

    dropAreaRefs.current = Array.from(
      document.querySelectorAll("div[drop-area]")
    );

    dropAreaRefs.current.forEach((item, index) => {
      function dragover(e: DragEvent) {
        e.preventDefault();
      }
      function drop(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        const dragIndex = e.dataTransfer?.getData("index")!;
        const placement = item.getAttribute("drop-area") as "right" | "left";
        reoderItems(Number(dragIndex), index, placement);
      }
      function dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLDivElement).classList.add("hovered");
      }
      function dragleave(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLDivElement).classList.remove("hovered");
      }

      item.addEventListener("dragover", dragover);
      item.addEventListener("drop", drop);
      item.addEventListener("dragenter", dragenter);
      item.addEventListener("dragleave", dragleave);
      clears.push(() => {
        item.removeEventListener("dragover", dragover);
        item.removeEventListener("drop", drop);
        item.removeEventListener("dragenter", dragenter);
        item.removeEventListener("dragleave", dragleave);
      });
    });

    const emptyAreas = Array.from(
      document.querySelectorAll(".empty-area")
    ) as HTMLDivElement[];

    emptyAreas.forEach((item) => {
      function dragover(e: DragEvent) {
        e.preventDefault();
      }
      function drop(e: DragEvent) {
        e.preventDefault();
        const dragIndex = e.dataTransfer?.getData("index")!;
        hideEmpty(Number(dragIndex), e.currentTarget as HTMLDivElement);
      }

      function dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLDivElement).classList.add("hovered");
      }
      function dragleave(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLDivElement).classList.remove("hovered");
      }

      item.addEventListener("dragover", dragover);
      item.addEventListener("drop", drop);
      item.addEventListener("dragenter", dragenter);
      item.addEventListener("dragleave", dragleave);

      clears.push(() => {
        item.removeEventListener("dragover", dragover);
        item.removeEventListener("drop", drop);
        item.removeEventListener("dragenter", dragenter);
        item.removeEventListener("dragleave", dragleave);
      });
    });

    const emptyFillRefs = Array.from(
      document.querySelectorAll(".empty-fill-area")
    ) as HTMLDivElement[];

    emptyFillRefs.forEach((item) => {
      function dragover(e: DragEvent) {
        e.preventDefault();
      }
      function drop(e: DragEvent) {
        e.preventDefault();
        const dragIndex = e.dataTransfer?.getData("index")!;
        fillEmpty(Number(dragIndex), e.currentTarget as HTMLDivElement);
      }

      function dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLDivElement).classList.add("hovered");
      }
      function dragleave(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLDivElement).classList.remove("hovered");
      }
      item.addEventListener("dragover", dragover);
      item.addEventListener("drop", drop);
      item.addEventListener("dragenter", dragenter);
      item.addEventListener("dragleave", dragleave);

      clears.push(() => {
        item.removeEventListener("dragover", dragover);
        item.removeEventListener("drop", drop);
        item.removeEventListener("dragenter", dragenter);
        item.removeEventListener("dragleave", dragleave);
      });
    });

    return () => {
      clears.forEach((cb) => cb());
    };
  }, [reoderItems, swapItems, fillEmpty, hideEmpty]);

  return { initDND: init };
}
