import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import NavigationBar from "@/components/NavigationBar";
import Circle from "@/components/Circle";
import AppImage from "@/components/AppImage";
import { t } from "@/utils";
import { WizardProps } from "../Wizard";

export default function AddCamera({ next, push, wizard }: WizardProps) {
  return (
    <Flex grow={1} direction="column" alignItems="center">
      <NavigationBar onClose={wizard.close} props={{ my: "1rem" }} />
      <AppImage name="add_camera.svg" options={{ imgProps: { mb: "2rem", maxH: "12rem" } }} />
      <Flex w="100%" direction="column" align="center" px="2.5rem" flexGrow={1}>
        <Heading as="h4" mb="1.25rem">
          {t("pairing.add.camera.title")}
        </Heading>
        <Flex columnGap="12px" mb="1.5rem" alignSelf="flex-start">
          <Circle size="28" bg="primary" props={{ color: "on-primary" }}>
            1
          </Circle>
          <Box>
            <Text>{t("pairing.add.camera.step1")}</Text>
            <Button
              variant="link"
              color="primary"
              mt="0.5rem"
              onClick={() =>
                push("device-select", {
                  stepDirection: "next",
                  stateUpdate: { previousStepId: "add-camera" }
                })
              }
            >
              {t("pairing.help.install.title")}
            </Button>
          </Box>
        </Flex>

        <Flex columnGap="12px" alignSelf="flex-start" alignItems="center">
          <Circle size="28" bg="primary" props={{ color: "on-primary" }}>
            2
          </Circle>
          <Flex alignItems="center">
            <Text>{t("pairing.add.camera.step2", { b: <strong /> })}</Text>
          </Flex>
        </Flex>

        <Button mt="64" bg="primary" color="on-primary" py="18" minW="18.5rem" onClick={next}>
          {t("buttons.continue")}
        </Button>
      </Flex>
    </Flex>
  );
}
