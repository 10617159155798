import { Box, Button, Flex, Heading, Spinner } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import Close from "assets/icons/close_large.svg?react";
import { t } from "@/utils";
import IconButton from "@/components/IconButton";
import useDragAndDrop from "viewer/lib/dnd/useDragAndDrop";
import { createMainBarObserver, createSideBarObserver, createUnusedBarObserver } from "viewer/lib/dnd/observers";
import EditablePanels from "./EditablePanels";

type Props = {
  actionStates: ActionStates;
  actionController: ActionController;
  close: Cb;
  jid: string;
};

const modalVariants: MotionProps["variants"] = {
  initial: { opacity: 0, transition: { duration: 0.2 } },
  animate: { opacity: 1, transition: { duration: 0.2 } },
  exit: { opacity: 0, transition: { duration: 0.2 } }
};

export default function ControlsEditDialog({ jid, actionController, actionStates, close }: Props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { initDND } = useDragAndDrop();
  const mainBarRef = useRef<HTMLDivElement>(null);
  const sideBarRef = useRef<HTMLDivElement>(null);
  const unusedBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return initDND();
  }, [initDND]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => e.key === "Escape" && close();
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [close]);

  useEffect(() => {
    const disconnectainBarObserver = createMainBarObserver(mainBarRef.current!);
    const disconnectSideBarObserver = createSideBarObserver(sideBarRef.current!);
    const disconnectUnusedBarObserver = createUnusedBarObserver(unusedBarRef.current!);
    return () => {
      disconnectainBarObserver();
      disconnectSideBarObserver();
      disconnectUnusedBarObserver();
    };
  }, []);

  async function handleSave() {
    if (!mainBarRef.current || !sideBarRef.current) return;
    setIsProcessing(true);
    const mainBarButtons = Array.from(mainBarRef.current.querySelectorAll("[draggable], .empty-area:not(.hidden)"));
    const sideBarButtons = Array.from(sideBarRef.current.querySelectorAll("[draggable]"));

    const controls = {
      primaryControls: mainBarButtons.map((l) => {
        const key = l.getAttribute("item-key");
        return key || "";
      }),
      secondaryControls: sideBarButtons.map((l) => l.getAttribute("item-key"))
    } as CameraControls;

    await actionController.providing.updateCloudControls(controls);
    close();
  }

  return (
    <AnimatePresence>
      <Box
        key="motion-overlay-edit-dialog"
        bg="blackAlpha.600"
        w="100%"
        h="100%"
        pos="fixed"
        as={motion.div}
        variants={modalVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      />
      <Box
        key="motion-edit-dialog"
        position="fixed"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
        as={motion.div}
        variants={modalVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        w="42rem"
        maxW="100%"
        zIndex={1}
      >
        <Flex direction="column" bg="surface2" py="24" px="32" borderRadius="1.5rem">
          <Flex align="center" justifyContent="space-between">
            <Flex align="center" columnGap="12px">
              <IconButton
                tooltip={{ label: t("buttons.close") }}
                icon={Close}
                fill="on-surface"
                onClick={close}
                props={{
                  pointerEvents: isProcessing ? "none" : "auto"
                }}
              />
              <Heading as="h4" color="on-surface">
                {t("dragItems.title")}
              </Heading>
            </Flex>

            {isProcessing ? (
              <Spinner size="lg" mr="22" />
            ) : (
              <Button px="24" py="12" variant="filled" onClick={handleSave}>
                {t("buttons.save")}
              </Button>
            )}
          </Flex>
          <EditablePanels
            jid={jid}
            actionController={actionController}
            actionStates={actionStates}
            mainBarRef={mainBarRef}
            sideBarRef={sideBarRef}
            unusedBarRef={unusedBarRef}
          />
        </Flex>
      </Box>
    </AnimatePresence>
  );
}
