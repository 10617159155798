import { Button, Text, Flex } from "@chakra-ui/react";
import { t, openCameritoWeb } from "@/utils";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import Icon from "@/components/Icon";
import { useAuth } from "@/store/auth";
import ArrowRight from "assets/icons/arrow_right.svg?react";
import AppImage from "@/components/AppImage";

export default function EventListBanner() {
  const isPremium = useAuth(getHasActiveSubscription);

  if (isPremium) return null;
  return (
    <Button
      variant="unstyled"
      bg="secondary-container"
      color="on-secondary-container"
      display="flex"
      justifyContent="space-between"
      borderRadius="1rem"
      mt="1rem"
      mb="0.25rem"
      alignItems="center"
      py="0.75rem"
      pl="0.75rem"
      pr="0.5rem"
      textAlign="start"
      onClick={() => openCameritoWeb("pricing")}
      whiteSpace="normal"
    >
      <Flex alignItems="center">
        <AppImage options={{ path: "icons", imgProps: { h: "48px", w: "48px" } }} name="time.svg" />
        <Text ml="12px" mr="16px" color="on-secondary-container" variant="body">
          {t("upgradeBanner.title")}
        </Text>
      </Flex>
      <Icon icon={ArrowRight} size="16" fill="on-secondary-container" />
    </Button>
  );
}
