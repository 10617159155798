import { useMemo } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { t } from "@/utils";
import Icon from "@/components/Icon";
import IconButton from "@/components/IconButton";
import Menu from "@/components/Menu";
import { useDeleteDeviceFlow } from "@/modules/app/DeviceDeleteFlow";
import { CameraColors, CameraIcons } from "@/enums/camera";
import { textOverflowDots } from "@/theme/utils/style";
import { useDeviceRename } from "viewer/components/DeviceRename";

import VerticalDots from "assets/icons/vertical_dots.svg?react";
import TrashIcon from "assets/icons/trash.svg?react";
import EditIcon from "assets/icons/edit_outlined.svg?react";
import AndroidIcon from "assets/icons/device_android.svg?react";
import DesktopIcon from "assets/icons/device_desktop.svg?react";
import IpadIcon from "assets/icons/device_ipad.svg?react";
import NotebookIcon from "assets/icons/device_notebook.svg?react";
import IphoneIcon from "assets/icons/device_iphone.svg?react";
import TabletIcon from "assets/icons/device_tablet.svg?react";

type Props =
  | {
      isThisDevice: boolean;
      isViewerDevice: true;
      device: StoreViewerDevice;
      status: string | JSX.Element | null;
    }
  | {
      isThisDevice?: never;
      isViewerDevice?: never;
      device: StoreCameraDevice;
      status: string | JSX.Element | null;
    };

const Icons = {
  1: IphoneIcon,
  2: IpadIcon,
  3: IpadIcon,
  4: NotebookIcon,
  5: AndroidIcon,
  6: TabletIcon,
  9: DesktopIcon,
  10: IphoneIcon,
  get(jid: string) {
    const icons = this as Omit<typeof Icons, "get">;
    const selector = Number(jid[jid.length - 1]) as keyof typeof icons;
    return icons[selector] || icons[5];
  }
};

export default function DeviceCard({ device, isViewerDevice, status, isThisDevice }: Props) {
  const openDeleteFlow = useDeleteDeviceFlow((state) => state.open);
  const openDeviceRename = useDeviceRename((state) => state.open);

  const items = useMemo(() => {
    const _items = [
      {
        item: t("buttons.rename"),
        props: {
          onClick: () => openDeviceRename({ device }),
          icon: <Icon icon={EditIcon} size="24" fill="on-surface-variant" />
        }
      }
    ];
    if (!isThisDevice)
      _items.push({
        item: t("buttons.delete"),
        props: {
          onClick: () => openDeleteFlow({ device }),
          icon: <Icon icon={TrashIcon} fill="error" />
        }
      });
    return _items;
  }, [device, isThisDevice, openDeleteFlow, openDeviceRename]);

  const DeviceIcon = isViewerDevice ? Icons.get(device.jid) : CameraIcons[device.icon].icon;
  return (
    <Flex
      border="1px solid"
      borderColor="outline"
      borderRadius="1rem"
      width={["100%", "calc(50% - 12px)"]}
      p="10px 9px 12px 12px"
      h="66px"
    >
      <Icon
        icon={DeviceIcon}
        style={{ width: "24px", my: "auto" }}
        fill={isViewerDevice ? "primary" : CameraColors[device.color].value}
      />
      <Box mx="12px" w="calc(100% - 88px)" whiteSpace="nowrap">
        <Text lineHeight="22px" letterSpacing="-0.408px" {...textOverflowDots} color="on-surface">
          {device.name}
        </Text>
        <Text fontSize="14px" lineHeight="20px" color="on-surface-variant" letterSpacing="0.25px" {...textOverflowDots}>
          {isThisDevice ? t("mydevice") : status || ""}
        </Text>
      </Box>
      <Menu items={items}>
        <IconButton
          fill="on-surface"
          icon={VerticalDots}
          iconSize="1rem"
          as="span"
          tooltip={{ label: t("moreOptions") }}
        />
      </Menu>
    </Flex>
  );
}
