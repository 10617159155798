import { isEmptyArray } from "@/utils";
import { useMemo } from "react";
import { useActivityLog } from "viewer/store/activityLog";
import { useEventFilters } from "viewer/store/eventFilters";
import {
  getActiveConfig,
  getHasActiveFilters,
} from "viewer/store/eventFilters/selectors";

export default function useSceneCondition() {
  const eventFiltersState = useEventFilters();
  const activeConfig = getActiveConfig(eventFiltersState);
  const hasActiveFilters = getHasActiveFilters(eventFiltersState);
  const { loading, events } = useActivityLog((state) => ({
    loading: state.loading,
    events: state.events,
  }));

  const hasNoEvents = useMemo(
    () => !loading && isEmptyArray(events) && !hasActiveFilters,
    [events, hasActiveFilters, loading]
  );

  const hasNoFilteredResults = useMemo(
    () => !loading && isEmptyArray(events) && hasActiveFilters,
    [events, hasActiveFilters, loading]
  );
  const isInCameraHistory = useMemo(
    () => activeConfig.key === "fromCamera",
    [activeConfig.key]
  );
  return {
    hasNoEvents,
    hasNoFilteredResults,
    isInCameraHistory,
  };
}
