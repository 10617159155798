import { RPCRequests } from "@/modules/communication";
import environment from "@/modules/environment";
import { viewerStore } from "viewer/store/viewer";

type EventPayload = { type: string; filename: string; id: number; state?: "ended" | "started" };
const TIMEOUT = 15000;

export default class SW {
  private stateChangeResolve: Cb | null = null;
  constructor(private rpc: RPC, private workerPath = environment.publicPath + "/sw.js") {
    this.workerPath = workerPath;
    this.init();
  }

  private get jid() {
    return viewerStore().selectedCamera.jid as string;
  }

  private init = async () => {
    navigator.serviceWorker.addEventListener("message", async (e: MessageEvent<EventPayload>) => {
      const { type, filename, id } = e.data;
      try {
        if (type === "state-change" && this.stateChangeResolve) {
          this.stateChangeResolve();
          this.stateChangeResolve = null;
        }
        if (type === "file-request" || type === "gap-init" || type === "gap-segment") {
          const segmentType = filename.endsWith(".mp4") ? "INIT" : "DATA";
          const request = RPCRequests.GetCameraHistoryStreamSegmentFile.create({
            fileName: filename,
            segmentType
          });
          const { data, result, imageRotation, date } = (await this.rpc.call(
            request,
            this.jid
          )) as GetCameraHistoryStreamSegmentFileResponse;
          if (result !== "SUCCESS") return;
          if (segmentType === "INIT") {
            const event = new CustomEvent("hls-init-file", {
              detail: { date, imageRotation }
            });
            document.dispatchEvent(event);
          }
          this.post({ filename, data, id });
        }

        if (type === "initial-playlist-request") {
          const request = RPCRequests.GetCameraHistoryStreamPlaylistFile.create({
            fileName: filename,
            deltaUpdate: false
          });
          const { duration, startDate, videoRanges, data, result } = (await this.rpc.call(request, this.jid, {
            timeout: TIMEOUT
          })) as GetCameraHistoryStreamPlaylistFileResponse;
          if (result !== "SUCCESS") return;
          const event = new CustomEvent("playlist-update", {
            detail: { duration, startDate, videoRanges }
          });
          document.dispatchEvent(event);
          this.post({ filename, data, id });
        }
        if (type === "delta-playlist-request") {
          const request = RPCRequests.GetCameraHistoryStreamPlaylistFile.create({
            fileName: filename,
            deltaUpdate: true
          });
          const { duration, startDate, videoRanges, data, result } = (await this.rpc.call(
            request,
            this.jid
          )) as GetCameraHistoryStreamPlaylistFileResponse;
          if (result !== "SUCCESS") return;
          const event = new CustomEvent("playlist-update", {
            detail: { duration, startDate, videoRanges }
          });
          document.dispatchEvent(event);
          this.post({ filename, data, id });
        }
      } catch (err) {
        log.err("Error for", e.data.filename);
        log.err(err);
        this.post({ id, err: true });
      }
    });
  };

  private checkForExistingWorker = () => {
    return navigator.serviceWorker.controller;
  };

  private forceWorkerUpdate = async () => {
    const registration = await navigator.serviceWorker.register(this.workerPath);
    await registration.update();
  };

  private register = async () => {
    log.info("No active worker found, registering...");
    await this.unregister();

    const registration = await navigator.serviceWorker.register(this.workerPath);

    return new Promise((resolve) => {
      if (!registration.active) {
        const onStateChange = () => {
          if (registration.active?.state === "activated") {
            registration.installing?.removeEventListener("statechange", onStateChange);
            resolve(1);
          }
        };
        registration.installing?.addEventListener("statechange", onStateChange);
      } else resolve(1);
    });
  };

  start = async () => {
    log.info("About to start worker");
    const worker = this.checkForExistingWorker();
    if (!worker || worker.state !== "activated") await this.register();
    await this.forceWorkerUpdate();
    log.info("Worker has started", worker?.state);
    this.turnOn();
    return worker as ServiceWorker;
  };

  unregister = async () => {
    this.turnOff();
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      log.info("About to unregister service worker", registration);
      await registration.unregister();
    }
  };

  turnOn = () =>
    new Promise<void>((resolve) => {
      this.stateChangeResolve = resolve;
      this.post({ state: "started" });
    });

  turnOff = () =>
    new Promise<void>((resolve) => {
      this.stateChangeResolve = resolve;
      this.post({ state: "ended" });
    });

  private post = (message: any) => {
    navigator.serviceWorker.controller?.postMessage(message);
  };
}
