import { useMemo } from "react";
import { t } from "@/utils";
import { Flex, Heading, Text, Box, Badge } from "@chakra-ui/react";
import NavigationBar from "@/components/NavigationBar";
import ListButton from "@/components/ListButton";
import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import { useAppContext } from "@/modules/app/AppContextProvider";
import { useControls } from "@/hooks";
import Avatar from "viewer/components/Avatar";
import { openCameritoWeb } from "@/utils";
import Star from "assets/icons/star.svg?react";
import Trash from "assets/icons/trash.svg?react";
import Logout from "assets/icons/logout.svg?react";
import { ViewerSettingsStepProps } from "../SettingsFlow";
import DeleteAccountDialog from "../components/DeleteAccountDialog";

type MenuItem = {
  stringKey: StringKey;
  icon: React.FunctionComponent;
  iconFill?: string;
  action: () => void;
};

export default function Account(props: ViewerSettingsStepProps) {
  const state = useAuth();
  const deleteDialog = useControls();
  const { resetStation } = useAppContext();
  const isPremium = getHasActiveSubscription(state);

  const { user } = state;

  const items = useMemo(() => {
    const nonPremium: MenuItem[] = [
      {
        stringKey: "camerito.premium",
        icon: Star,
        action: () => openCameritoWeb("pricing")
      }
    ];
    const common: MenuItem[] = [
      {
        stringKey: "buttons.deleteAccount",
        icon: Trash,
        action: deleteDialog.open,
        iconFill: "primary"
      },
      {
        stringKey: "buttons.logOut",
        icon: Logout,
        action: resetStation,
        iconFill: "primary"
      }
    ];
    if (!isPremium) return [...nonPremium, ...common];
    return common;
  }, [deleteDialog.open, isPremium, resetStation]);

  return (
    <Box>
      <DeleteAccountDialog isOpen={deleteDialog.isOpen} close={deleteDialog.close} />
      <NavigationBar title={t("account")} onBack={props.previous} />
      <Flex mb="1.5rem" mt="0.5rem">
        <Avatar size="72px" textProps={{ fontSize: "32px", color: "on-tertiary" }} />
        <Box ml="1rem">
          <Flex alignItems="center" columnGap="0.5rem">
            <Heading as="h4">Camerito</Heading>

            <Badge
              variant={isPremium ? "premium-gradient" : "free-gradient"}
              bg={
                isPremium
                  ? "linear-gradient(275.61deg, #F9913B -0.89%, #FFD788 99.11%)"
                  : "linear-gradient(275.61deg, #A9A9A9 -0.89%, #CFCFCF 99.11%)"
              }
            >
              <Text
                fontWeight={700}
                fontSize="11px"
                lineHeight="14px"
                letterSpacing="0.5px"
                color={isPremium ? "orange.30" : "neutral.40"}
              >
                {t(isPremium ? "user.premium" : "user.free").toUpperCase()}
              </Text>
            </Badge>
          </Flex>
          <Text color="on-surface-variant" lineHeight="28px">
            {user?.email}
          </Text>
        </Box>
      </Flex>
      <Flex flexWrap="wrap" columnGap="1.5rem" rowGap="1rem">
        {items.map(({ stringKey, icon, action, iconFill }) => (
          <ListButton
            props={{ w: ["100%", "calc(50% - 12px)"] }}
            label={t(stringKey)}
            key={stringKey}
            icon={icon}
            iconFill={iconFill}
            onClick={action}
          />
        ))}
      </Flex>
    </Box>
  );
}
