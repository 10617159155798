import { create } from "zustand";

export const useDeviceRename = create<{
  device: StoreDevice | null;
  isOpen: boolean;
  open: ({ device }: { device: StoreDevice }) => void;
  close: () => void;
}>()((set) => ({
  isOpen: false,
  device: null,
  open({ device }) {
    set({ isOpen: true, device });
  },
  close() {
    set({ isOpen: false });
  }
}));
