import { useEffect } from "react";
import { useViewer } from "viewer/store/viewer";

export default function useVideoMode(jid?: string | null) {
  const { videoModes, setVideoMode } = useViewer((state) => ({
    videoModes: state.videoModes,
    setVideoMode: state.setVideoMode
  }));
  const currentMode = jid ? videoModes[jid] : null;

  useEffect(() => {
    if (jid && !currentMode) setVideoMode(jid, "contain");
  }, [currentMode, jid, setVideoMode]);

  const getModeForCamera = () => currentMode || "contain";

  return { getModeForCamera };
}
