import { useMemo } from "react";
import { Flex, Text, Heading, Divider, Box } from "@chakra-ui/react";
import Popover from "@/components/Popover";
import { t, openEmailClient } from "@/utils";
import Icon from "@/components/Icon";
import { useAppContext } from "@/modules/app/AppContextProvider";
import IconButton from "@/components/IconButton";
import { useAuth } from "@/store/auth";
import { textOverflowDots } from "@/theme/utils/style";
import Logout from "assets/icons/logout.svg?react";
import Help from "assets/icons/help_outlined.svg?react";
import Account from "assets/icons/account.svg?react";
import SettingsOutlined from "assets/icons/settings_outlined.svg?react";
import PlusOutlined from "assets/icons/plus_outlined.svg?react";
import Hamburger from "assets/icons/hamburger.svg?react";
import { useViewerSettings } from "viewer/modules/station/components/SettingsFlow";
import Avatar from "viewer/components/Avatar";
import { NavItem } from "./Navbar";

const MobileMenu = ({ openWizard }: { openWizard: Cb }) => {
  const user = useAuth((state) => state.user);
  const { resetStation } = useAppContext();
  const openSettings = useViewerSettings((state) => state.open);

  const menuItems = useMemo(
    () =>
      [
        {
          stringKey: "addCamera",
          action: openWizard,
          icon: PlusOutlined
        },
        {
          stringKey: "settings",
          action: () => openSettings("settings"),
          icon: SettingsOutlined
        },

        {
          stringKey: "account",
          action: () => openSettings("account"),
          icon: Account
        },
        {
          stringKey: "purchase.help.contactUs.heading",
          action: openEmailClient,
          icon: Help
        },
        {
          stringKey: "buttons.logOut",
          action: resetStation,
          icon: Logout
        }
      ] as const,
    [openSettings, openWizard, resetStation]
  );

  return (
    <Popover
      contentProps={{
        py: "8px",
        px: 0
      }}
      body={
        <>
          <Flex alignItems="center" columnGap="12px" maxW="310px" px="12px" pt="12px" pb="4px">
            <Avatar />
            <Heading fontSize="1rem" lineHeight="20px" {...textOverflowDots}>
              {user?.email}
            </Heading>
          </Flex>
          <Divider mt="8px" />
          {menuItems.map((item, index) => {
            const { action, icon, stringKey } = item;
            return (
              <Box key={stringKey}>
                <Flex px="12px" h="48px" alignItems="center">
                  <NavItem onClick={action as Cb}>
                    <Icon icon={icon} fill="on-surface-variant" />
                    <Text ml="1rem" color="on-surface">
                      {t(stringKey)}
                    </Text>
                  </NavItem>
                </Flex>
                {index === 2 && <Divider my="8px" />}
              </Box>
            );
          })}
        </>
      }
    >
      <IconButton icon={Hamburger} fill="on-surface-variant" tooltip={{ label: t("buttons.menu") }} />
    </Popover>
  );
};

export default MobileMenu;
