import { Box, Flex } from "@chakra-ui/react";
import PremiumBanner from "viewer/components/PremiumBanner";
import AdsComponent from "@/components/Ads";
import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";

export default function MonitoringAd() {
  const isPremium = useAuth(getHasActiveSubscription);
  if (isPremium) return null;
  return (
    <Box pos="absolute" top="1rem" w={["calc(100% - 280px)", "calc(100% - 330px)", "calc(100% - 572px)"]}>
      <Flex pos="relative" w="100%" h="100%" justifyContent="center" alignItems="center">
        <AdsComponent
          type="list"
          style={{ pos: "absolute", w: "100%", h: "90px", top: "0", display: "flex", justifyContent: "center" }}
          placeholderComponent={<PremiumBanner stringKey="adUnlock" styles={{ maxW: "672px" }} />}
        />
      </Flex>
    </Box>
  );
}
