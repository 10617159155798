import { createContext, useContext } from "react";

export const RequestProvider = createContext<{
  requestManager: RequestManager;
  wizard: Controls;
}>({
  requestManager: {} as any,
  wizard: {
    close: () => {},
    open: () => {},
    isOpen: false
  }
});

export const useRequester = () => useContext(RequestProvider);
