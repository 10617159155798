import { Flex, Box, Button, BoxProps, forwardRef, Spinner } from "@chakra-ui/react";
import { useMicrophoneToggle } from "viewer/modules/monitoring/utils";
import Microphone from "assets/icons/microphone.svg?react";
import { useRef, useEffect, useCallback } from "react";
import NoiseDetector from "viewer/modules/noise/NoiseDetector";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import Icon from "@/components/Icon";

const Bar = forwardRef<BoxProps, "div">((props, ref) => (
  <Box ref={ref} width="4px" height="4px" bg="white" borderRadius="1px" transition="transform 0.1s ease" />
));

export default function PttButton() {
  const { isActive, toggleMicrophone, isLoading } = useMicrophoneToggle();
  const firstBar = useRef<HTMLDivElement>(null);
  const secondBar = useRef<HTMLDivElement>(null);
  const thirdBar = useRef<HTMLDivElement>(null);
  const fourthBar = useRef<HTMLDivElement>(null);
  const barRefs = [firstBar, secondBar, thirdBar, fourthBar];

  const { active: micNotActivatedActive, hover: micNotActivatedHover } = useButtonStateColors(
    "primary.10",
    "primary.90"
  );
  const { active: micActivatedActive, hover: micActivatedHover } = useButtonStateColors("white", "primary.40");

  const onAudioData = useCallback(
    (noise: number) => {
      if (isActive && firstBar.current) {
        firstBar.current!.style.transform = `scaleY(${1 + noise * 1})`;
        secondBar.current!.style.transform = `scaleY(${3 + noise * 6})`;
        thirdBar.current!.style.transform = `scaleY(${2 + noise * 4})`;
        fourthBar.current!.style.transform = `scaleY(${1 + noise * 2})`;
      }
    },
    [isActive]
  );
  useEffect(() => {
    NoiseDetector.on("viewer-noise", onAudioData);

    return () => {
      NoiseDetector.off("viewer-noise", onAudioData);
    };
  }, [onAudioData]);
  return (
    <Button
      variant="unstyled"
      w="56px"
      h="56px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="12px"
      bg={isActive ? "primary.40" : "primary.90"}
      onClick={toggleMicrophone}
      cursor="pointer"
      boxShadow={
        "0 2px 4px -1px rgba(0, 0, 0, 0.12), 0 1px 16px 0 rgba(0, 0, 0, 0.06), 0 4px 8px 0 rgba(0, 0, 0, 0.08)"
      }
      _hover={{
        bg: isActive ? micActivatedHover : micNotActivatedHover
      }}
      _active={{
        bg: isActive ? micActivatedActive : micNotActivatedActive
      }}
    >
      {isLoading ? (
        <Spinner size="md" borderWidth="3px" />
      ) : isActive ? (
        <Flex alignItems="center" columnGap="3px" justifyContent="center">
          {barRefs.map((bar, index) => (
            <Bar key={index} ref={bar} />
          ))}
        </Flex>
      ) : (
        <Icon icon={Microphone} fill="primary.10" />
      )}
    </Button>
  );
}
