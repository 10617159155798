import { Text, Flex, Button, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDevices } from "@/store/devices";
import { getCameraDevices } from "@/store/devices/selectors";
import AppImage from "@/components/AppImage";

export default function NoPairedCameras({ openWizard }: { openWizard: Cb }) {
  const { t } = useTranslation();
  const hasDevices = useDevices(getCameraDevices).length !== 0;
  if (hasDevices) return null;
  return (
    <Flex direction="column" alignItems="center" justifyContent="center" flexGrow={1} pb="3rem">
      <AppImage name="no_cameras.svg" />
      <Heading as="h4" mb="12px" pt="2rem">
        {t("noCameras.title")}
      </Heading>
      <Text mb="2rem">{t("noCameras.description")}</Text>
      <Button onClick={openWizard}>{t("addCamera")}</Button>
    </Flex>
  );
}
