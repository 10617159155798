import { useState, useEffect, useCallback } from "react";
import Peers from "@/modules/Peers";
import { rtcEmitter } from "@/modules/events/emitter";
import { useViewer } from "viewer/store/viewer";

export function useIsPeerConnected(jid?: string | null) {
  const [isConnected, setIsConnected] = useState(true);

  const onConnectionStateChange = useCallback(
    (_jid: string) => {
      if (jid !== _jid) return;
      const peer = Peers.get(jid);
      if (!peer) return;
      const hasConnectionError = peer.getIsConnectionInErrorState();
      setIsConnected(!hasConnectionError);
    },
    [jid]
  );

  useEffect(() => rtcEmitter.on("connection-state-change", onConnectionStateChange), [onConnectionStateChange]);
  return isConnected;
}

export function useIsCurrentPeerConnected() {
  const jid = useViewer((state) => state.selectedCamera.jid);
  const isConnected = useIsPeerConnected(jid);
  return isConnected;
}
