import Flow from "@/lib/Flow";
import { useDevices } from "@/store/devices";
import { getCameraDevices } from "@/store/devices/selectors";
import steps from "./steps";

type Props = {
  requestManager: RequestManager;
  wizard: Controls;
};

export type WizardProps<T = Keyable> = FlowProviding<T> & {
  requestManager: RequestManager;
  wizard: Controls;
};

export default function Wizard({ requestManager, wizard }: Props) {
  const hasPairedCamera = useDevices(getCameraDevices).length > 0;

  return (
    <Flow
      steps={steps}
      initialStep={hasPairedCamera ? "enter-code" : "add-camera"}
      requestManager={requestManager}
      wizard={wizard}
    />
  );
}
