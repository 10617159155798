import Peers from "@/modules/Peers";
import { commEmitter, dataSyncEmitter, rtcEmitter } from "@/modules/events/emitter";
import { messages } from "@/modules/communication";
import RtcManager from "@/modules/RtcManager";
import { viewerStore } from "viewer/store/viewer";
import { getMediaStream } from "@/modules/stream/getMediaStream";
import { videoConstraints } from "@/modules/stream/constraints";

const { setLocalStream, destroyLocalTrack, setRemoteStream } = viewerStore();

export default class ViewerRtcManager {
  rtc: RtcManager;

  constructor() {
    this.rtc = new RtcManager(() => viewerStore().localStream);
  }

  startListening = () => {
    commEmitter.on(messages.WebRTCCandidate.name, this.rtc.handleWebRTCCandidates);
    commEmitter.on(messages.WebRTCCreateAnswer.name, this.rtc.handleAnswer);
    commEmitter.on(messages.WebRTCCreateOffer.name, this.rtc.handleOffer);
    commEmitter.on(messages.MotionInfoPacket.name, this.handleMotion);
    rtcEmitter.on("connection-state-change", this.handleConnectionStateChange);
    rtcEmitter.on("media-track", this.handleMediaTrack);
  };

  initializeConnection = async (jid: string) => {
    const peer = this.rtc.initializePeer(jid);
    await this.rtc.createAndSendOfferTo(peer);
  };

  private handleConnectionStateChange = (jid: string) => {
    const peer = Peers.get(jid);
    if (peer == null || peer.peerConnection == null) return;
    if (peer.peerConnection.connectionState === "disconnected") {
      log.rtc("connectionState is disconnected, closing...", jid);
      peer.peerConnection.close();
    }
  };

  private handleMediaTrack = (e: RTCTrackEvent, jid: string) => {
    log.rtc("handleMediaTrack fired ", e);
    const remoteStream = e.streams[0];
    setRemoteStream(jid, remoteStream);
  };

  private handleMotion = (motionPacket: {}, jid: string) => {
    log.rtc("received motion info packet");
  };

  checkConnection = async (jid: string) => {
    log.rtc("checking connection", jid);
    const peer = Peers.get(jid);
    if (peer == null || peer.peerConnection == null) return;
    const { connectionState } = peer.peerConnection;
    if (connectionState !== "closed") return;
    log.rtc("connectionState is 'closed', restarting...", jid);
    try {
      const newPeer = await this.rtc.recreateConnection(jid);
      await this.rtc.createAndSendOfferTo(newPeer);
    } catch (err) {
      log.err("failed connection check:", err, jid);
    }
  };

  waitForConnectionAndDataChannel = (jid: string) => {
    const peer = Peers.get(jid);
    return new Promise<void>((resolve, reject) => {
      if (!peer) {
        reject("No peer in store");
        return;
      }
      const timeout = setTimeout(() => reject("Connection timeout"), 7000);
      let isConnectionReady = false;
      let isDataChannelReady = false;

      const resolveConnection = () => {
        if (peer.peerConnection?.connectionState === "connected") {
          isConnectionReady = true;
          tryToResolve();
        }
      };
      const resolveDataChannel = () => {
        isDataChannelReady = true;
        tryToResolve();
      };

      const tryToResolve = () => {
        if (isConnectionReady && isDataChannelReady) {
          log.monitoring("Resolved Connection and DataChannel, continuing...");
          clearTimeout(timeout);
          resolve();
        }
      };

      if (peer.peerConnection?.connectionState !== "connected") {
        rtcEmitter.on("connection-state-change", resolveConnection);
        log.monitoring("Waiting for ConnectionState...");
      } else isConnectionReady = true;
      if (peer.commDataChannel?.readyState !== "open") {
        log.monitoring("Waiting for DataChannel...");
        rtcEmitter.on("data-channel-opened", resolveDataChannel);
      } else isDataChannelReady = true;
      tryToResolve();
    });
  };

  startAudioStream = async (jid: string) => {
    const peer = Peers.get(jid);
    const stream = await getMediaStream({
      audio: true,
      video: false
    });
    const audioTrack = stream.getAudioTracks()[0];
    let currentStream = viewerStore().localStream;
    if (currentStream) {
      currentStream.addTrack(audioTrack);
      setLocalStream(currentStream);
    } else setLocalStream(stream);
    if (peer.audioTransceiver) {
      await peer.audioTransceiver.sender.replaceTrack(stream.getAudioTracks()[0]);
    }
    await this.rtc.stream.startSendingStreamTo(jid, { audioOnly: true });
  };

  startVideoStream = async (jid: string) => {
    const peer = Peers.get(jid);
    const stream = await getMediaStream({
      audio: false,
      video: videoConstraints.premium.HD.video
    });
    const videoTrack = stream.getVideoTracks()[0];
    let currentStream = viewerStore().localStream;
    if (currentStream) {
      currentStream.addTrack(videoTrack);
      setLocalStream(currentStream);
    } else setLocalStream(stream);
    if (peer.videoTransceiver) {
      await peer.videoTransceiver.sender.replaceTrack(videoTrack);
    }
    await this.rtc.stream.startSendingStreamTo(jid, { videoOnly: true });
    dataSyncEmitter.emit("local-video-added");
  };

  stopVideoStream = async (jid: string) => {
    await this.rtc.stream.stopSendingStreamTo(jid, {
      stopVideoOnly: true
    });
    destroyLocalTrack("video");
    dataSyncEmitter.emit("local-video-removed");
  };

  stopAudioStream = async (jid: string) => {
    await this.rtc.stream.stopSendingStreamTo(jid, {
      stopAudioOnly: true
    });
    destroyLocalTrack("audio");
  };

  destroy = () => {
    commEmitter.off(messages.WebRTCCandidate.name, this.rtc.handleWebRTCCandidates);
    commEmitter.off(messages.WebRTCCreateAnswer.name, this.rtc.handleAnswer);
    commEmitter.off(messages.WebRTCCreateOffer.name, this.rtc.handleOffer);
    commEmitter.off(messages.MotionInfoPacket.name, this.handleMotion);
    rtcEmitter.off("connection-state-change", this.handleConnectionStateChange);
    rtcEmitter.off("media-track", this.handleMediaTrack);
    this.rtc.destroy();
  };
}
