import { Flex, Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Icon from "@/components/Icon";
import ActiveFilters from "viewer/modules/activityLog/components/ActiveFilters";
import FilterSelect from "viewer/modules/activityLog/components/FilterSelect";
import RefreshButton from "viewer/modules/activityLog/components/RefreshButton";
import ActivityLogFilled from "assets/icons/history_log_filled.svg?react";
import ActivityLog from "assets/icons/history_log.svg?react";
import GridOutlined from "assets/icons/grid.svg?react";
import GridFilled from "assets/icons/grid_filled.svg?react";

type Props = {
  onSceneChange: Cb<number>;
  sceneIndex: number;
  getCanNavigate: Cb<undefined, boolean>;
};

export default function Switcher({ onSceneChange, sceneIndex, getCanNavigate }: Props) {
  const { t } = useTranslation();
  const dashboardSelected = sceneIndex === 0;

  const onChange = (i: number) => {
    if (!getCanNavigate()) return;
    onSceneChange(i);
  };

  return (
    <Flex mb="1rem" alignItems="center" zIndex={1}>
      <Flex borderRadius="1rem" p="8" bg="surface2" w="fit-content">
        <Tabs onChange={onChange} index={sceneIndex}>
          <TabList>
            <Tab>
              <Flex align="center" columnGap="4px">
                <Icon icon={dashboardSelected ? GridFilled : GridOutlined} fill="on-surface-variant" />
                <Text
                  color="on-surface"
                  fontSize="1rem"
                  fontStyle="normal"
                  fontWeight={dashboardSelected ? 500 : 400}
                  lineHeight="1.5rem"
                  letterSpacing="0.5px"
                >
                  {t("dashboard")}
                </Text>
              </Flex>
            </Tab>

            <Tab>
              <Flex align="center" columnGap="4px">
                <Icon icon={dashboardSelected ? ActivityLog : ActivityLogFilled} fill="on-surface-variant" />
                <Text
                  color="on-surface"
                  fontSize="1rem"
                  fontStyle="normal"
                  fontWeight={dashboardSelected ? 400 : 500}
                  lineHeight="1.5rem"
                  letterSpacing="0.5px"
                >
                  {t("events.heading")}
                </Text>
              </Flex>
            </Tab>
          </TabList>
        </Tabs>
      </Flex>
      {sceneIndex === 1 && (
        <Flex ml="1rem" overflow="hidden" flexGrow="1">
          <ActiveFilters />
          <Flex gap="1rem">
            <FilterSelect />
            <RefreshButton />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
