import IconButton from "@/components/IconButton";
import { t } from "@/utils";
import Reload from "assets/icons/reload.svg?react";
import ActivityLogController from "viewer/modules/activityLog/ActivityLogController";

export default function RefreshButton() {
  return (
    <IconButton
      icon={Reload}
      fill="on-surface"
      onClick={ActivityLogController.downloadEvents}
      tooltip={{ label: t("refresh") }}
    />
  );
}
