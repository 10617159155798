import Icon from "@/components/Icon";
import { useTransparentize } from "@/theme/utils/transparentize";
import { Box, Text, ThemeTypings, Flex, BoxProps } from "@chakra-ui/react";
import { FunctionComponent } from "react";

type Props = {
  label: string;
  itemKey: string;
  action?: Cb;
  editable?: boolean;
  style?: BoxProps;
  icon?: FunctionComponent;
  iconFill?: ThemeTypings["colors"];
  axis?: "vertical" | "horizontal";
  disableReorder?: boolean;
  large?: boolean;
  active?: boolean;
};

const DropArea = ({
  position,
  axis = "horizontal"
}: {
  position: "left" | "right";
  axis: "vertical" | "horizontal";
}) => {
  return (
    <Box
      className={`dropArea ${axis}`}
      pos="absolute"
      w={axis === "horizontal" ? "8px" : "100%"}
      h={axis === "horizontal" ? "100%" : "8px"}
      zIndex={10}
      sx={{
        "&.horizontal": {
          w: "8px",
          h: "100%",
          left: position === "left" ? "0px" : undefined,
          right: position === "right" ? "0px" : undefined,
          transform: position === "left" ? `translateX(calc(-100% + 4px))` : `translateX(calc(100% - 4px))`,
          "::after": {
            left: position === "left" ? "0px" : undefined,
            right: position === "right" ? "0px" : undefined,
            transform: position === "left" ? `translateX(-50%)` : `translateX(50%)`,
            height: "100%",
            width: "2px"
          }
        },
        "&.vertical": {
          w: "100%",
          h: "8px",
          top: position === "left" ? "0px" : undefined,
          bottom: position === "right" ? "0px" : undefined,
          left: "0px",
          transform: position === "left" ? `translateY(calc(-100% + 4px))` : `translateY(calc(100% - 4px))`,
          "::after": {
            top: position === "left" ? "0px" : undefined,
            bottom: position === "right" ? "0px" : undefined,
            left: "0px",
            transform: position === "left" ? `translateY(-50%)` : `translateY(50%)`,
            height: "2px",
            width: "100%"
          }
        },
        "&.hovered": {
          _after: {
            content: "''",
            position: "absolute",
            bg: "primary"
          }
        }
      }}
      drop-area={position}
    />
  );
};

export default function DndItem({
  label,
  action,
  editable,
  icon,
  iconFill,
  style,
  axis = "horizontal",
  disableReorder,
  large,
  active,
  itemKey
}: Props) {
  const hoverBg = useTransparentize("primary", 0.16);
  const isMultiWorded = label.includes(" ");
  let className = "";
  if (disableReorder) className += "reorder-disabled";
  if (large) className += " variant-large";
  if (active) className += " variant-active";
  return (
    <Box
      item-key={itemKey}
      zIndex={1}
      className={className || undefined}
      onClick={editable ? undefined : action}
      tabIndex={0}
      w="60px"
      minW="60px"
      maxW="60px"
      position="relative"
      userSelect="none"
      cursor={editable ? "move" : "pointer"}
      draggable={editable}
      sx={{
        "&.reorder-disabled": {
          ".dropArea": {
            display: "none"
          }
        },
        "&.hovered": {
          ".drag-item-container": {
            bg: hoverBg
          }
        },
        "&.dragged": {
          ".drag-item-text, .drag-item-icon": {
            visibility: "hidden"
          }
        },
        "&.variant-large": {
          ".drag-item-icon": {
            bg: "surface-variant",
            borderRadius: "8px",
            p: "12px",
            mb: "4px"
          },
          ".drag-item-container": {
            py: "8",
            justifyContent: "normal",
            h: "auto"
          }
        },
        "&.variant-active": {
          pointerEvents: "none",
          ".drag-item-icon": {
            bg: "primary",
            color: "on-primary",
            svg: { path: { fill: "on-primary" } },
            borderRadius: "8px",
            p: "12px",
            mb: "4px"
          },
          ".drag-item-container": {
            p: "0",
            pt: "8px",
            pb: "10px",
            border: "none"
          }
        }
      }}
      {...style}
    >
      <DropArea position="left" axis={axis} />
      <DropArea position="right" axis={axis} />
      <Flex
        className="drag-item-container"
        w="100%"
        h="100%"
        direction="column"
        justify="center"
        align="center"
        py="10"
        borderRadius="16px"
        border="1px dashed"
        borderColor={editable ? "on-surface-variant" : "transparent"}
        pointerEvents="none"
        transition="background 0.2s"
      >
        {icon && (
          <Box className="drag-item-icon" pb="4px" borderRadius="8px">
            <Icon icon={icon} fill={iconFill || "black"} />
          </Box>
        )}

        <Text
          maxW="100%"
          className="drag-item-text"
          px="4px"
          whiteSpace="normal"
          lineHeight="0.75rem"
          align="center"
          fontSize="11px"
          width="68px"
          display={isMultiWorded ? "-webkit-box" : "block"}
          overflow="hidden"
          textOverflow="ellipsis"
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical"
          }}
        >
          {label}
        </Text>
      </Flex>
    </Box>
  );
}
