import { useState } from "react";
import { Flex, Skeleton } from "@chakra-ui/react";
import ImagePlaceholder from "assets/icons/image_placeholder.svg?react";
import Icon from "@/components/Icon";
import ImageWithBlurredBg from "viewer/modules/station/components/ImageWithBlurredBg";

export default function EventThumbnail({ url, hideBg }: { url: string | undefined; hideBg: boolean }) {
  const [loadError, setLoadError] = useState(false);
  const [isReady, setIsReady] = useState(false);

  return (
    <Flex h="100%" aspectRatio={16 / 9} borderRadius="1rem" overflow="hidden" pos="relative">
      {url && !isReady && <Skeleton pos="absolute" w="100%" h="100%" />}
      {url && !loadError ? (
        <ImageWithBlurredBg
          src={url}
          imageProps={{
            onError: () => setLoadError(true),
            onLoad: () => setIsReady(true)
          }}
        />
      ) : (
        <Flex justifyContent="center" alignItems="center" bg={hideBg ? undefined : "surface-variant"} h="100%" w="100%">
          {loadError && <Icon icon={ImagePlaceholder} fill="on-surface-variant" />}
        </Flex>
      )}
    </Flex>
  );
}
