import { useTranslation } from "react-i18next";
import { Flex, Heading, Box, Text, Button } from "@chakra-ui/react";
import AppImage from "@/components/AppImage";
import { useEventFilters } from "viewer/store/eventFilters";
import ActivityLogController from "../ActivityLogController";

const NoFilteredEvents = () => {
  const { t } = useTranslation();
  const { resetConfig, activeConfigKey } = useEventFilters(({ resetConfig, activeConfigKey }) => ({
    resetConfig,
    activeConfigKey
  }));

  const reset = () => {
    resetConfig(activeConfigKey, activeConfigKey === "fromCamera");
    ActivityLogController.downloadEvents();
  };

  return (
    <Flex w="100%" h="100%" justifyContent="center" alignItems="center" flexDir="column">
      <Flex textAlign="center" flexDir="column" alignItems="center" maxW="395px">
        <AppImage
          name="filter.svg"
          options={{
            imgProps: {
              htmlWidth: 264,
              htmlHeight: 192,
              mb: "2rem"
            }
          }}
        />
        <Heading as="h4" mb="12px">
          {t("noEvents.filter.title")}
        </Heading>
        <Text>{t("noEvents.filter.message")}</Text>
        <Button mt="2rem" variant="text" onClick={reset}>
          {t("noEvents.filter.clear")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default NoFilteredEvents;
