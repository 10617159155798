import { MouseEvent } from "react";
import { t } from "@/utils";
import { useIsMobileView } from "@/hooks";
import Logo from "@/components/Logo";
import { Flex, Text, Container, Button } from "@chakra-ui/react";
import Icon from "@/components/Icon";
import environment from "@/modules/environment";
import { getCameraDevices } from "@/store/devices/selectors";
import { useDevices } from "@/store/devices";
import { usePremiumScreen } from "@/modules/app/PremiumScreen";
import { useRequester } from "viewer/modules/pairing/Requester";
import { useViewerSettings } from "viewer/modules/station/components/SettingsFlow";
import SettingsOutlined from "assets/icons/settings_outlined.svg?react";
import PlusOutlined from "assets/icons/plus_outlined.svg?react";
import AvatarPopover from "./AvatarPopover";
import MobileMenu from "./MobileMenu";

const { appMode } = environment;

export const NavItem = ({ children, onClick }: { children: any; gap?: string; onClick?: Cb<MouseEvent> }) => (
  <Button
    variant="unstyled"
    display="flex"
    alignItems="center"
    columnGap="0.5rem"
    px="0.75rem"
    py="0.5rem"
    onClick={onClick}
    w="100%"
    justifyContent="flex-start"
  >
    {children}
  </Button>
);

export default function Navbar() {
  const { wizard } = useRequester();
  const openSettings = useViewerSettings((state) => state.open);
  const { displayBannerForFreeUser } = usePremiumScreen();
  const pairedCameras = useDevices(getCameraDevices).length;
  const isMobile = useIsMobileView();

  const openWizard = () => {
    if (pairedCameras >= 3) displayBannerForFreeUser("more_cameras").elseRun(wizard.open);
    else wizard.open();
  };

  return (
    <Container centerContent>
      <Flex align="center" justify="space-between" py="12px" w="100%">
        <Flex alignItems="center">
          <Logo />
          <Text size="md" variant="title" fontFamily="body" color="on-surface-variant" mt="0.125rem">
            {appMode !== "story" && " · " + t(appMode)}
          </Text>
        </Flex>
        {isMobile ? (
          <MobileMenu openWizard={openWizard} />
        ) : (
          <Flex gap="0.5rem" align="center" display={["none", "flex", "flex", "flex"]}>
            <NavItem onClick={openWizard}>
              <Icon fill="on-surface-variant" icon={PlusOutlined} />
              <Text>{t("addCamera")}</Text>
            </NavItem>
            <NavItem onClick={() => openSettings("settings")}>
              <Icon fill="on-surface-variant" icon={SettingsOutlined} />
              <Text>{t("settings")}</Text>
            </NavItem>
            <AvatarPopover openSettings={() => openSettings("account")} />
          </Flex>
        )}
      </Flex>
    </Container>
  );
}
