import { useMemo } from "react";
import { AspectRatio, Box, Flex, Spinner, Text, Badge } from "@chakra-ui/react";
import { motion } from "framer-motion";
import VerticalDots from "assets/icons/vertical_dots.svg?react";
import ActivityLog from "assets/icons/history_log.svg?react";
import SettingsOutlined from "assets/icons/settings_outlined.svg?react";
import Circle from "@/components/Circle";
import IconButton from "@/components/IconButton";
import Menu, { MenuProps } from "@/components/Menu";
import Icon from "@/components/Icon";
import { absoluteCenter, textOverflowDots } from "@/theme/utils/style";
import { CameraColors, CameraIcons } from "@/enums/camera";
import { t } from "@/utils";
import { useTransparentize } from "@/theme/utils/transparentize";
import { usePresenceKeeper } from "@/modules/app/Presence";
import LastActivity from "./LastActivity";
import ImageWithBlurredBg from "./ImageWithBlurredBg";
import BatteryIndicator from "./BatteryIndicator";

type Props = {
  camera: StoreCameraDevice;
  preview: CameraPreviewState[string];
  openCameraHistory: () => void;
  handleConnect: () => void;
  openSettings: () => void;
};

export default function CameraPreview({ camera, preview, handleConnect, openSettings, openCameraHistory }: Props) {
  const circleBorder = useTransparentize("neutral.99", 0.2);
  const presences = usePresenceKeeper();
  const presence = presences[camera.jid];

  const isAvailable = presence !== "unavailable" && presence !== "not_chosen_yet";
  const isCharging = preview?.status?.batteryStatus?.state === "CHARGING";
  const hasBatteryStatus = preview?.status?.batteryStatus?.state !== "UNKNOWN" && preview?.status?.batteryStatus?.level;
  const items: MenuProps["items"] = useMemo(
    () => [
      {
        item: t("event.history"),
        props: {
          icon: <Icon icon={ActivityLog} fill="on-surface-variant" />,
          onClick: openCameraHistory
        }
      },
      {
        item: t("camera.settings"),
        props: {
          onClick: openSettings,
          icon: <Icon icon={SettingsOutlined} fill="on-surface-variant" />
        }
      }
    ],
    [openCameraHistory, openSettings]
  );

  return (
    <Box maxW={["100%", "calc(50% - 12px)"]} flexBasis={["100%", "50%"]}>
      <Box
        w="100%"
        h="100%"
        as={motion.div}
        layoutId={`camera-view-${camera.jid}`}
        bg="surface-variant"
        borderTopRadius="md"
        borderBottomRadius="md"
        overflow="hidden"
      >
        <Box
          position="relative"
          bg="neutral-variant.20"
          borderRadius="md"
          onClick={handleConnect}
          _hover={{ cursor: "pointer" }}
          pointerEvents={isAvailable ? "auto" : "none"}
          overflow="hidden"
        >
          {(!preview?.image || !isAvailable) && (
            <Box {...absoluteCenter} color="white" zIndex={2}>
              {!preview?.image && isAvailable && <Spinner />}
              {!isAvailable && <h4>{t("camera.offline")}</h4>}
            </Box>
          )}
          {isAvailable && !isCharging && hasBatteryStatus && (
            <BatteryIndicator batteryLevel={preview!.status!.batteryStatus!.level} gradient />
          )}
          {isAvailable && (
            <Badge variant="status" pos="absolute" right="12px" top="12px" zIndex="1">
              {t("online").toUpperCase()}
            </Badge>
          )}
          <AspectRatio ratio={16 / 9}>
            {preview?.image?.url ? (
              <ImageWithBlurredBg displayForegroundImage={isAvailable} src={preview.image.url} />
            ) : (
              <></>
            )}
          </AspectRatio>
        </Box>
        <Flex p="15px" align="center" justify="space-between">
          <Flex maxW="calc(100% - 36px)" align="center" columnGap="12px">
            <Circle bg="surface" props={{ border: "1px solid", borderColor: circleBorder }}>
              {<Icon icon={CameraIcons[camera.icon].icon} fill={CameraColors[camera.color].value} />}
            </Circle>
            <Box overflow="hidden">
              <Text as="h5" color="on-surface" {...textOverflowDots}>
                {camera.name}
              </Text>
              <LastActivity preview={preview} isAvailable={isAvailable} />
            </Box>
          </Flex>
          <Menu items={items}>
            <IconButton
              icon={VerticalDots}
              fill="on-surface-variant"
              as="span"
              bg="surface-variant"
              tooltip={{ label: t("moreOptions") }}
            />
          </Menu>
        </Flex>
      </Box>
    </Box>
  );
}
