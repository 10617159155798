import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCameraDevices } from "@/store/devices/selectors";
import { useDevices } from "@/store/devices";
import Dialog from "@/components/Dialog";
import Modal from "@/components/Modal";
import { useControls } from "@/hooks";
import { useStation } from "viewer/store/station";
import Wizard from "../Wizard";
import RequestManager from "../RequestManager";
import { RequestProvider } from "./useRequester";
import Wrong from "assets/icons/wrong.svg?react";

export default function Requester({ children }: { children: React.ReactNode }) {
  const wizard = useControls();
  const hasPairedCamera = useDevices(getCameraDevices).length > 0;
  const { hasShownWizard, setHasShownWizard } = useStation();
  const errorDialog = useControls();
  const [rejected, setRejected] = useState<boolean>(false);
  const { t } = useTranslation();

  const [requestManager] = useState(
    () =>
      new RequestManager({
        onError: (type?: "reject" | "error") => {
          if (type === "reject") setRejected(true);
          errorDialog.open();
        }
      })
  );

  useEffect(() => {
    if (hasShownWizard || hasPairedCamera) return;
    setHasShownWizard(true);
    wizard.open();
  }, [hasShownWizard, setHasShownWizard, wizard, hasPairedCamera]);

  useEffect(() => {
    requestManager.init();
    return () => requestManager.destroy();
  }, [requestManager]);

  function resetPairing() {
    requestManager.reset();
    setRejected(false);
  }

  return (
    <>
      <RequestProvider.Provider value={{ wizard, requestManager }}>
        <Modal
          isOpen={wizard.isOpen}
          onClose={wizard.close}
          modalContentProps={{ minH: "612px" }}
          modalBodyProps={{ pt: 8 }}
        >
          <Wizard wizard={wizard} requestManager={requestManager} />
        </Modal>
        {children}
      </RequestProvider.Provider>
      <Dialog
        icon={Wrong}
        iconProps={{ fill: "error" }}
        isOpen={errorDialog.isOpen}
        onClose={() => {
          resetPairing();
          errorDialog.close();
          wizard.close();
        }}
        title={t(rejected ? "pairing.requester.rejectionDialog.heading" : "pairing.failureDialog.heading")}
        body={t(rejected ? "pairing.requester.rejectionDialog.text" : "pairing.failureDialog.text")}
        buttons={{
          cancel: {
            label: t("buttons.ok")
          }
        }}
      />
    </>
  );
}
