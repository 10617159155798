import { ViewerStore } from "./viewer";

export const getCurrentCameraState = (store: ViewerStore) => {
  if (!store.selectedCamera.jid) return null;
  return store.cameraConfigs[store.selectedCamera.jid]?.cameraState ?? null;
};

export const getCurrentCameraConfig = (store: ViewerStore) => {
  if (!store.selectedCamera.jid) return null;
  return store.cameraConfigs[store.selectedCamera.jid] ?? null;
};

export const getShouldCheckCameraAvailability = (store: ViewerStore) => {
  const {
    localDevices: { camera }
  } = store;
  if (!camera.askedForPermission || camera.error) return true;
  return false;
};

export const getShouldCheckMicrophoneAvailability = (store: ViewerStore) => {
  const {
    localDevices: { microphone }
  } = store;
  if (!microphone.askedForPermission || microphone.error) return true;
  return false;
};
