import { useRef, useState } from "react";
import { useViewer } from "viewer/store/viewer";
import NoiseDetector from "viewer/modules/noise/NoiseDetector";
import { useIsPeerConnected } from "viewer/hooks/useIsPeerConnected";
import { getShouldCheckMicrophoneAvailability } from "viewer/store/viewer/selectors";
import { useMonitoringDialog } from "../CameraView/MonitoringDialog";
import { useViewerActions } from "../MonitoringManager";
import checkLocalDeviceAvailability from "./checkLocalDeviceAvailability";

export const useMicrophoneToggle = () => {
  const isProcessing = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const { startAudioStream, stopAudioStream } = useViewerActions();
  const openMonitoringDialog = useMonitoringDialog((state) => state.open);
  const { localStream, selectedCamera, shouldCheckMicrophoneAvailability } = useViewer((state) => ({
    localStream: state.localStream,
    selectedCamera: state.selectedCamera,
    shouldCheckMicrophoneAvailability: getShouldCheckMicrophoneAvailability(state)
  }));
  const isPeerConnected = useIsPeerConnected(selectedCamera.jid);

  const start = async (jid: string) => {
    await startAudioStream(jid);
    await NoiseDetector.init();
    await NoiseDetector.connectStream();
    setIsActive(true);
  };

  const toggleMicrophone = async () => {
    if (!isPeerConnected) {
      openMonitoringDialog();
      return;
    }
    if (!selectedCamera.jid) return;
    if (isProcessing.current) return;
    if (shouldCheckMicrophoneAvailability) {
      const microphoneAvailability = await checkLocalDeviceAvailability("microphone");
      if ("error" in microphoneAvailability) {
        openMonitoringDialog("microphone", microphoneAvailability.error);
        return;
      }
    }

    isProcessing.current = true;
    setIsLoading(true);

    if (localStream && localStream.getAudioTracks().length > 0) {
      await stopAudioStream(selectedCamera.jid);
      await NoiseDetector.destroy();
      setIsActive(false);
    } else await start(selectedCamera.jid);
    isProcessing.current = false;
    setIsLoading(false);
  };

  return { toggleMicrophone, isActive, isLoading };
};
