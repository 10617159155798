import { Button, ButtonProps, Text } from "@chakra-ui/react";
import { t } from "@/utils";
import { usePremiumScreen } from "@/modules/app/PremiumScreen";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import { useAuth } from "@/store/auth";
import Icon from "@/components/Icon";
import Star from "assets/icons/star.svg?react";

export default function HdVideoButton({ style }: { style?: ButtonProps }) {
  const { displayBannerForFreeUser } = usePremiumScreen();
  const isPremium = useAuth(getHasActiveSubscription);

  if (isPremium) return null;
  return (
    <Button
      onClick={() => displayBannerForFreeUser("hd")}
      variant="blurred"
      pos="absolute"
      top="32"
      right="144"
      display="flex"
      alignItems="center"
      py="8"
      {...style}
    >
      <Icon icon={Star} style={{ mr: "0.5rem" }} />
      <Text variant="label" color="neutral.99">
        {t("getHdVideo")}
      </Text>
    </Button>
  );
}
