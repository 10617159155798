import { addGuard } from "@/utils";
import { RPCRequests } from "@/modules/communication";
import { viewerStore } from "viewer/store/viewer";

const { setCameraConfig } = viewerStore();

export default class Actions {
  private rpc!: RPC;
  private get jid() {
    return viewerStore().selectedCamera.jid as string;
  }

  init = (rpc: RPC) => {
    this.rpc = rpc;
  };

  changeCamera = async (cameraId: string) => {
    const request = RPCRequests.ChangeCamera.create({ cameraId });
    const rpcCall = await addGuard(() => this.rpc.call(request, this.jid!) as Promise<CameraCommandResponse>, {
      onSuccess: (response) => {
        const { result, cameraAndFlashlightState } = response;
        if (result === "SUCCESS") setCameraConfig(this.jid, { cameraState: cameraAndFlashlightState });
      },
      onError: (err) => log.err("'changeLowLightMode' request failed", err)
    });
    return Boolean(rpcCall.result);
  };

  changeLowLightMode = async () => {
    const { lowLightMode } = viewerStore().cameraConfigs[this.jid].cameraState;
    const newLowLightState: LowLightMode = lowLightMode === "LL_AUTO" ? "LL_OFF" : "LL_AUTO";
    const request = RPCRequests.SetLowLightMode.create(newLowLightState);

    const rpcCall = await addGuard(() => this.rpc.call(request, this.jid) as Promise<CameraCommandResponse>, {
      onSuccess: (response) => {
        const { result, cameraAndFlashlightState } = response;
        if (result === "SUCCESS") setCameraConfig(this.jid, { cameraState: cameraAndFlashlightState });
      },
      onError: (err) => log.err("'changeLowLightMode' request failed", err)
    });
    return Boolean(rpcCall.result);
  };

  syncMonitoring = async () => {
    const request = RPCRequests.SyncMonitoring.create();
    const rpcCall = await addGuard(() => this.rpc.call(request, this.jid) as Promise<SyncMonitoringResponse>, {
      onSuccess: (response) => {
        const { batteryStatus, cameraState } = response;
        setCameraConfig(this.jid, { batteryStatus, cameraState });
      },
      onError: (err) => log.err("'syncMonitoring' request failed", err)
    });
    return Boolean(rpcCall.result);
  };
}
