import { useCallback, useEffect, useState } from "react";
import { isEmptyArray } from "@/utils";

export default class ModalManager {
  private static openedModals: number[] = [];
  private static callbacks: ((hasModalOpened: boolean) => void)[] = [];

  static get isAnyModalOpened() {
    return !isEmptyArray(this.openedModals);
  }

  static subscribe = (callback: (hasModalOpened: boolean) => void) => {
    this.callbacks.push(callback);
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  };

  static add = () => {
    this.openedModals.push(1);
    this.callbacks.forEach((cb) => cb(this.isAnyModalOpened));
  };

  static remove = () => {
    this.openedModals.pop();
    this.callbacks.forEach((cb) => cb(this.isAnyModalOpened));
  };
}

export function useModalManager() {
  const [isModalOpen, setIsModalOpen] = useState(ModalManager.isAnyModalOpened);

  const onModalUpdate = useCallback((isAnyModalOpened: boolean) => {
    setIsModalOpen(isAnyModalOpened);
  }, []);

  useEffect(() => {
    const unsub = ModalManager.subscribe(onModalUpdate);
    return unsub;
  }, [onModalUpdate]);

  return {
    isModalOpen,
    addModalToManager: ModalManager.add,
    removeModalFromManager: ModalManager.remove,
  };
}
