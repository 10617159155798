import { Progress } from "@chakra-ui/react";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";

type Props = {
  duration?: number;
  controllerRef: MutableRefObject<{ end: () => void; set: (to: number) => void } | null>;
};

export type ProgressController = Props["controllerRef"]["current"];

const MAX_EVENT_DURATION = 15 * 60 * 1000;
const MAX_PREPARE_DURATION = 1.5 * 60 * 1000;
const UPDATE_INTERVAL = 60;
const MAX_PREPARE_PROGRESS = 0.5;

export default function ArtificialProgress({ duration, controllerRef }: Props) {
  const [value, setValue] = useState(0);
  const progress = useRef(0);
  const controller = useRef<Props["controllerRef"]["current"]>({
    end() {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
      progress.current = MAX_PREPARE_PROGRESS;
      setValue(MAX_PREPARE_PROGRESS);
    },
    set(pr) {
      progress.current = pr;
      setValue(progress.current);
    }
  });
  const interval = useRef<NodeJS.Timeout | null>(null);
  controllerRef.current = controller.current;

  const updateInterval = useMemo(() => {
    const preparingDuration = duration ? (MAX_PREPARE_DURATION * duration) / MAX_EVENT_DURATION : MAX_PREPARE_DURATION;
    return preparingDuration / UPDATE_INTERVAL;
  }, [duration]);

  const startUpdating = useCallback(() => {
    if (interval.current) return;
    interval.current = setInterval(() => {
      progress.current = Math.min(MAX_PREPARE_PROGRESS, progress.current + MAX_PREPARE_PROGRESS / UPDATE_INTERVAL);
      setValue(progress.current);
    }, updateInterval);
  }, [updateInterval]);

  useEffect(() => {
    startUpdating();
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [startUpdating]);

  return <Progress value={value} max={1} min={0} />;
}
