import { useAuth } from "@/store/auth";
import { commEmitter } from "@/modules/events/emitter";
import { messages } from "@/modules/communication";
import Messenger from "@/modules/Messenger";
import { requestDevicePairing } from "../cloud/pairing";
import { generateOtp } from "@/modules/cloud/auth";

type PairingRequest = { pairingType: PairingType; requestId: string };
type PairingResponder = {
  jid: string;
  name: string;
  code: string;
};
type ErrorType = "reject" | "error";

export default class RequestManager {
  private request: PairingRequest | null = null;
  private responder: PairingResponder | null = null;
  private messenger = Messenger.getInstance();
  private onError: (type?: ErrorType) => void;
  private push: FlowProviding["push"] | null = null;

  constructor({ onError }: { onError: (type?: ErrorType) => void }) {
    this.onError = onError;
  }

  init = () => {
    log.pairing("starting request manager");
    commEmitter.on(messages.RequestPairingResponseMessage.name, this.onPairingResponse);
  };

  destroy = () => {
    commEmitter.off(messages.RequestPairingResponseMessage.name, this.onPairingResponse);
    this.reset();
  };

  reset = () => {
    log.pairing("resetting request manager");
    this.request = null;
    this.responder = null;
    this.push = null;
  };

  initiatePairing = async ({ responder, push }: { responder: PairingResponder; push: FlowProviding["push"] }) => {
    log.pairing("initiating pairing with responder: ", responder);
    try {
      push("waiting");
      this.responder = responder;
      this.push = push;

      const request = await requestDevicePairing(responder.jid);
      log.pairing("pairing request from server ", request);
      this.request = request as PairingRequest;

      switch (this.request.pairingType) {
        case "OUR_FAMILY":
        case "INITIAL": {
          log.auth("generating otp");
          const otpData = await generateOtp();
          this.sendPairingRequest({
            otp: otpData.otp,
            authId: otpData.authId
          });
          return;
        }
        default:
          return;
      }
    } catch (error) {
      log.err(error);
      push("enter-code");
      this.reset();
      this.onError();
    }
  };

  private sendPairingRequest = (params: SendPairingRequestParams = {}) => {
    log.pairing("sending pairing request", params);
    const deviceName = useAuth.getState().device.name;
    try {
      const message = messages.RequestPairingMessage.create({
        pairingSource: "MANUAL_PAIRING",
        requestId: params.requestId || this.request!.requestId,
        pairingType: params.pairingType || this.request!.pairingType,
        securityCode: params.securityCode || this.responder!.code,
        deviceName: deviceName,
        authId: params.authId,
        otp: params.otp
      });

      log.pairing("sending pairing request message", message);

      this.messenger.sendAsEnvelope({
        to: params.to || this.responder!.jid,
        payload: { message }
      });
    } catch (err) {
      log.err("Error trying to send pairing request");
      log.err(err);
    }
  };

  private onPairingResponse = async (response: RequestPairingResponseMessage, from: string) => {
    log.pairing("received pairing response", response, from);
    log.pairing("current request: ", this.request);
    if (!this.request || this.request.requestId !== response.requestId) {
      return;
    }

    log.pairing("pairing response", response);
    switch (response.result) {
      case "REJECTED":
        this.onError("reject");
        return;
      case "FAILED":
        this.onError("error");
        return;
      case "PAIRING_IN_PROGRESS":
        log.pairing("pairing failed");
        this.push?.("enter-code");
        this.reset();
        return;
      case "ACCEPTED": {
        log.pairing("pairing was accepted");
        this.push?.("customize", { stateUpdate: { jid: from, name: response.deviceName } });
        return;
      }
      default:
        this.onError("error");
        this.push?.("enter-code");
        this.reset();
        return;
    }
  };
}
