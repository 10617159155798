import { useState, useEffect } from "react";
import { Heading, Spinner, Flex } from "@chakra-ui/react";
import { t } from "@/utils";
import { absoluteCenter } from "@/theme/utils/style";
import ImageWithBlurredBg from "viewer/modules/station/components/ImageWithBlurredBg";
import { useIsPeerConnected } from "viewer/hooks/useIsPeerConnected";
import { useViewer } from "viewer/store/viewer";
import MonitoringError from "./MonitoringError";
import { useVideoMode } from "../utils";

export default function MonitoringErrorOverlay() {
  const [previewUrl, setPreviewUrl] = useState<null | string>(null);
  const { cameraPreviews, jid } = useViewer((state) => ({
    cameraPreviews: state.cameraPreviews,
    jid: state.selectedCamera.jid
  }));
  const connected = useIsPeerConnected(jid);
  const { getModeForCamera } = useVideoMode(jid);
  const videoMode = getModeForCamera();

  useEffect(() => {
    if (jid && cameraPreviews[jid]?.image?.url) setPreviewUrl(cameraPreviews[jid]!.image!.url);
  }, [cameraPreviews, jid]);

  return (
    <>
      <MonitoringError cameraError={!connected} />
      {!connected && (
        <>
          {previewUrl && (
            <ImageWithBlurredBg
              src={previewUrl}
              displayForegroundImage={false}
              blur={30}
              containerProps={{ bg: "neutral-variant.20", ...absoluteCenter, zIndex: -1 }}
              imageProps={{ objectFit: videoMode }}
            />
          )}
          <Flex {...absoluteCenter} justifyContent="center" flexDir="column" alignItems="center" rowGap="1.5rem">
            <Spinner color="neutral.99" />
            <Heading as="h4" color="neutral.99">
              {t("videoNotAvailable")}
            </Heading>
          </Flex>
        </>
      )}
    </>
  );
}
