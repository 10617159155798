import Icon from "@/components/Icon";
import { Box, BoxProps } from "@chakra-ui/react";
import BatteryIcon from "assets/icons/battery.svg?react";

const AVAILABLE_PX_IN_ICON = 15;

export default function BatteryIndicator({
  batteryLevel,
  gradient,
  gradientStyles
}: {
  batteryLevel: number;
  gradient?: boolean;
  gradientStyles?: BoxProps;
}) {
  if (gradient)
    return (
      <GradientBox {...gradientStyles}>
        <Battery batteryLevel={batteryLevel} />
      </GradientBox>
    );
  return <Battery batteryLevel={batteryLevel} />;
}

const GradientBox = ({ children, gradientStyles }: { children: JSX.Element; gradientStyles?: BoxProps }) => {
  return (
    <Box
      pos="absolute"
      top="0"
      left="0"
      w="120px"
      h="120px"
      bg="linear-gradient(135deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.00) 50%)"
      zIndex={2}
      {...gradientStyles}
    >
      <Box pos="absolute" left="16px" bg="transparent" top="12px" zIndex={2}>
        {children}
      </Box>
    </Box>
  );
};

const Battery = ({ batteryLevel }: { batteryLevel: number }) => {
  return (
    <Box pos="relative">
      <Icon icon={BatteryIcon} />
      <Box
        bg={batteryLevel > 20 ? "white" : "error"}
        w={(AVAILABLE_PX_IN_ICON / 100) * batteryLevel + "px"}
        h="8px"
        borderRadius="1px"
        pos="absolute"
        left="2px"
        top="50%"
        transform="translateY(-50%)"
      />
    </Box>
  );
};
