import Dialog, { DialogProps } from "@/components/Dialog";
import { useTranslation } from "react-i18next";
import WrongIcon from "assets/icons/wrong.svg?react";

type Props = {
  isOpen: boolean;
  close: Cb;
  errorMessage: PairingCodeInfoError | null;
  props: DialogProps["props"];
};

export default function PairingCodeFailureDialog({ isOpen, close, errorMessage, props }: Props) {
  const { t } = useTranslation();

  let title: string;
  let body: string;
  switch (errorMessage) {
    case "code_not_found":
      title = t(`pairing.manually.enterCode.failureDialog.codeNotFound.heading`);
      body = t(`pairing.manually.enterCode.failureDialog.codeNotFound.text`);
      break;
    case "code_expired":
      title = t(`pairing.manually.enterCode.failureDialog.codeExpired.heading`);
      body = t(`pairing.manually.enterCode.failureDialog.codeExpired.text`);
      break;
    default:
      title = t(`pairing.failureDialog.heading`);
      body = t(`pairing.failureDialog.text`);
      break;
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      title={title}
      body={body}
      props={props}
      icon={WrongIcon}
      iconProps={{ fill: "error" }}
      buttons={{ cancel: { label: t("buttons.ok") } }}
    />
  );
}
