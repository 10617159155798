import { RPCRequests } from "@/modules/communication";
import Peers from "@/modules/Peers";
import { peerEmitter } from "@/modules/events/emitter";

type Constructor = {
  rpc: RPC;
  checkConnection: Cb<string>;
  syncMonitoring: Cb<undefined, Promise<boolean>>;
};

const INTERVAL = 5 * 1000;
const SYNC_THRESHOLD = 40 * 1000;

export default class PingService {
  private interval: NodeJS.Timeout | null = null;
  private pingQueue: { [jid: string]: boolean } = {};
  private pingTimes: { [jid: string]: number } = {};
  private request = RPCRequests.Ping.create();
  private unsubscribe: Cb | null = null;

  private rpc: Constructor["rpc"];
  checkConnection: Constructor["checkConnection"];
  syncMonitoring: Constructor["syncMonitoring"];

  constructor({ rpc, checkConnection, syncMonitoring }: Constructor) {
    this.rpc = rpc;
    this.checkConnection = checkConnection;
    this.syncMonitoring = syncMonitoring;
  }

  start = () => {
    if (Object.keys(Peers.getAll()).length === 0) {
      this.waitForPeers();
      return;
    }
    this.interval = setInterval(this.playPingPong, INTERVAL);
  };

  stop = () => {
    if (this.interval) clearInterval(this.interval);
    this.unsubscribe?.();
    this.pingQueue = {};
    this.pingTimes = {};
  };

  private waitForPeers = () => {
    this.unsubscribe = peerEmitter.on("peer-added", () => {
      this.unsubscribe?.();
      this.unsubscribe = null;
      this.start();
    });
  };

  private playPingPong = async () => {
    const peers = Peers.getAll();

    await Promise.all(
      Object.values(peers).map(async ({ jid }) => {
        try {
          if (this.pingQueue[jid]) return;
          this.pingQueue[jid] = true;

          await this.rpc.call(this.request, jid);
          this.checkConnection(jid);
          delete this.pingQueue[jid];

          const now = new Date().getTime();
          const lastPingTime = this.pingTimes[jid] || (this.pingTimes[jid] = now);
          if (now > lastPingTime + SYNC_THRESHOLD) await this.syncMonitoring();
          this.pingTimes[jid] = now;
        } catch (err) {
          delete this.pingQueue[jid];
          log.info("ping timed out");
          log.err(err);
        }
      })
    );
  };
}
