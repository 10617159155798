import { cloudCode } from "@/modules/cloud";

export function updateCameraStationControls({
  xmppLogin,
  cameraControls
}: {
  xmppLogin: string;
  cameraControls: CameraControls;
}): Promise<ParseCameraDevice> {
  return cloudCode.run("updateCameraStationControls", {
    xmppLogin,
    cameraControls
  });
}

export async function getDevicesLatestActivity() {
  const response = (await cloudCode.run("getDevicesLatestActivity")) as DeviceActivity[];
  return response;
}
