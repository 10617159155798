import HowToInstall from "@/shared-steps/HowToInstall";
import DeviceSelect from "@/shared-steps/DeviceSelect";
import AddCamera from "./AddCamera";
import EnterCode from "./EnterCode";
import Waiting from "./Waiting";
import CantFindCode from "./CantFindCode";
import InitialCustomize from "./InitialCustomize";

const steps = [
  { id: "add-camera", Component: AddCamera },
  { id: "enter-code", Component: EnterCode },
  { id: "waiting", Component: Waiting },
  { id: "customize", Component: InitialCustomize },
  { id: "cant-find-code", Component: CantFindCode },
  { id: "how-to-install", Component: HowToInstall },
  { id: "device-select", Component: DeviceSelect }
] as {
  id: string;
  Component: (props: FlowProviding & Keyable) => JSX.Element;
}[];

export default steps;
