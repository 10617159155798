import React, { RefObject, useMemo, useRef } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useDevices } from "@/store/devices";
import Icon from "@/components/Icon";
import { t } from "@/utils";
import { DndItem, EmptyDndItem } from "viewer/lib/dnd/components";
import { EmptyFillDndItem } from "viewer/lib/dnd/components/EmptyDndItem";
import Microphone from "assets/icons/microphone.svg?react";

type Props = {
  actionStates: ActionStates;
  actionController: ActionController;
  mainBarRef: RefObject<HTMLDivElement>;
  sideBarRef: RefObject<HTMLDivElement>;
  unusedBarRef: RefObject<HTMLDivElement>;
  jid: string;
};

function getFixedArray<T>(arr: T[]): T[] {
  return [...arr, ...Array(2).slice(arr.length)];
}

export default function EditablePanels({
  actionController,
  jid,
  actionStates,
  mainBarRef,
  sideBarRef,
  unusedBarRef
}: Props) {
  const device = useDevices((s) => s.devices)[jid] as StoreCameraDevice;
  const { primaryControls, secondaryControls } = useRef(device.cameraControls).current!;

  const { map: controlsMap } = actionController;

  const [mainLeftControls, mainRightControls] = useMemo(() => {
    const leftActions = primaryControls.slice(0, 2);
    const rightActions = primaryControls.slice(2, 4);
    return [getFixedArray(leftActions), getFixedArray(rightActions)];
  }, [primaryControls]);

  const unusedActions = useMemo(() => {
    return actionController.mapKeys.filter((key) => !primaryControls.includes(key) && !secondaryControls.includes(key));
  }, [actionController.mapKeys, primaryControls, secondaryControls]);

  return (
    <>
      <Text my="0.5rem">{t("mainPanel")}</Text>
      <Flex
        ref={mainBarRef}
        gap="1rem"
        p="8"
        borderRadius="24px"
        bg="surface1"
        w="fit-content"
        flexWrap="wrap"
        h="110px"
      >
        {mainLeftControls.map((item, i) => {
          if (!item) {
            return (
              <React.Fragment key={"left" + i}>
                <EmptyDndItem show />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={item}>
              <EmptyDndItem />
              <DndItem
                itemKey={controlsMap[item].key}
                label={t(controlsMap[item].labelKey)}
                icon={controlsMap[item].icon}
                iconFill="on-surface"
                editable
                disableReorder
                large
                active={actionStates[item].isActive}
              />
            </React.Fragment>
          );
        })}
        <Flex
          w="56px"
          h="56px"
          bg="surface-variant"
          borderRadius="12px"
          justify="center"
          align="center"
          alignSelf="flex-start"
          mt="8px"
        >
          <Icon icon={Microphone} fill="on-surface" />
        </Flex>
        {mainRightControls.map((item, i) => {
          if (!item) {
            return (
              <React.Fragment key={"right" + i}>
                <EmptyDndItem show />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={item}>
              <EmptyDndItem />
              <DndItem
                itemKey={controlsMap[item].key}
                label={t(controlsMap[item].labelKey)}
                icon={controlsMap[item].icon}
                iconFill="on-surface"
                editable
                disableReorder
                large
                active={actionStates[item].isActive}
              />
            </React.Fragment>
          );
        })}
      </Flex>

      <Text mb="0.5rem" mt="1rem">
        {t("sidePanel")}
      </Text>
      <Flex
        ref={sideBarRef}
        gap="0.5rem"
        wrap="wrap"
        borderBottom="1px"
        borderColor="neutral-variant.90"
        pb="1.5rem"
        h="100px"
      >
        {secondaryControls.map((item) => (
          <DndItem
            itemKey={controlsMap[item].key}
            label={t(controlsMap[item].labelKey)}
            icon={controlsMap[item].icon}
            iconFill="on-surface"
            editable
            key={item}
            active={actionStates[item].isActive}
          />
        ))}
        <EmptyFillDndItem />
      </Flex>
      <Text mt="12px" mb="8px">
        {t("unusedActions")}
      </Text>
      <Flex ref={unusedBarRef} wrap="wrap" gap="8px">
        {unusedActions.map((item) => (
          <DndItem
            itemKey={controlsMap[item].key}
            label={t(controlsMap[item].labelKey)}
            icon={controlsMap[item].icon}
            iconFill="on-surface"
            editable
            key={item}
            active={actionStates[item].isActive}
          />
        ))}
        <EmptyFillDndItem />
      </Flex>
    </>
  );
}
