import { Flex, Text, Button } from "@chakra-ui/react";
import Icon from "@/components/Icon";
import { t } from "@/utils";
import Wrong from "assets/icons/wrong.svg?react";
import ActivityLogController from "../ActivityLogController";

export default function EventsError() {
  return (
    <Flex flexDir="column" alignItems="center" h="100%" w="100%" justifyContent="center">
      <Icon icon={Wrong} size="24" fill="on-surface" />
      <Text mt="0.75rem">{t("loadEvents.error")}</Text>
      <Button mt="2rem" onClick={ActivityLogController.downloadEvents}>
        {t("buttons.retry")}
      </Button>
    </Flex>
  );
}
