import { FlexProps, Heading, TextProps } from "@chakra-ui/react";
import { useAuth } from "@/store/auth";
import Circle from "@/components/Circle";

export default function Avatar({
  size,
  textProps,
  circleProps,
}: {
  size?: string;
  textProps?: TextProps;
  circleProps?: FlexProps;
}) {
  const user = useAuth((state) => state.user);
  if (!user?.email) return null;
  return (
    <Circle bg="tertiary" size={size} props={{ ...circleProps }}>
      <Heading as="h5" color="on-tertiary" {...textProps}>
        {user.email.charAt(0).toUpperCase()}
      </Heading>
    </Circle>
  );
}
