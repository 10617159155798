import { useTransparentize } from "@/theme/utils/transparentize";
import { Box, BoxProps } from "@chakra-ui/react";

type EmptyFillItemProps = {
  style?: BoxProps;
};

type EmptyItemProps1 = {
  style?: BoxProps;
  show?: boolean;
};

export default function EmptyDndItem({ style, show }: EmptyItemProps1) {
  const hoverBg = useTransparentize("primary", 0.16);
  let className = "empty-area";
  if (!show) className += " hidden";
  return (
    <Box
      className={className}
      w="60px"
      minW="60px"
      maxW="60px"
      h="82px"
      minH="82px"
      transition="background 0.2s"
      {...style}
      sx={{
        "&.hidden": {
          display: "none",
        },
        "&.hovered": {
          ".empty-item-box": {
            bg: hoverBg,
          },
        },
      }}
    >
      <Box
        pointerEvents="none"
        className="empty-item-box"
        w="100%"
        h="100%"
        borderRadius="16px"
        border="1px dashed"
        borderColor="on-surface-variant"
      />
    </Box>
  );
}

export function EmptyFillDndItem({ style }: EmptyFillItemProps) {
  return (
    <Box
      className="empty-fill-area"
      minW="60px"
      minH="69px"
      flexGrow={1}
      border="1px solid transparent"
      {...style}
      sx={{
        "&.hovered": {
          borderRight: "none",
          borderTop: "none",
          borderBottom: "none",
          borderLeft: "2px solid",
          borderColor: "primary",
        },
      }}
    />
  );
}
