import { Flex, Button, Text, ThemeTypings } from "@chakra-ui/react";
import { t } from "@/utils";
import { useAuth } from "@/store/auth";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import { usePremiumScreen } from "@/modules/app/PremiumScreen";
import Icon from "@/components/Icon";
import Lock from "assets/icons/lock_outlined.svg?react";

export default function UnlockButton() {
  const isPremium = useAuth(getHasActiveSubscription);
  const { displayBannerForFreeUser } = usePremiumScreen();

  const buttonBg: ThemeTypings["colors"] = "primary.40";
  const buttonColor: ThemeTypings["colors"] = "neutral.99";
  const { active, hover } = useButtonStateColors(buttonColor, buttonBg);

  if (isPremium) return null;
  return (
    <Flex alignItems="center" pos="absolute" bottom="32px" left="203px" zIndex={5}>
      <Button
        py="8"
        px="12"
        bg={buttonBg}
        color={buttonColor}
        _hover={{ bg: hover }}
        _active={{ bg: active }}
        onClick={() => displayBannerForFreeUser("replay")}
      >
        <Icon icon={Lock} size="20" fill="neutral.99" style={{ mr: "4px" }} />
        <Text variant="label" color="neutral.99">
          {t("unlockMore")}
        </Text>
      </Button>
    </Flex>
  );
}
