export function throttle<T extends (...args: any[]) => any>(
  cb: T,
  timeout: number
): (...args: Parameters<T>) => ReturnType<T> | void {
  let waiting = false;

  return (...args: Parameters<T>): ReturnType<T> | void => {
    if (waiting) return;
    waiting = true;
    const result = cb(...args);
    setTimeout(() => {
      waiting = false;
    }, timeout);
    return result;
  };
}
