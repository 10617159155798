import {
  Popover as ChakraPopover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverProps,
  PopoverContentProps,
} from "@chakra-ui/react";

type Props = {
  children: any;
  arrow?: boolean;
  closeButton?: boolean;
  title?: string | React.ReactNode;
  body?: any;
  footer?: any;
  popoverProps?: PopoverProps;
  contentProps?: PopoverContentProps;
};

export default function Popover({
  children,
  arrow,
  closeButton,
  title,
  body,
  footer,
  popoverProps,
  contentProps,
}: Props) {
  return (
    <ChakraPopover placement="bottom-start" {...popoverProps}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent {...contentProps}>
        {arrow && <PopoverArrow />}
        {closeButton && <PopoverCloseButton />}
        {title && <PopoverHeader>{title}</PopoverHeader>}
        {body && <PopoverBody>{body}</PopoverBody>}
        {footer && <PopoverFooter>{footer}</PopoverFooter>}
      </PopoverContent>
    </ChakraPopover>
  );
}
