import MotionIcon from "assets/icons/event_motion.svg?react";
import NoiseIcon from "assets/icons/event_noise.svg?react";

type EventValuesType = {
  [key in CameraEventType]: CameraEventType;
};
type EventColorsType = {
  [key in CameraEventType]: {
    primary: string;
    secondary: string;
  };
};
type EventIconsType = {
  [key in CameraEventType]: React.FunctionComponent;
};

export const EventKeys: EventValuesType = {
  MOTION: "MOTION",
  NOISE: "NOISE",
  CONNECT: "CONNECT",
  DISCONNECT: "DISCONNECT"
};

export const EventColors: EventColorsType = {
  MOTION: { primary: "purple", secondary: "purple-container" },
  NOISE: { primary: "error", secondary: "error-container" },
  CONNECT: { primary: "error", secondary: "error-container" },
  DISCONNECT: { primary: "purple", secondary: "purple-container" }
};

export const EventIcons: EventIconsType = {
  MOTION: MotionIcon,
  NOISE: NoiseIcon,
  CONNECT: MotionIcon,
  DISCONNECT: NoiseIcon
};

log.implementation("Missing proper translations for 'CONNECT' and 'DISCONNECT' events");
export const EventStringKeys = {
  MOTION: "motion",
  NOISE: "noise",
  CONNECT: "buttons.connect",
  DISCONNECT: "buttons.disconnect" as any
} as const;

export const EventValuesArr = Object.values(EventKeys);
