import { viewerStore } from "viewer/store/viewer";
import { useMonitoringDialog } from "../CameraView/MonitoringDialog";

const { setCameraConfig } = viewerStore();

type Init = {
  faceController: FaceController;
};

export default class Handlers {
  faceController!: FaceController;

  init = ({ faceController }: Init) => {
    this.faceController = faceController;
  };

  cameraAndFlashLightState = (cameraAndFlashLightState: CameraAndFlashlightState, from: string) => {
    setCameraConfig(from, { cameraState: cameraAndFlashLightState });
  };

  batteryStatus = (batteryStatus: BatteryStatus, from: string) => {
    setCameraConfig(from, { batteryStatus });
  };

  stopVideoBroadcasting = () => {
    useMonitoringDialog.getState().open("face", "stopped");
    this.faceController.off();
  };
}
