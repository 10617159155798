import externalLinks from "@/modules/externalLinks";

export default function checkForWebUser() {
  return new Promise<void>((resolve) => {
    const user = window.localStorage.getItem("parseUser");

    if (!user) {
      log.app("No user found, redirecting...");
      window.location.replace(externalLinks.login);
    } else {
      log.app("Found user:", user);
      resolve();
    }
  });
}
