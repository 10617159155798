import { useCallback, useState } from "react";
import { useDevices } from "@/store/devices";
import { useCameraSettings } from "@/modules/cameraSettings/CameraSettingsFlow";

type Updater = {
  settingsStore: CSTypes.SettingsStore | null;
  openSettings: (jid: string) => void;
};

export default function useCameraSettingsUpdater(): Updater {
  const [selectedDeviceJid, setSelectedDeviceJid] = useState<string | null>(null);
  const { devices, updatePairedDevice } = useDevices((s) => ({
    devices: s.devices as StoreCameraDevices,
    updatePairedDevice: s.updatePairedDevice
  }));
  const controls = useCameraSettings();

  const openSettings = useCallback(
    (jid: string) => {
      setSelectedDeviceJid(jid);
      controls.open();
    },
    [controls]
  );

  const settingsStore: CSTypes.SettingsStore | null =
    selectedDeviceJid && devices[selectedDeviceJid]
      ? {
          xmppLogin: devices[selectedDeviceJid].jid,
          deviceName: devices[selectedDeviceJid].deviceName || "",
          state: {
            color: devices[selectedDeviceJid].color,
            name: devices[selectedDeviceJid].name,
            icon: devices[selectedDeviceJid].icon,
            settings: devices[selectedDeviceJid].settings
          },
          update: (setting) => {
            updatePairedDevice({
              jid: devices[selectedDeviceJid].jid,
              ...setting
            });
          }
        }
      : null;

  return { settingsStore, openSettings };
}
