import EventEmitter from "@/lib/EventEmitter";
import Synchronizer from "@/modules/Synchronizer";
import Announcer from "@/modules/Announcer";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { monitoringActionKeys as keys } from "@/enums/monitoringActionKeys";
import { updateCameraStationControls } from "viewer/modules/cloud/station";
import CameraSwitch from "viewer/modules/monitoring/CameraView/mappedComponents/CameraSwitch";
import Replay from "viewer/modules/monitoring/CameraView/mappedComponents/Replay";
import { useViewer, ViewerStore, viewerStore } from "viewer/store/viewer";
import { getCurrentCameraConfig } from "viewer/store/viewer/selectors";
import MuteIcon from "assets/icons/mute.svg?react";
import HistoryIcon from "assets/icons/history_log.svg?react";
import FlashlightIcon from "assets/icons/flashlight.svg?react";
import ScaleToFillIcon from "assets/icons/scale_to_fill.svg?react";
import SwitchCameraIcon from "assets/icons/switch_camera.svg?react";
import ReplayIcon from "assets/icons/replay.svg?react";
import LowLightIcon from "assets/icons/low_light_auto.svg?react";
import FaceIcon from "assets/icons/face_outlined.svg?react";
import actionControllerUtils, { StateValue } from "./utils";

type ActionStateSetter = (key: CameraControlKey, stateUpdate: boolean) => void;
type ActionStateToggle = (key: CameraControlKey) => void;
export type ActionStates = { [key in CameraControlKey]: StateValue };

export default function createActionController() {
  let jid: string;
  const emitter = new EventEmitter();
  const {
    Processing: { setProcessToggle },
    createAction,
    setJid
  } = actionControllerUtils;

  const directActions: { [key in CameraControlKey]?: Cb } = {};

  const actionsProxy = {
    switch_camera: () => {
      directActions["switch_camera"]?.();
      return true;
    },
    replay: () => {
      directActions["replay"]?.();
      return true;
    }
  };

  const addAction = (action: any, key: CameraControlKey) => (directActions[key] = action);

  const dispatchActionEvent = (eventKey: CameraControlKey) => {
    return new Promise<boolean>((resolve) => {
      const response = (result: boolean) => resolve(result);
      emitter.emit(eventKey, response);
    });
  };

  const map = {
    [keys.history]: createAction({
      key: keys.history,
      actionOpts: { action: () => dispatchActionEvent(keys.history) },
      icon: HistoryIcon,
      labelKey: "events" as const
    }),
    [keys.flashlight]: createAction({
      key: keys.flashlight,
      actionOpts: {
        action: () => dispatchActionEvent(keys.flashlight),
        middlewares: ["processing", "disabledWhenNoConnection"]
      },
      icon: FlashlightIcon,
      labelKey: "buttons.flashlight" as const
    }),
    [keys.mute]: createAction({
      key: keys.mute,
      actionOpts: {
        action: () => {
          dispatchActionEvent(keys.mute);
          toggleIsActive("mute");
        }
      },
      icon: MuteIcon,
      labelKey: "buttons.mute" as const
    }),
    [keys.scale_video]: createAction({
      key: keys.scale_video,
      actionOpts: {
        action: () => {
          dispatchActionEvent(keys.scale_video);
          toggleIsActive("scale_video");
        }
      },
      icon: ScaleToFillIcon,
      labelKey: "scale" as const
    }),
    [keys.switch_camera]: createAction({
      key: keys.switch_camera,
      actionOpts: { action: actionsProxy.switch_camera, middlewares: ["disabledWhenNoConnection"] },
      icon: SwitchCameraIcon,
      labelKey: "buttons.switchCamera" as const,
      Component: CameraSwitch
    }),
    [keys.replay]: createAction({
      key: keys.replay,
      actionOpts: { action: actionsProxy.replay, middlewares: ["disabledWhenNoConnection"] },
      icon: ReplayIcon,
      labelKey: "replay" as const,
      Component: Replay
    }),
    [keys.low_light_mode]: createAction({
      key: keys.low_light_mode,
      actionOpts: {
        action: () => dispatchActionEvent(keys.low_light_mode),
        middlewares: ["processing", "disabledWhenNoConnection"]
      },
      icon: LowLightIcon,
      labelKey: "lowLight" as const
    }),
    [keys.face]: createAction({
      key: keys.face,
      actionOpts: {
        action: () => dispatchActionEvent(keys.face),
        middlewares: ["processing", "disabledWhenNoConnection"]
      },
      icon: FaceIcon,
      labelKey: "face" as const
    })
  };

  function getCurrentStates() {
    return Object.values(map).reduce((all, one) => {
      all[one.key] = {
        isActive: one.isActive,
        isDisabled: one.isDisabled,
        isProcessing: one.isProcessing
      };
      return all;
    }, {} as ActionStates);
  }

  function setProperty(key: CameraControlKey, prop: keyof StateValue, value: boolean) {
    map[key][prop] = value;
    emitter.emit("state-update", getCurrentStates());
  }

  const setIsActive: ActionStateSetter = (key, isActive) => setProperty(key, "isActive", isActive);
  const toggleIsActive: ActionStateToggle = (key) => setProperty(key, "isActive", !map[key].isActive);
  const toggleIsProcessing: ActionStateToggle = (key) => setProperty(key, "isProcessing", !map[key].isProcessing);
  const setIsDisabled: ActionStateSetter = (key, isDisabled) => setProperty(key, "isDisabled", isDisabled);
  setProcessToggle(toggleIsProcessing);

  const onCameraHistoryNavigation = (isOpen: boolean) => setIsActive("history", isOpen);
  const onLocalVideoRemoved = () => setIsActive("face", false);
  const onStoreUpdate = (store: ViewerStore) => {
    updateActivities(store);
    updateDisables(store);
  };

  function startListeningForUpdates() {
    dataSyncEmitter.on("camera-history-navigation", onCameraHistoryNavigation);
    dataSyncEmitter.on("local-video-removed", onLocalVideoRemoved);
    useViewer.subscribe(onStoreUpdate);
  }

  function updateActivities(store: ViewerStore) {
    const cameraConfig = getCurrentCameraConfig(store);
    if (cameraConfig) {
      const { flashlightEnabled, lowLightMode } = cameraConfig.cameraState;
      const incomingFlashlight = flashlightEnabled ?? false;
      const incomingLowLightMode = lowLightMode ? lowLightMode !== "LL_OFF" : false;
      if (incomingFlashlight !== map.flashlight.isActive) setIsActive("flashlight", incomingFlashlight);
      if (incomingLowLightMode !== map.low_light_mode.isActive) setIsActive("low_light_mode", incomingLowLightMode);
    }
    const incomingFace = Boolean(store.localStream?.getVideoTracks()[0]);
    if (incomingFace !== map.face.isActive) setIsActive("face", incomingFace);
  }

  function updateDisables(store: ViewerStore) {
    const cameraConfig = getCurrentCameraConfig(store);
    if (cameraConfig) {
      const { flashlightAvailable, lowLightAvailable } = cameraConfig.cameraState;
      const incomingFlashlight = flashlightAvailable ?? false;
      const incomingLowLight = lowLightAvailable ?? false;
      const incomingSwitchCamera = cameraConfig.configuration.availableCameras.length > 1;
      if (map.flashlight.isDisabled === incomingFlashlight) setIsDisabled("flashlight", !incomingFlashlight);
      if (map.low_light_mode.isDisabled === incomingLowLight) setIsDisabled("low_light_mode", !incomingLowLight);
      if (map.switch_camera.isDisabled === incomingSwitchCamera) setIsDisabled("switch_camera", !incomingSwitchCamera);
    }
  }

  async function updateCloudControls(controls: CameraControls) {
    const response = await updateCameraStationControls({
      xmppLogin: jid,
      cameraControls: controls
    });
    if (!response) return;
    await Synchronizer.getInstance().synchronizeDevices();
    Announcer.getInstance().announceItemChange("DEVICES");
  }

  const setInitialState = (_jid: string) => {
    jid = _jid;
    setJid(_jid);
    Object.entries(getInitialActivityState(_jid)).forEach(([key, value]) =>
      setIsActive(key as CameraControlKey, value)
    );
    Object.entries(getInitialDisabledState()).forEach(([key, value]) => setIsDisabled(key as CameraControlKey, value));
  };

  startListeningForUpdates();
  const mapKeys = Object.keys(map) as CameraControlKeys;

  return {
    setInitialState,
    addAction,
    get map() {
      return map;
    },
    mapKeys,
    providing: {
      getCurrentStates,
      updateCloudControls
    },
    on: emitter.on,
    off: emitter.off
  };
}

const getInitialActivityState = (jid: string) => {
  const { mutedCameras, videoModes } = viewerStore();
  const cameraConfig = getCurrentCameraConfig(viewerStore());

  return {
    [keys.mute]: Boolean(mutedCameras[jid]),
    [keys.scale_video]: videoModes[jid] === "cover",
    [keys.low_light_mode]: cameraConfig ? cameraConfig.cameraState.lowLightMode !== "LL_OFF" : false,
    [keys.flashlight]: cameraConfig ? cameraConfig.cameraState.flashlightEnabled || false : false
  };
};

const getInitialDisabledState = () => {
  const cameraConfig = getCurrentCameraConfig(viewerStore());
  return {
    [keys.flashlight]: cameraConfig ? !cameraConfig.cameraState.flashlightAvailable : true,
    [keys.low_light_mode]: cameraConfig ? !cameraConfig.cameraState.lowLightAvailable : true,
    [keys.switch_camera]: cameraConfig ? cameraConfig.configuration.availableCameras.length <= 1 : true
  };
};
