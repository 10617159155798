import RPC from "@/modules/RPC";
import Xmpp from "@/modules/Xmpp";
import { isEmptyObject } from "@/utils";
import { messages, RPCRequests } from "@/modules/communication";
import { commEmitter } from "@/modules/events/emitter";
import PresenceKeeper from "@/modules/app/Presence";
import Peers from "@/modules/Peers";
import { viewerStore } from "viewer/store/viewer";
import ViewerRtcManager from "viewer/modules/ViewerRtcManager";
import PingService from "viewer/modules/ViewerRtcManager/PingService";
import createTimelineController from "viewer/lib/Timeline/createTimelineController";
import SW from "viewer/lib/Timeline/SW";
import FaceController from "./FaceController";
import FlashlightController from "./FlashlightController";
import Actions from "./rpcActions";
import Handlers from "./cameraUpdateHandlers";
import { addGuard } from "@/utils";

type Providing = {
  startAudioStream: ViewerRtcManager["startAudioStream"];
  startVideoStream: ViewerRtcManager["startVideoStream"];
  stopAudioStream: ViewerRtcManager["stopAudioStream"];
  stopVideoStream: ViewerRtcManager["stopVideoStream"];
  changeFlashlightIntensity: FlashlightController["setIntensity"];
  toggleFlashlight: FlashlightController["toggle"];
  toggleFace: FaceController["toggle"];
  toggleMute: MonitoringManager["toggleMute"];
  toggleVideoMode: MonitoringManager["toggleVideoMode"];
  startTimelinePlayer: ReturnType<typeof createTimelineController>["startTimelinePlayer"];
  destroyTimelinePlayer: ReturnType<typeof createTimelineController>["destroyTimelinePlayer"];
} & Omit<typeof actions, "init" | "syncMonitoring">;

const actions = new Actions();

const { changeCamera, changeLowLightMode } = actions;
const {
  setCameraConfig,
  removeCameraConfig,
  removeRemoteStream,
  toggleMuteForStation,
  setVideoMode,
  destroyLocalTrack
} = viewerStore();

export default class MonitoringManager {
  private xmpp = Xmpp.getInstance();
  private rpc = new RPC();
  private rtcManager = new ViewerRtcManager();
  private flashlightController = new FlashlightController();
  private faceController = new FaceController(
    this.rpc,
    this.rtcManager.startVideoStream,
    this.rtcManager.stopVideoStream
  );
  private pingService = new PingService({
    rpc: this.rpc,
    checkConnection: this.rtcManager.checkConnection,
    syncMonitoring: actions.syncMonitoring
  });
  private sw = new SW(this.rpc);
  private handlers = new Handlers();

  providing = {} as Providing;
  constructor() {
    const { startTimelinePlayer, destroyTimelinePlayer } = createTimelineController(this.rpc, this.sw);
    this.handlers.init({ faceController: this.faceController });
    this.providing = {
      startAudioStream: this.rtcManager.startAudioStream,
      stopAudioStream: this.rtcManager.stopAudioStream,
      startVideoStream: this.rtcManager.startVideoStream,
      stopVideoStream: this.rtcManager.stopVideoStream,
      toggleMute: this.toggleMute,
      toggleFace: this.faceController.toggle,
      changeCamera,
      changeFlashlightIntensity: this.flashlightController.setIntensity,
      toggleFlashlight: this.flashlightController.toggle,
      changeLowLightMode,
      toggleVideoMode: this.toggleVideoMode,
      startTimelinePlayer,
      destroyTimelinePlayer
    };
  }

  sendInitialPresence = () => this.xmpp.sendPresence("receiver");

  init = () => {
    this.rpc.startListening();
    this.rtcManager.startListening();
    this.pingService.start();

    PresenceKeeper.setDefaults();
    commEmitter.on(messages.BatteryStatus.name, this.handlers.batteryStatus);
    commEmitter.on(messages.StopVideoBroadcasting.name, this.handlers.stopVideoBroadcasting);
    commEmitter.on(messages.CameraAndFlashlightState.name, this.handlers.cameraAndFlashLightState);
    actions.init(this.rpc);
  };

  connect = async (jid: string) => {
    try {
      log.monitoring("connecting to", jid);
      const monitoringRequest = RPCRequests.StartMonitoring.create();
      const videoRequest = RPCRequests.StartVideoBroadcasting.create();
      const monitoringResponse = (await this.rpc.call(monitoringRequest, jid, {
        timeout: 7000
      })) as StartMonitoringResponse;
      if (!monitoringResponse || (monitoringResponse && isEmptyObject({ ...monitoringResponse })))
        throw Error("No monitoring response");

      await this.rtcManager.initializeConnection(jid);
      await this.rtcManager.waitForConnectionAndDataChannel(jid);
      await this.rpc.call(videoRequest, jid);

      setCameraConfig(jid, { ...monitoringResponse, jid });

      if (this.xmpp.presence !== "receiver_connected") {
        this.xmpp.sendPresence("receiver_connected");
      }
      return true;
    } catch (err) {
      log.err(err);
      Peers.remove(jid);
      return false;
    }
  };

  stop = async (jid: string) => {
    log.monitoring("stop called for", jid);
    this.destroyCamera(jid);
    const request = RPCRequests.StopMonitoring.create();
    await addGuard(() => this.rpc.call(request, jid));
  };

  private destroyCamera = async (jid: string) => {
    log.monitoring("destroying camera", jid);
    this.rtcManager.rtc.destroyPeerConnection(jid);
    removeCameraConfig(jid);
    removeRemoteStream(jid);
    destroyLocalTrack("audio");
    this.faceController.off();

    if (Object.keys(viewerStore().cameraConfigs).length === 0) {
      this.xmpp.sendPresence("receiver");
    }
  };

  private toggleMute = () => {
    const jid = viewerStore().selectedCamera.jid;
    if (!jid) return;
    toggleMuteForStation(jid);
  };

  private toggleVideoMode = () => {
    const jid = viewerStore().selectedCamera.jid;
    if (!jid) return;

    const currentVideoMode = viewerStore().videoModes[jid];
    const newVideoMode: VideoMode = currentVideoMode === "contain" ? "cover" : "contain";
    setVideoMode(jid, newVideoMode);
  };

  destroy = () => {
    this.rpc.destroy();
    this.rtcManager.destroy();
    this.pingService.stop();
    commEmitter.off(messages.BatteryStatus.name, this.handlers.batteryStatus);
    commEmitter.off(messages.StopVideoBroadcasting.name, this.handlers.stopVideoBroadcasting);
    commEmitter.off(messages.CameraAndFlashlightState.name, this.handlers.cameraAndFlashLightState);
  };
}
