import { useCallback, useMemo, useRef, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { isEmptyArray } from "@/utils";
import { useControls } from "@/hooks";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useDevices } from "@/store/devices";
import { getCameraDevices } from "@/store/devices/selectors";
import { useMount } from "@/hooks/useLifeCycles";
import CameraSettingsFlow from "@/modules/cameraSettings/CameraSettingsFlow";
import PageAnimation from "@/components/PageAnimation";
import DeviceDeleteFlow from "@/modules/app/DeviceDeleteFlow";
import GenericError from "@/components/GenericError";
import DeviceRename from "viewer/components/DeviceRename";
import { useRequester } from "viewer/modules/pairing/Requester";
import { useViewer } from "viewer/store/viewer";
import { useEventFilters } from "viewer/store/eventFilters";
import useCameraSettingsUpdater from "viewer/modules/settings/useCameraSettingsUpdater";
import PreviewManager from "viewer/modules/station/PreviewManager";
import { monitoring } from "viewer/modules/monitoring/MonitoringManager";
import SceneAnimation from "viewer/components/SceneAnimation";
import ActivityLog from "viewer/modules/activityLog/components/ActivityLog";
import CameraHistory from "viewer/components/CameraHistory";
import CameraView from "viewer/modules/monitoring/CameraView";
import { useSwitcher } from "viewer/modules/station/components/Switcher";
import { NoPairedCameras, CameraPreview, Switcher, StatusBar, SettingsFlow } from "viewer/modules/station/components";
import PremiumBanner from "viewer/components/PremiumBanner";
import ConnectProvider from "viewer/modules/monitoring/ConnectProvider";
import DashboardAd from "viewer/modules/station/components/DashboardAd";

const Scenes = { dashboard: 0, history: 1 };

PreviewManager.startListeningForUpdates();
monitoring.init();

export default function Viewer() {
  const { setActiveConfig, addCameraFilter } = useEventFilters((state) => ({
    setActiveConfig: state.setActiveConfig,
    addCameraFilter: state.addCameraFilter
  }));
  const { wizard } = useRequester();
  const cameraHistoryControls = useControls();
  const shouldOpenCameraHistoryAfterDisconnect = useRef(false);
  const errorDialog = useControls();
  const [sceneId, setSceneId] = useState(Scenes.dashboard);
  const pairedCameras = useDevices(getCameraDevices);
  const { openSettings, settingsStore } = useCameraSettingsUpdater();
  const { allowNavigation, disableNavigation, getCanNavigate } = useSwitcher();
  const { previews, selectedCamera, setSelectedCamera } = useViewer((state) => ({
    previews: state.cameraPreviews,
    selectedCamera: state.selectedCamera,
    setSelectedCamera: state.setSelectedCamera
  }));
  const hasPairedCameras = !isEmptyArray(pairedCameras);

  useMount(monitoring.sendInitialPresence);

  const openCameraHistory = useCallback(
    (jid: string) => {
      const objectId = pairedCameras.find((c) => c.jid === jid)?.objectId;
      if (!objectId) return;
      setActiveConfig("fromCamera");
      addCameraFilter(objectId);
      cameraHistoryControls.open();
      dataSyncEmitter.emit("camera-history-navigation", true);
    },
    [cameraHistoryControls, pairedCameras, setActiveConfig, addCameraFilter]
  );

  const closeCameraHistory = useCallback(() => {
    cameraHistoryControls.close();
    dataSyncEmitter.emit("camera-history-navigation", false);
  }, [cameraHistoryControls]);

  const handleConnect = useCallback(
    async (jid: string) => {
      log.rtc("about to connect to", jid);
      if (cameraHistoryControls.isOpen) {
        closeCameraHistory();
        shouldOpenCameraHistoryAfterDisconnect.current = true;
      }
      setSelectedCamera({ jid });
      const ok = await monitoring.connect(jid);
      if (!ok && jid === useViewer.getState().selectedCamera.jid) {
        setSelectedCamera({ jid: null });
        errorDialog.open();
      }
    },
    [setSelectedCamera, errorDialog, cameraHistoryControls.isOpen, closeCameraHistory]
  );

  const handleDisconnect = useCallback(
    async (jid: string) => {
      if (shouldOpenCameraHistoryAfterDisconnect.current) {
        openCameraHistory(jid);
        shouldOpenCameraHistoryAfterDisconnect.current = false;
      }
      setSelectedCamera({ jid: null });
      await monitoring.stop(jid);
    },
    [openCameraHistory, setSelectedCamera]
  );

  const onSceneChange = useCallback(
    (i: number) => {
      disableNavigation();
      if (sceneId !== Scenes.history) setActiveConfig("fromDashboard");
      setSceneId(i);
    },
    [sceneId, setActiveConfig, disableNavigation]
  );

  const cameraList = useMemo(() => {
    const [firstCamera, ...otherCameras] = pairedCameras;
    return [firstCamera, "ad", ...otherCameras];
  }, [pairedCameras]);

  return (
    <ConnectProvider connect={handleConnect}>
      <PageAnimation>
        <Flex
          width={["container.sm", "container.md", "container.lg"]}
          justify="center"
          direction="column"
          h="100%"
          overflow={selectedCamera.jid ? "hidden" : "visible"}
        >
          <Switcher sceneIndex={sceneId} onSceneChange={onSceneChange} getCanNavigate={getCanNavigate} />
          <SettingsFlow />
          {settingsStore && <CameraSettingsFlow settingsStore={settingsStore} />}
          <AnimatePresence initial={false} mode="wait">
            {sceneId === Scenes.dashboard && (
              <SceneAnimation key="list" direction="left" onTransitionComplete={allowNavigation}>
                <StatusBar />
                <PremiumBanner stringKey="upgradeToPremium" />
                {hasPairedCameras ? (
                  <Flex maxW="100%" flexWrap="wrap" gap="24px" pb="5rem">
                    {cameraList.map((camera, i) =>
                      typeof camera === "string" ? (
                        <DashboardAd key={camera} />
                      ) : (
                        <CameraPreview
                          camera={camera}
                          key={camera.jid}
                          preview={previews[camera.jid]}
                          handleConnect={() => handleConnect(camera.jid)}
                          openSettings={() => openSettings(camera.jid)}
                          openCameraHistory={() => openCameraHistory(camera.jid)}
                        />
                      )
                    )}
                  </Flex>
                ) : (
                  <NoPairedCameras openWizard={wizard.open} />
                )}
              </SceneAnimation>
            )}
            {sceneId === Scenes.history && (
              <SceneAnimation direction="right" key="history" onTransitionComplete={allowNavigation}>
                <ActivityLog />
              </SceneAnimation>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {selectedCamera.jid && (
              <CameraView
                jid={selectedCamera.jid}
                disconnect={() => handleDisconnect(selectedCamera.jid!)}
                openCameraHistory={() => openCameraHistory(selectedCamera.jid!)}
                openSettings={() => openSettings(selectedCamera.jid!)}
              />
            )}
          </AnimatePresence>
        </Flex>
        <CameraHistory isOpen={cameraHistoryControls.isOpen} onClose={closeCameraHistory} />
        <DeviceDeleteFlow />
        <DeviceRename />
        <GenericError
          titleKey="connectError.title"
          bodyKey="connectError.subtitle"
          isOpen={errorDialog.isOpen}
          onClose={errorDialog.close}
        />
      </PageAnimation>
    </ConnectProvider>
  );
}
