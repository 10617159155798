import Settings from "./Settings";
import Account from "./Account";
import DeviceList from "./DeviceList";
const steps = [
  { id: "settings", Component: Settings },
  { id: "account", Component: Account },
  { id: "device-list", Component: DeviceList },
] as {
  id: string;
  Component: (props: FlowProviding & Keyable) => JSX.Element;
}[];

export default steps;
