import { useCallback, useEffect } from "react";
import { useApp } from "@/store/app";
import { commEmitter, dataSyncEmitter } from "@/modules/events/emitter";
import { signOut } from "@/modules/cloud/auth";
import { messages } from "@/modules/communication";
import externalLinks from "@/modules/externalLinks";
import clearSession from "../app/clearSession";

export function useStationReset() {
  const setStatus = useApp((state) => state.setStatus);

  const resetStation = useCallback(async () => {
    setStatus("SIGNING_OUT");
    try {
      await signOut();
      clearSession();
      window.location.replace(externalLinks.login);
    } catch (err) {
      log.err(err);
    }
  }, [setStatus]);

  const destroyDevice = useCallback(async () => {
    log.app("received unpair message or sessionToken was invalid");
    await resetStation();
  }, [resetStation]);

  useEffect(() => {
    commEmitter.on(messages.DeviceUnpairedMessage.name, destroyDevice);
    dataSyncEmitter.on("invalid-session", destroyDevice);
    return () => {
      commEmitter.off(messages.DeviceUnpairedMessage.name, destroyDevice);
      dataSyncEmitter.off("invalid-session", destroyDevice);
    };
  }, [destroyDevice]);

  return { resetStation };
}
