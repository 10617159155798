import { useCallback, useEffect, useRef } from "react";
import { Flex, Heading } from "@chakra-ui/react";
import Time from "@/lib/Time";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useIsMobileView } from "@/hooks";
import { useActivityLog } from "viewer/store/activityLog";
import ActivityLogController from "../ActivityLogController";
import EventLoading from "./EventLoading";
import EventCard from "./EventCard";
import EventListBanner from "./EventListBanner";

export default function EventList() {
  const [
    events,
    selectedEventId,
    setSelectedEventId,
    error,
    reachedEnd
    //  loading
  ] = useActivityLog((state) => [
    state.events,
    state.selectedEventId,
    state.setSelectedEventId,
    state.loading,
    state.reachedEnd,
    state.error,
    state.loading
  ]);
  const listRef = useRef<HTMLDivElement>(null);
  const blockScrollListener = useRef(false);
  const isMobile = useIsMobileView();

  useEffect(() => {
    if (error) blockScrollListener.current = true;
    else blockScrollListener.current = false;
  }, [error]);

  const onScroll = useCallback(() => {
    const list = listRef.current;
    if (!list || reachedEnd) return;
    if (blockScrollListener.current) return;
    if (Math.abs(list.scrollHeight - list.clientHeight - list.scrollTop) < 300) {
      blockScrollListener.current = true;
      ActivityLogController.downloadNextEvents(() => {
        blockScrollListener.current = false;
      });
    }
  }, [reachedEnd]);

  let displayDateRef: string | null = null;

  return (
    <Flex
      ref={listRef}
      w={["100%", "calc(50% - 0.75rem)"]}
      direction="column"
      rowGap="0.75rem"
      bg="surface1"
      borderRadius="1rem"
      px={["1rem", "0.75rem", "1rem"]}
      overflowY="scroll"
      pb={reachedEnd && !error ? "1rem" : 0}
      maxH="100%"
      onScroll={onScroll}
    >
      <EventListBanner />
      {events.map((event) => {
        const date = Time.getRelevantDate(event.start);
        const time =
          date !== displayDateRef ? (
            <Heading as="h5" mb="1rem" pt="1rem">
              {date}
            </Heading>
          ) : null;
        displayDateRef = date;
        return (
          <Flex key={event.uniqueId} direction="column">
            {time}
            <EventCard
              onClick={() => {
                setSelectedEventId(event.uniqueId);
                isMobile && dataSyncEmitter.emit("open-event-modal");
              }}
              isSelected={selectedEventId === event.uniqueId}
              event={event}
            />
          </Flex>
        );
      })}
      <EventLoading />
    </Flex>
  );
}
