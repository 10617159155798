import { useApp } from "@/store/app";
import { useAuth } from "@/store/auth";
import { linkWebDevice } from "../cloud/auth";
import { registerXmppDevice, fetchCurrentUser } from "@/modules/cloud/auth";
import { getInstallationId } from "@/modules/app/installation";
import Synchronizer from "@/modules/Synchronizer";
import Xmpp from "@/modules/Xmpp";

export default function createDeviceSetup() {
  let isNewDevice = true;
  let user: any = null;
  let setStatus = useApp.getState().setStatus;
  let setUser = useAuth.getState().setUser;

  const init = async () => {
    let canContinue = true;
    const storedUser = window.localStorage.getItem("parseUser");
    const browserUser = storedUser ? JSON.parse(storedUser) : null;
    log.info("user from local storage", browserUser);

    if (!browserUser) {
      setStatus("FAILURE");
      canContinue = false;
    }
    user = browserUser;
    return canContinue;
  };

  const getXmppCredentials = async () => {
    const deviceName = useAuth.getState().device.name;
    let xmppCredentials = useAuth.getState().device.xmppCredentials;
    log.info("xmpp credentials from store", xmppCredentials);
    if (xmppCredentials) {
      isNewDevice = false;
      return xmppCredentials;
    }
    setUser(user);
    await getInstallationId();

    xmppCredentials = await registerXmppDevice(deviceName);
    const response = await linkWebDevice(xmppCredentials.xmppLogin);

    await fetchCurrentUser();

    log.info("Device linked", response);
    return xmppCredentials;
  };

  const syncDevice = async (announcer: Announcer) => {
    const synchronizer = Synchronizer.getInstance();
    if (isNewDevice) {
      announcer.announceChange();
      await synchronizer.synchronize({ syncAll: true });
    } else {
      await synchronizer.synchronize({ syncAlways: ["CLOUD_ACCOUNT"] });
    }
    await Xmpp.getInstance().getRoster();
  };

  return {
    init,
    getXmppCredentials,
    syncDevice
  };
}
