import { Input, Box, InputProps } from "@chakra-ui/react";
import Calendar from "assets/icons/calendar.svg?react";
import FilterItem from "./FilterItem";
import { forwardRef } from "@chakra-ui/react";
import Time from "@/lib/Time";

type Props = {
  title: string;
  inputProps?: InputProps;
  min?: string | null;
  initValue: string | null;
  isOpen: boolean;
  setIsOpen: Cb;
  invalid?: boolean;
  checkDraft: Cb;
};

const DateInput = forwardRef<Props, "div">(
  ({ title, inputProps, min, invalid, checkDraft, isOpen, setIsOpen, initValue }, ref) => {
    const maxDate = Time.formatDate(new Date(), "YYYY-MM-DD");

    return (
      <Box w="164px" h="40px">
        {isOpen ? (
          <Input
            borderRadius="1.5rem"
            variant="date"
            pos="absolute"
            type="date"
            borderColor={invalid ? "error" : "primary"}
            placeholder="none"
            w="164px"
            h="40px"
            defaultValue={initValue || undefined}
            onChange={checkDraft}
            min={min ? min : undefined}
            fontSize="1rem"
            ref={ref}
            id={title}
            max={maxDate}
            autoFocus
            {...inputProps}
          />
        ) : (
          <FilterItem
            label={title}
            icon={Calendar}
            iconFill="on-surface"
            iconSize="18"
            props={{
              w: "164px",
              h: "40px",
              justifyContent: "space-between",
              flexDir: "row-reverse"
            }}
            onClick={() => {
              setIsOpen();
              setTimeout(() => {
                const el = document.getElementById(title) as HTMLDateInputElement;
                el?.showPicker();
              }, 0);
            }}
          />
        )}
      </Box>
    );
  }
);

export default DateInput;
