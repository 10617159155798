import { getMediaStream } from "@/modules/stream/getMediaStream";
import { getHasPermissionError } from "@/modules/stream/permissions";
import { viewerStore } from "viewer/store/viewer";

// type FalseWithError = false & { error: LocalDeviceError };

const { setLocalDevice } = viewerStore();

export default async function checkLocalDeviceAvailability(
  device: "camera" | "microphone"
): Promise<{ result: true } | { error: LocalDeviceError; result: false }> {
  try {
    const stream = await getMediaStream({
      audio: device === "microphone",
      video: device === "camera"
    });
    stream.getTracks().forEach((track) => {
      track.stop();
      track.enabled = false;
      stream.removeTrack(track);
    });

    return { result: true };
  } catch (error: any) {
    if (getHasPermissionError(error.message)) setLocalDevice(device, { error: "no_permission" });
    else setLocalDevice(device, { error: "no_device" });
    return { error: viewerStore().localDevices[device].error as LocalDeviceError, result: false };
  }
}
