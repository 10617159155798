import { Text, ButtonProps, TextProps } from "@chakra-ui/react";

export default function TextAsButton({
  children,
  onClick,
  ...rest
}: {
  onClick?: Cb;
  children: any;
} & (ButtonProps & TextProps)) {
  return (
    <Text
      as="span"
      role="button"
      tabIndex={0}
      _hover={{
        cursor: "pointer",
      }}
      onKeyDown={onClick ? (e) => e.key === "Enter" && onClick() : undefined}
      {...rest}
      onClick={onClick}
    >
      {children}
    </Text>
  );
}
