import { useState } from "react";
import { Flex, Heading, Spinner, Button, HStack, PinInput, PinInputField } from "@chakra-ui/react";
import Dialog from "@/components/Dialog";
import { useControls } from "@/hooks";
import NavigationBar from "@/components/NavigationBar";
import Icon from "@/components/Icon";
import CameraCode from "assets/icons/camera_code.svg?react";
import { getPairingCodeInfo } from "viewer/modules/cloud/pairing";
import { t } from "@/utils";
import { WizardProps } from "../../Wizard";
import PairingCodeFailureDialog from "./PairingCodeFailureDialog";

export default function EnterCode({ push, requestManager, history, wizard }: WizardProps) {
  const alreadyPairedDialog = useControls();
  const errorDialog = useControls();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [responderDeviceName, setResponderDeviceName] = useState("");

  async function handleCodeComplete(code: string) {
    setIsProcessing(true);

    try {
      const codeInfo = await getPairingCodeInfo(code);
      if (codeInfo.isPaired === true) {
        log.err("Device already paired");
        setResponderDeviceName(codeInfo.deviceName);
        alreadyPairedDialog.open();
        return;
      }

      const responder = {
        jid: codeInfo.xmppLogin,
        name: codeInfo.deviceName,
        code: codeInfo.code
      };

      requestManager.initiatePairing({ push, responder });
    } catch (err: any) {
      log.err(err);
      setError(err.message);
      errorDialog.open();
    }
  }

  function resetState() {
    setIsProcessing(false);
    setResponderDeviceName("");
    setError(null);
  }

  async function handlePastedCode() {
    const clipboardCode = await navigator.clipboard.readText();
    const formattedCode = clipboardCode.replace(/\n/g, "");
    handleCodeComplete(formattedCode);
  }

  const showBack = history.includes("add-camera");
  return (
    <Flex grow={1} direction="column" onPaste={handlePastedCode}>
      <NavigationBar
        {...(!showBack && { onClose: wizard.close })}
        {...(showBack && { onBack: () => push("add-camera", { stepDirection: "previous" }) })}
        props={{ my: "1rem" }}
        autoFocus={false}
      />

      <Flex direction="column" justify="center" align="center" grow="1">
        <Icon
          icon={CameraCode}
          size="2.5rem"
          style={{
            mb: "1rem"
          }}
          fill="primary"
        />
        <Heading maxW="296" as="h4" textAlign="center" mb="2rem" color="on-surface">
          {t("pairing.manually.enterCode.heading")}
        </Heading>
        {isProcessing ? (
          <Spinner />
        ) : (
          <HStack>
            <PinInput
              type="number"
              variant="outline"
              placeholder=""
              onComplete={handleCodeComplete}
              focusBorderColor="primary.99"
              autoFocus
            >
              <PinInputField />
              <PinInputField />
              <PinInputField mr="1.25rem" />
              <PinInputField />
              <PinInputField />
              <PinInputField mr="1.25rem" />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        )}
        {!isProcessing && (
          <Button variant="text" mt="1.5rem" onClick={() => push("cant-find-code")}>
            {t("pairing.code.help.title")}
          </Button>
        )}
      </Flex>
      <Dialog
        isOpen={alreadyPairedDialog.isOpen}
        onClose={alreadyPairedDialog.close}
        title={t("pairing.manually.enterCode.alreadyPairedDialog.heading")}
        body={t("pairing.manually.enterCode.alreadyPairedDialog.text", {
          1: responderDeviceName
        })}
        props={{ onCloseComplete: resetState, blockScrollOnMount: false }}
      />
      <PairingCodeFailureDialog
        isOpen={errorDialog.isOpen}
        close={errorDialog.close}
        errorMessage={error}
        props={{ onCloseComplete: resetState, blockScrollOnMount: false }}
      />
    </Flex>
  );
}
