function getAddedIndex(length: number) {
  if (length === 2 || length === 1) return 0;
  if (length === 4) return 2;
  return 0;
}

export function createSideBarObserver(element: Node) {
  const onChange: MutationCallback = (e, observer) => {
    const index = getAddedIndex(e.length);
    const addedElement = e?.[index]?.addedNodes?.[0] as HTMLDivElement;
    if (!addedElement) return;
    if (addedElement.classList.contains("reorder-disabled")) {
      addedElement.classList.remove("reorder-disabled");
    }
    if (addedElement.classList.contains("variant-large")) {
      addedElement.classList.remove("variant-large");
    }
  };

  const observer = new MutationObserver(onChange);
  observer.observe(element!, {
    childList: true,
  });

  return () => observer.disconnect();
}

export function createUnusedBarObserver(element: Node) {
  const onChange: MutationCallback = (e, observer) => {
    const index = getAddedIndex(e.length);
    const addedElement = e?.[index]?.addedNodes?.[0] as HTMLDivElement;
    if (!addedElement) return;
    if (addedElement.classList.contains("reorder-disabled")) {
      addedElement.classList.remove("reorder-disabled");
    }
    if (addedElement.classList.contains("variant-large")) {
      addedElement.classList.remove("variant-large");
    }
  };

  const observer = new MutationObserver(onChange);
  observer.observe(element!, {
    childList: true,
  });

  return () => observer.disconnect();
}

export function createMainBarObserver(element: Node) {
  const onChange: MutationCallback = (e, observer) => {
    const addedIndex = getAddedIndex(e.length);

    const addedElement = e?.[addedIndex]?.addedNodes?.[0] as HTMLDivElement;
    if (addedElement) {
      if (!addedElement.classList.contains("reorder-disabled")) {
        addedElement.classList.add("reorder-disabled");
      }
      if (!addedElement.classList.contains("variant-large")) {
        addedElement.classList.add("variant-large");
      }
    }

    const revealEmpty =
      (e.length === 1 && e?.[0]?.removedNodes?.[0]) ||
      (e.length === 2 && e?.[0]?.removedNodes?.[0] === e?.[1]?.addedNodes?.[0]);

    if (revealEmpty) {
      const emptyArea = e?.[0]?.previousSibling as HTMLDivElement;
      if (emptyArea) {
        emptyArea.classList.remove("hidden");
      }
    }
  };

  const observer = new MutationObserver(onChange);
  observer.observe(element!, {
    childList: true,
  });

  return () => observer.disconnect();
}
