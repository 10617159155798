import Modal from "@/components/Modal";
import Flow from "@/lib/Flow";
import steps from "./steps";
import { useViewerSettings } from "./useViewerSettings";

export type ViewerSettingsStepProps = FlowProviding & { onClose: () => void };

export default function SettingsFlow() {
  const { isOpen, close, initialStep } = useViewerSettings((state) => ({
    isOpen: state.isOpen,
    close: state.close,
    initialStep: state.initialStep
  }));
  return (
    <Modal isOpen={isOpen} onClose={close} modalBodyProps={{ pt: 8 }}>
      <Flow initialStep={initialStep} steps={steps} onClose={close} />
    </Modal>
  );
}
