import { RPCRequests } from "@/modules/communication";
import { viewerStore } from "viewer/store/viewer";

export default class FaceController {
  private get jid() {
    return viewerStore().selectedCamera.jid as string;
  }
  private get isEnabled() {
    const videoTrack = viewerStore().localStream?.getVideoTracks()[0];
    return Boolean(videoTrack);
  }

  private isProcessing = false;

  constructor(
    private rpc: RPC,
    private startStream: (jid: string) => Promise<void>,
    private stopStream: (jid: string) => Promise<void>
  ) {}

  private send = async (request: RpcMessage) => {
    try {
      const response = (await this.rpc.call(request, this.jid)) as StartVideoReceivingResponse | {};
      if ("result" in response) {
        if (response.result === "SUCCESS") return "success";
        if (response.result === "ALREADY_RUNNING") return "already_running";
      }
      return "error";
    } catch (err) {
      return "error";
    }
  };

  private _on = async () => {
    await this.startStream(this.jid);
    const request = RPCRequests.StartVideoReceiving.create();
    return await this.send(request);
  };

  private _off = async () => {
    const request = RPCRequests.StopVideoReceiving.create();
    await this.stopStream(this.jid);
    await this.send(request);
  };

  toggle = async () => {
    if (this.isProcessing) return;
    this.isProcessing = true;
    let result: Awaited<ReturnType<typeof this.send>> = "success";

    if (this.isEnabled) await this._off();
    else result = await this._on();

    this.isProcessing = false;
    return result;
  };

  off = async () => {
    if (this.isEnabled) await this.stopStream(this.jid);
  };
}
