import { Flex, Heading, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import AppImage from "@/components/AppImage";
import { useDisableNavigationEvents } from "@/hooks";

export default function Waiting() {
  const { t } = useTranslation();
  useDisableNavigationEvents();

  return (
    <Flex w="100%" direction="column" justify="center" align="center" grow={1}>
      <AppImage
        name="confirm_pair.svg"
        options={{
          imgProps: {
            w: "264",
            h: "192",
            mb: "2rem"
          }
        }}
      />
      <Heading as="h4" maxW="296" textAlign="center" mb="1.5rem">
        {t("pairing.confirm.heading")}
      </Heading>
      <Spinner color="primary" />
    </Flex>
  );
}
