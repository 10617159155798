import { useEffect, useRef, useState } from "react";
import { AspectRatio, Box } from "@chakra-ui/react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import { useControls } from "@/hooks";
import { addGuard } from "@/utils";
import Dialog from "@/components/Dialog";
import { t } from "@/utils";
import Error from "assets/icons/error.svg?react";
import Controls from "./Controls";
import PreviewOverlay from "./components/PreviewOverlay";

export default function EventPlayer({ url }: { url?: string }) {
  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [requestedPlay, setRequestedPlay] = useState(false);
  const [error, setError] = useState(false);
  const [playerStatus, setPlayerStatus] = useState<"playing" | "resting">("resting");
  const errorModal = useControls();

  useEffect(() => {
    const sources = [{ type: "application/x-mpegURL", src: url }];
    if (!playerRef.current) {
      const options = {
        controls: false,
        preload: "auto",
        sources
      };

      const videoEl = document.createElement("video") as HTMLVideoElement;
      videoEl.setAttribute("data-setup", "{}");
      videoEl.id = "playlist_video";
      videoEl.onended = () => setPlayerStatus("resting");
      videoEl.muted = isDev;
      videoContainerRef.current?.appendChild(videoEl);
      playerRef.current = videojs(videoEl, options);
      playerRef.current.on("timeupdate", () => setCurrentTime(videoEl.currentTime));
      playerRef.current.on("loadeddata", () => {
        cancelTimeout();
        setLoading(false);
      });
      playerRef.current.on("error", () => setError(true));
    } else {
      setLoading(true);
      setError(false);
      playerRef.current.src(sources);
      setRequestedPlay(false);
    }
  }, [url]);

  useEffect(() => cancelTimeout(), [url]);
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      cancelTimeout();
    };
  }, []);

  const handleFullScreen = async () => {
    try {
      if (window.document.fullscreenElement) {
        await window.document.exitFullscreen();
      } else await document.getElementById("playlist_video_html5_api")?.requestFullscreen();
    } catch (err) {
      log.err(err);
    }
  };

  const handlePlay = () => {
    if (error) {
      setLoading(false);
      errorModal.open();
      return;
    }
    setRequestedPlay(true);
    addGuard(() => playerRef.current!.play(), { onError: (err) => log.err(err) });
    setPlayerStatus("playing");

    if (!loading) return;
    timeoutRef.current = setTimeout(() => {
      setRequestedPlay(false);
      setPlayerStatus("resting");
      errorModal.open();
    }, 7000);
  };

  const cancelTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  return (
    <AspectRatio ratio={16 / 9}>
      <Box
        h="100%"
        w="100%"
        position="relative"
        overflow="hidden"
        borderRadius="1rem"
        bg="black"
        zIndex="1"
        sx={{
          ".vjs-control": {
            display: "none"
          },
          ".vjs-modal-dialog": {
            display: "none"
          },
          ".vjs-control-text": {
            display: "none"
          },
          "#playlist_video": {
            width: "100% !important",
            height: "100% !important",
            display: "flex",
            justifyContent: "center"
          },
          video: {
            height: "100%"
          }
        }}
      >
        {(loading || !requestedPlay || error) && (
          <PreviewOverlay handlePlay={handlePlay} requestedPlay={requestedPlay} />
        )}
        <Box
          position="absolute"
          bottom="0"
          right="0"
          width="100%"
          height="100%"
          bgImage="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 97.16%)"
        />
        <Box data-vjs-player h="100%">
          <Box ref={videoContainerRef} h="100%" />
        </Box>
        <Controls
          playerRef={playerRef}
          currentTime={currentTime}
          handleFullScreen={handleFullScreen}
          playerStatus={playerStatus}
          setPlayerStatus={setPlayerStatus}
        />
        <Dialog
          isOpen={errorModal.isOpen}
          onClose={errorModal.close}
          title={t("eventPlayer.error.title")}
          icon={Error}
          iconProps={{ fill: "error" }}
          buttons={{ cancel: { label: t("buttons.ok") } }}
          body={t("eventPlayer.error.message")}
        />
      </Box>
    </AspectRatio>
  );
}
