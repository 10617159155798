import { t } from "@/utils";
import ErrorIcon from "assets/icons/error.svg?react";
import Dialog from "./Dialog";

type Props = {
  isOpen: boolean;
  onClose: Cb;
  titleKey?: StringKey;
  bodyKey?: StringKey;
};

export default function GenericError({ isOpen, onClose, titleKey, bodyKey }: Props) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t(titleKey || "messages.actionFailed")}
      body={t(bodyKey || "messages.tryLater")}
      buttons={{ confirm: { label: t("buttons.ok") } }}
      icon={ErrorIcon}
      iconProps={{ fill: "error" }}
    />
  );
}
