import { useState } from "react";
import Slider from "@/components/Slider";

type SeekBarProps = {
  currentTime: number;
  duration: number;
  setPlayerTime: (number: number) => void;
  stop: () => void;
  play: () => void;
};

const SeekBar = ({
  currentTime,
  duration,
  setPlayerTime,
  stop,
  play,
}: SeekBarProps) => {
  const [sliderValue, setSliderValue] = useState(currentTime);
  const [isDragging, setIsDragging] = useState(false);

  const handleSliderChange = (value: number) => {
    setPlayerTime(value);
    setSliderValue(value);
  };

  const handleSliderChangeEnd = () => {
    setIsDragging(false);
    setPlayerTime(sliderValue);
    play();
  };
  const handleStartDragging = () => {
    setIsDragging(true);
    stop();
  };

  return (
    <Slider
      min={0}
      step={0.2}
      max={duration}
      onChange={handleSliderChange}
      onChangeEnd={handleSliderChangeEnd}
      onChangeStart={handleStartDragging}
      props={{
        value: isDragging ? sliderValue : currentTime,
        flexGrow: "1",
      }}
    />
  );
};

export default SeekBar;
