import { useMemo } from "react";
import { Box, Flex, Heading, Button, Text } from "@chakra-ui/react";
import { useAppContext } from "@/modules/app/AppContextProvider";
import { useAuth } from "@/store/auth";
import { t, openEmailClient } from "@/utils";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import Popover from "@/components/Popover";
import Icon from "@/components/Icon";
import Avatar from "viewer/components/Avatar";
import AccountIcon from "assets/icons/account.svg?react";
import LogoutIcon from "assets/icons/logout.svg?react";
import HelpIcon from "assets/icons/help_outlined.svg?react";
import ArrowDownIcon from "assets/icons/arrow_down.svg?react";

const AvatarPopover = ({ openSettings }: { openSettings: Cb }) => {
  const { resetStation } = useAppContext();
  const user = useAuth((state) => state.user);
  const { active, hover } = useButtonStateColors("primary", "surface2");

  const menuItems = useMemo(
    () =>
      [
        {
          key: "account",
          action: () => openSettings(),
          icon: AccountIcon
        },
        {
          key: "purchase.help.contactUs.heading",
          action: () => openEmailClient(),
          icon: HelpIcon
        },
        {
          key: "buttons.logOut",
          action: resetStation,
          icon: LogoutIcon
        }
      ] as const,
    [openSettings, resetStation]
  );

  return (
    <Popover
      contentProps={{
        display: "flex",
        flexDir: "column",
        maxW: "400px",
        flexWrap: "wrap",
        p: "0.5rem"
      }}
      popoverProps={{
        placement: "bottom-end"
      }}
      body={
        <Box zIndex={20}>
          <Flex columnGap="12px" mb="12px" alignItems="center" px="8px" pt="4px">
            <Avatar />
            <Heading whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxW="316px" as="h6">
              {user?.email}
            </Heading>
          </Flex>
          <Box borderTop="1px solid" borderColor="outline">
            {menuItems.map((item) => (
              <Button
                variant="unstyled"
                display="flex"
                justifyContent="flex-start"
                w="100%"
                py="12px"
                px="8px"
                borderRadius="0.5rem"
                key={item.key}
                onClick={item.action}
                _hover={{ bg: hover }}
                _active={{ bg: active }}
              >
                <Icon icon={item.icon} fill="primary" />
                <Text ml="1rem">{t(item.key)}</Text>
              </Button>
            ))}
          </Box>
        </Box>
      }
    >
      <Button
        variant="unstyled"
        display="flex"
        alignItems="center"
        columnGap="4"
        py="4"
        pl="4"
        pr="6"
        color="tertiary"
        bg="surface1"
      >
        <Avatar />
        <Icon icon={ArrowDownIcon} fill="on-surface-variant" size="1rem" />
      </Button>
    </Popover>
  );
};

export default AvatarPopover;
