import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { isEmptyArray } from "@/utils";
import environment from "@/modules/environment";

type ViewerState = {
  localStream: MediaStream | null;
  remoteStreams: { [jid: string]: MediaStream | null };
  cameraConfigs: { [jid: string]: StartMonitoringResponse & { jid: string } };
  cameraPreviews: CameraPreviewState;
  mutedCameras: { [jid: string]: boolean };
  selectedCamera: { jid: string | null };
  videoModes: { [jid: string]: VideoMode };
  isMonitoringPanelOpened: boolean;
  localDevices: {
    microphone: { askedForPermission: boolean; error: null | LocalDeviceError };
    camera: { askedForPermission: boolean; error: null | LocalDeviceError };
  };
};

export type ViewerStore = ViewerState & {
  setLocalStream(localStream: MediaStream): void;
  destroyLocalTrack(type: "video" | "audio"): void;
  setRemoteStream(jid: string, remoteStream: MediaStream): void;
  setCameraConfig(jid: string, cameraConfig: Partial<StartMonitoringResponse & { jid: string }>): void;
  removeRemoteStream(jid: string): void;
  removeCameraConfig(jid: string): void;
  setCameraPreview(jid: string, preview: CameraPreviewState[string]): void;
  toggleMuteForStation(jid: string): void;
  removeCameraPreview(jid: string): void;
  setSelectedCamera({ jid }: { jid: string | null }): void;
  setVideoMode(jid: string, videoMode: VideoMode): void;
  setIsMonitoringPanelOpened(isMonitoringPanelOpened: boolean): void;
  setLocalDevice(
    device: keyof ViewerState["localDevices"],
    update: Partial<ViewerState["localDevices"][keyof ViewerState["localDevices"]]>
  ): void;
  reset(): void;
};

const initialState: ViewerState = {
  localStream: null,
  remoteStreams: {},
  cameraConfigs: {},
  cameraPreviews: {},
  mutedCameras: {},
  selectedCamera: { jid: null },
  videoModes: {},
  isMonitoringPanelOpened: true,
  localDevices: {
    microphone: { askedForPermission: false, error: null },
    camera: { askedForPermission: false, error: null }
  }
};

const useViewer = create<ViewerStore>()(
  persist(
    immer((set, get) => ({
      ...initialState,
      setLocalStream(localStream) {
        set({ localStream });
      },
      destroyLocalTrack(type) {
        const stream = get().localStream;
        stream?.getTracks().forEach((track) => {
          if (track.kind === type) {
            log.info(`Destroying ${type} track`);
            track.stop();
            track.enabled = false;
            stream.removeTrack(track);
          }
        });
        if (isEmptyArray(stream?.getTracks())) {
          log.info("No tracks left in stream, setting stream to null");
          set({ localStream: null });
        }
      },
      setRemoteStream(jid, remoteStream) {
        log.rtc("setting remote stream for camera", remoteStream, jid);
        set((state) => {
          state.remoteStreams[jid] = remoteStream;
        });
      },
      setCameraConfig(jid, cameraConfig) {
        log.monitoring("setting camera state", cameraConfig, jid);
        set((state) => {
          const prevCameraState = state.cameraConfigs[jid]?.cameraState;
          state.cameraConfigs[jid] = {
            ...state.cameraConfigs[jid],
            ...cameraConfig
          };
          if (cameraConfig.cameraState) {
            state.cameraConfigs[jid].cameraState = {
              ...prevCameraState,
              ...cameraConfig.cameraState
            };
          }
        });
        log.monitoring("new camera state", get().cameraConfigs[jid]);
      },
      removeRemoteStream(jid) {
        log.rtc("removing remote stream from camera", jid);
        set((state) => {
          const { [jid]: _, ...rest } = state.remoteStreams;
          return { remoteStreams: rest };
        });
      },
      removeCameraConfig(jid) {
        log.monitoring("removing camera state from", jid);
        set((state) => {
          const { [jid]: _, ...rest } = state.cameraConfigs;
          return { cameraConfigs: rest };
        });
      },
      setCameraPreview(jid, preview) {
        log.media("setting camera preview", preview, jid);
        set((state) => {
          state.cameraPreviews[jid] = preview;
        });
      },
      removeCameraPreview(jid) {
        log.media("removing preview from camera", jid);
        set((state) => {
          const { [jid]: _, ...rest } = state.cameraPreviews;
          return { cameraPreviews: rest };
        });
      },
      setSelectedCamera(selectedCamera) {
        log.monitoring("setting selected camera", selectedCamera);
        set({ selectedCamera });
      },
      toggleMuteForStation(jid) {
        set((state) => {
          state.mutedCameras[jid] = !state.mutedCameras[jid];
        });
      },
      setVideoMode(jid, videoMode) {
        set((state) => {
          state.videoModes[jid] = videoMode;
        });
      },
      setIsMonitoringPanelOpened(isMonitoringPanelOpened) {
        set({ isMonitoringPanelOpened });
      },
      setLocalDevice(device, update) {
        set((state) => {
          state.localDevices[device] = { ...state.localDevices[device], ...update };
        });
      },
      reset() {
        set(initialState);
      }
    })),
    {
      name: environment.appMode + "-viewer",
      partialize: (state) => ({
        videoModes: state.videoModes,
        mutedCameras: state.mutedCameras,
        isMonitoringPanelOpened: state.isMonitoringPanelOpened
      })
    }
  )
);

const viewerStore = useViewer.getState;

export { useViewer, viewerStore };
